import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getPaginatedGuarantor } from "store/clients";
import { getAllGuarantor } from "store/clients";
import { getStaffsWithParams } from "store/clients";
import { NumberParam, useQueryParam } from "use-query-params";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgDashboardIdField } from "utils";
import { positionToOrgMapping } from "utils";

const useDashboardGuarantorList = (generateOrgId) => {
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [filterName, setFilterName] = useState("");
  const dispatch = useDispatch();
  const { type, orgId } = useParams();
  const [listRefreshTrigger, setListRefreshTrigger] = useState(false);
  const [cos, setCos] = useState();
  // useful for loan request form
  const [guarantorList, setGurantorList] = useState([]);
  const orgType = type || positionToOrgMapping[user.position];
  const [loading, setLoading] = useState(false);

  const handleListRefreshTrigger = () =>
    setListRefreshTrigger((current) => !current);

  useEffect(async () => {
    setLoading(true);
    let queryString = "";
    if (generateOrgId) {
      // Benefitial for cases were we need to get the org id field from the user data
      const _orgType = getOrgStaffProfileOrgField(user?.position);
      const orgIdField = getOrgDashboardIdField(user?.position);
      const orgFieldId =
        user?.position === "CO" ? user?.id : user?.[_orgType]?.id;

      queryString = queryString.concat(`${orgIdField}=${orgFieldId}`);
    } else {
      if (orgType) {
        const field_id = getOrgDashboardIdField(orgType);
        queryString = queryString.concat(`${field_id}=${orgId || user?.id}`);
      }
    }

    const guarantor_querystring = queryString?.concat(
      `&search_name=${filterName}&page=${filterName ? 1 : (page || 1)}`
    );

    const { results, count } = await dispatch(
      getPaginatedGuarantor(guarantor_querystring)
    );
    if (results) {
      setGurantorList(results);
      setTotalPageNumber(count);
    }

    if (user?.position === "CO") {
      setCos([user]);
    } else {
      dispatch(getStaffsWithParams(queryString)).then((result) => {
        setCos(result);
      });
    }
    setLoading(false);
  }, [listRefreshTrigger, filterName, page]);

  const handleFilterChange = (range) =>
    dispatch(setClientDateFilterAction(range));

  return {
    type: orgType,
    id: orgId,
    handleListRefreshTrigger,
    guarantorList,
    cos,
    filterName,
    setFilterName,
    loading,
    totalPageNumber,
    page,
    setPage,
  };
};

export { useDashboardGuarantorList };
