import { ClientProfile } from "views/ClientProfile";
import {
  RegisterClient,
  LoanRequestForm,
  StaffProfile,
  StaffCreateForm,
  Dashboard,
  CreateOrgLevel,
} from "views";
import { DashboardList, DashboardClientList } from "views/Dashboard";
import { DashboardLoanList } from "views/Dashboard/DashboardLoanList";
import { LoanDetailPage } from "views/LoanDetailPage";
import { RegisterGuarantor } from "views/RegisterGuarantor";
import { RegisterUnion } from "views/RegisterUnion";
import { DashboardStaffList } from "views/Dashboard/DashboardStaffList";
import { CreateHolidays } from "views/CreateHolidays";
import { ViewHolidays } from "views/ViewHolidays";
import { ViewMessages } from "views/messages/ViewMessages";
import { ViewUnion } from "views/ViewUnion";
import { UnionDetailPage } from "views/UnionDetailPage";
import { DashboardDailypayList } from "views/Dashboard/DashboardDailypayList";
import { ExpenseForm } from "components/ExpenseForm";
import { ExpenseList } from "components/ExpenseList";
import { SpecialIncome } from "components/SpecialIncomeForm";
import { SpecialIncomeList } from "components/SpecialIncomeList";
import { SeizedAssetsForm } from "components/SeizedAssetsForm";
import { SeizedAssetsList } from "components/SeizedAssetsList";
import { SeizedAssetDetail } from "components/SeizedAssetDetail";
import { BankWithdrawalForm } from "components/BankWithdrawalForm";
import { BankDepositForm } from "components/BankDepositForm";
import { BankDepositList } from "components/BankDepositList";
import { BankWithdrawalList } from "components/BankWithdrawalList";
import { ProcurementsForm } from "components/ProcurementsForm";
import { ProcurementsList } from "components/ProcurementsList";
import { ReportsForm } from "components/ReportsForm";
import { ReportsList } from "components/ReportsList";
import { WaiverBalanaceForm } from "components/WaiverBalanceForm";
import { WaiverBalanceList } from "components/WaiverBalananceList";
import { AdditionalSavingsForm } from "components/AdditionalSavingsForm";
import { AdditionalSavingsList } from "components/AdditionalSavingsList";
import { OrgExpenseForm } from "components/OrgExpenseForm";
import { OrgExpenseList } from "components/OrgExpenseList";
import { RemittanceToBmList } from "components/RemittanceToBmList";
import { RemittanceToBmForm } from "components/RemitanceToBmForm";
import { DashboardGuarantorList } from "views";
import { SavingsWithdrawalList } from "components/SavingsWithdrawalList";
import { FieldCollectionList } from "components/FieldCollectionList";

const useRoutes = () => {
  const dashboardRoutes = [
    {
      path: "/",
      name: "Dashboard",
      icon: "nc-icon nc-chart-pie-35",
      component: Dashboard,
      showOnSidebar: true,
      permissionLevel: 1,
    },
    {
      name: "General",
      icon: "nc-icon nc-air-baloon",
      showOnSidebar: true,
      permissionLevel: 1,
      subLayouts: [
        {
          path: "/expenses/new",
          name: "Add Expense",
          icon: "nc-icon nc-air-baloon",
          component: ExpenseForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/org_expenses/new",
          name: "Add Org Expense",
          icon: "nc-icon nc-air-baloon",
          component: OrgExpenseForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/specialincome/new",
          name: "Special Income",
          icon: "nc-icon nc-air-baloon",
          component: SpecialIncome,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/seizedasset/new",
          name: "Add seized assets",
          icon: "nc-icon nc-air-baloon",
          component: SeizedAssetsForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/bankwithdrawal/new",
          name: "Bank withdrawal",
          icon: "nc-icon nc-air-baloon",
          component: BankWithdrawalForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/bankdeposit/new",
          name: "Bank deposit",
          icon: "nc-icon nc-air-baloon",
          component: BankDepositForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/remittance/new",
          name: "Remittance to BM",
          icon: "nc-icon nc-air-baloon",
          component: RemittanceToBmForm,
          showOnSidebar: true,
          permissionLevel: 0,
        },
        {
          path: "/procurements/new",
          name: "Add Procurement",
          icon: "nc-icon nc-air-baloon",
          component: ProcurementsForm,
          showOnSidebar: true,
          permissionLevel: 3,
        },
        {
          path: "/reports/new",
          name: "Add Report",
          icon: "nc-icon nc-air-baloon",
          component: ReportsForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/waiverbalance/new",
          name: "Waiver balance",
          icon: "nc-icon nc-air-baloon",
          component: WaiverBalanaceForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/additionalsavings/new",
          name: "Additional savings",
          icon: "nc-icon nc-air-baloon",
          component: AdditionalSavingsForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
      ],
    },
    {
      path: "/messages",
      name: "Messages",
      icon: "nc-icon nc-chat-round",
      component: ViewMessages,
      showOnSidebar: true,
      permissionLevel: 0,
    },
    {
      name: "Customers",
      icon: "nc-icon nc-circle-09",
      showOnSidebar: true,
      permissionLevel: 1,
      subLayouts: [
        {
          path: "/client-register/",
          name: "Register Clients",
          icon: "nc-icon nc-chart-pie-35",
          component: RegisterClient,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/guarantor-register/",
          name: "Register Guarantor",
          icon: "nc-icon nc-badge",
          component: RegisterGuarantor,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/clients/:id",
          name: "Client Profile",
          icon: "nc-icon nc-chart-pie-35",
          component: ClientProfile,
          showOnSidebar: false,
          permissionLevel: 1,
        },
      ],
    },
    {
      name: "Loans",
      icon: "nc-icon nc-money-coins",
      showOnSidebar: true,
      permissionLevel: 1,
      subLayouts: [
        {
          path: "/new-loan/",
          name: "Request Loan",
          icon: "nc-icon nc-money-coins",
          component: LoanRequestForm,
          showOnSidebar: true,
          permissionLevel: 1,
        },
      ],
    },
    {
      name: "Union",
      icon: "nc-icon nc-grid-45",
      showOnSidebar: true,
      permissionLevel: 1,
      subLayouts: [
        {
          path: "/union-register/",
          name: "Register Union",
          icon: "nc-icon nc-chart-pie-35",
          component: RegisterUnion,
          showOnSidebar: true,
          permissionLevel: 1,
        },
        {
          path: "/union-viewall/:id",
          name: "View Detail",
          icon: "nc-icon nc-chart-pie-35",
          component: UnionDetailPage,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/union-viewall/",
          name: "View Unions",
          icon: "nc-icon nc-bullet-list-67",
          component: ViewUnion,
          showOnSidebar: true,
          permissionLevel: 1,
        },
      ],
    },
    {
      name: "Staffs",
      icon: "nc-icon nc-bell-55",
      showOnSidebar: true,
      permissionLevel: 2,
      subLayouts: [
        {
          path: "/staffs-register/",
          name: "Create Staff",
          icon: "nc-icon nc-chart-pie-35",
          component: StaffCreateForm,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/staffs/:id",
          name: "Staff Profile",
          icon: "nc-icon nc-single-02",
          component: StaffProfile,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/staffs/edit/:id",
          name: "Staff Profile",
          icon: "nc-icon nc-single-02",
          component: StaffCreateForm,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/staffs/",
          name: "Staff Profile",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardStaffList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/staffs/:type/:orgId/",
          name: "Loan List",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardStaffList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
      ],
    },
    {
      name: "Organization",
      icon: "nc-icon nc-bank",
      showOnSidebar: true,
      permissionLevel: 3,
      subLayouts: [
        {
          path: "/create-org/",
          name: "Create Org",
          icon: "nc-icon nc-bank",
          component: CreateOrgLevel,
          showOnSidebar: true,
          permissionLevel: 3,
        },
        {
          path: "/org/:type/:id",
          name: "Org Dashboard",
          icon: "nc-icon nc-bank",
          component: Dashboard,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/org/:type/:id/dashboard-list/dailypay",
          name: "Org Dashboard Daily Pay list",
          icon: "nc-icon nc-bullet-list-67",
          component: DashboardDailypayList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/expenses/",
          name: "Expenses",
          icon: "nc-icon nc-bullet-list-67",
          component: ExpenseList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/org_expenses/",
          name: "Org Expenses",
          icon: "nc-icon nc-bullet-list-67",
          component: OrgExpenseList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/specialincome/",
          name: "Org Special Income",
          icon: "nc-icon nc-bullet-list-67",
          component: SpecialIncomeList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/seizedassets/",
          name: "Seized Assets",
          icon: "nc-icon nc-bullet-list-67",
          component: SeizedAssetsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/dashboard-list/:listType",
          name: "Org Dashboard List",
          icon: "nc-icon nc-bullet-list-67",
          component: DashboardList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/dashboard-list/dailypay",
          name: "Org Dashboard Daily Pay list",
          icon: "nc-icon nc-bullet-list-67",
          component: DashboardDailypayList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/expenses/",
          name: "Expense list",
          icon: "nc-icon nc-bullet-list-67",
          component: ExpenseList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org_expenses/",
          name: "Org Expense list",
          icon: "nc-icon nc-bullet-list-67",
          component: OrgExpenseList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/specialincome/",
          name: "Special income list",
          icon: "nc-icon nc-bullet-list-67",
          component: SpecialIncomeList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/seizedassets/",
          name: "Seized Assets",
          icon: "nc-icon nc-bullet-list-67",
          component: SeizedAssetsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/seizedassets/:id",
          name: "Seized Assets",
          icon: "nc-icon nc-bullet-list-67",
          component: SeizedAssetDetail,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/procurements/",
          name: "Procurements",
          icon: "nc-icon nc-air-baloon",
          component: ProcurementsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/procurements/",
          name: "Procurements",
          icon: "nc-icon nc-air-baloon",
          component: ProcurementsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/reports/",
          name: "Reports",
          icon: "nc-icon nc-air-baloon",
          component: ReportsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/reports/",
          name: "Reports",
          icon: "nc-icon nc-air-baloon",
          component: ReportsList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/waiverbalance/",
          name: "Waiver Balanace",
          icon: "nc-icon nc-air-baloon",
          component: WaiverBalanceList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/waiverbalance/",
          name: "Waiver Balance",
          icon: "nc-icon nc-air-baloon",
          component: WaiverBalanceList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/additionalsavings/",
          name: "Additional savings",
          icon: "nc-icon nc-air-baloon",
          component: AdditionalSavingsList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/org/:type/:id/additionalsavings/",
          name: "Additional savings",
          icon: "nc-icon nc-air-baloon",
          component: AdditionalSavingsList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/field-collections/",
          name: "Field Collections",
          icon: "nc-icon nc-air-baloon",
          component: FieldCollectionList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/org/:type/:id/field-collections/",
          name: "Field Collections",
          icon: "nc-icon nc-air-baloon",
          component: FieldCollectionList,
          showOnSidebar: false,
          permissionLevel: 1,
        },
        {
          path: "/bank_deposits/",
          name: "Bank Deposits",
          icon: "nc-icon nc-bullet-list-67",
          component: BankDepositList,
          showOnSidebar: false,
          permissionLevel: 2,
        },
        {
          path: "/org/:type/:id/bank_deposits/",
          name: "Bank Deposits",
          icon: "nc-icon nc-bullet-list-67",
          component: BankDepositList,
          showOnSidebar: false,
          permissionLevel: 2,
        },
        {
          path: "/remittance/",
          name: "Remittance to BM",
          icon: "nc-icon nc-bullet-list-67",
          component: RemittanceToBmList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/remittance/",
          name: "Remmitance To BM",
          icon: "nc-icon nc-bullet-list-67",
          component: RemittanceToBmList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/bank_withdrawals/",
          name: "Bank withdrawals",
          icon: "nc-icon nc-bullet-list-67",
          component: BankWithdrawalList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/bank_withdrawals/",
          name: "Seized Assets",
          icon: "nc-icon nc-bullet-list-67",
          component: BankWithdrawalList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/savings_withdrawals/",
          name: "Savings Withdrawals",
          icon: "nc-icon nc-bullet-list-67",
          component: SavingsWithdrawalList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/savings_withdrawals/",
          name: "Savings Withdrawals",
          icon: "nc-icon nc-bullet-list-67",
          component: SavingsWithdrawalList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/dashboard-list/:listType",
          name: "Org Dashboard List",
          icon: "nc-icon nc-bullet-list-67",
          component: DashboardList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/org/:type/:id/edit",
          name: "Org Dashboard Edit",
          icon: "nc-icon nc-chart-pie-35",
          component: CreateOrgLevel,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/clients/",
          name: "Org Dashboard Edit",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardClientList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/clients/:type/:orgId/",
          name: "Org Dashboard Edit",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardClientList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/guarantors/",
          name: "Org Dashboard Guarantors",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardGuarantorList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/guarantors/:type/:orgId/",
          name: "Org Dashboard Gurantors",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardGuarantorList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/loans/",
          name: "Loan List",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardLoanList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/loans/:type/:orgId/",
          name: "Loan List",
          icon: "nc-icon nc-chart-pie-35",
          component: DashboardLoanList,
          showOnSidebar: false,
          permissionLevel: 0,
        },
        {
          path: "/loans/:id/",
          name: "Loan Detail",
          icon: "nc-icon nc-chart-pie-35",
          component: LoanDetailPage,
          showOnSidebar: false,
          permissionLevel: 0,
        },
      ],
    },
    {
      name: "Holidays",
      icon: "nc-icon nc-air-baloon",
      showOnSidebar: true,
      permissionLevel: 0,
      component: CreateHolidays,
      subLayouts: [
        {
          path: "/holidays/create",
          name: "Create Holidays",
          icon: "nc-icon nc-air-baloon",
          component: CreateHolidays,
          showOnSidebar: true,
          permissionLevel: 2,
        },
        {
          path: "/holidays/view",
          name: "View Holidays",
          icon: "nc-icon nc-chart-pie-35",
          component: ViewHolidays,
          showOnSidebar: true,
          permissionLevel: 0,
        },
      ],
    }
  ];

  return {
    routes: dashboardRoutes,
  };
};

export { useRoutes };
