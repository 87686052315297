import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Input, Select } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDashboardStaffList } from "hooks/useDashboardStaffList";
import { USER_POSITIONS } from "utils";
import { useQueryParam, NumberParam } from "use-query-params";
import { ModifiedAntTable } from "components/ModifiedAntTable";
import { deleteUserAction } from "store/users";
import { useListTitle } from "hooks/useListTitle";

const DashboardStaffList = () => {
  const { listData, loading, handleListRefreshTrigger } = useDashboardStaffList();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [selectedRowKeysState, setSelectedRowKeysState] = useState([]);
  const [filteredData, setFilteredData] = useState(listData);
  const [filterName, setFilterName] = useState("");
  const [filterPosition, setFilterPosition] = useState(undefined);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { fullListName } = useListTitle("staffs")

  useEffect(() => {
    if (listData?.length) {
      setFilteredData(listData);
    }
  }, [listData]);

  const handleFilter = useCallback(() => {
    setFilteredData(() => {
      return listData?.filter((val) => {
        const conditions =
          val?.status !== 'terminated' && // remove terminated employees
          val?.id !== user.id &&
          val?.name?.toLowerCase()?.includes(filterName?.toLowerCase()) &&
          (val.position === filterPosition ||
            filterPosition === "" ||
            !filterPosition);

        return conditions;
      });
    });
  }, [filterPosition, filterName, listData]);

  const handleDeleteLoan = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this staff? If this person is a CO, please reassign their clients before proceeding."
      )
    ) {
      await dispatch(deleteUserAction(id));
      handleListRefreshTrigger();
    }
  };

  // Handle Filter
  useEffect(() => {
    handleFilter();
  }, [filterPosition, filterName, listData]);

  const getStaffColumn = useCallback(() => {
    const columns = [
      {
        title: "S/N",
        dataIndex: "id",
        render: (text, record, index) =>
          ((Number(page) || 1) - 1) * 50 + (index + 1),
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/staffs/${record.id}`}>
            <i className="fa fa-link" aria-hidden="true"></i>{" "}
            {`${record?.name}`}
          </Link>
        ),
      },
      {
        title: "Position",
        dataIndex: "position",
      },
      {
        title: "Edit",
        render: (text, record) => (
          <Link to={`/staffs/edit/${record.id}`}>
            Edit <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
        ),
      },
    ];

    if (["DR", "SM", "ZM"].includes(user?.position)) {
      columns.push({
        title: "",
        dataIndex: "id",
        render: (text, record) => (
          <span role="button" onClick={() => handleDeleteLoan(record?.id)}>
            <i className="fas fa-trash"></i>
          </span>
        ),
        summary: "-",
      });
    }

    return columns;
  }, [user]);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Input.Group compact className="mb-3 filter-panel">
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "50%" }}
          placeholder="Filter by position"
          value={filterPosition}
          onChange={(val) => setFilterPosition(val)}
        >
          {USER_POSITIONS?.map((val, index) => (
            <Select.Option value={val?.id} key={index}>
              {val?.label}
            </Select.Option>
          ))}
        </Select>
        <Input
          style={{ width: "50%" }}
          placeholder="Filter by name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Input.Group>

      <ModifiedAntTable
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedRowKeysState,
          onChange: (selectedRowKeys) =>
            setSelectedRowKeysState(selectedRowKeys),
        }}
        rowKey="id"
        scroll={{ x: "max-content" }}
        columns={getStaffColumn()}
        dataSource={filteredData}
        bordered
        loading={loading}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </Container>
  );
};

export { DashboardStaffList };
