import { Checkbox, Form, Input, Select } from "antd";
import { ImageUpload } from "components/ImageUpload";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showAlertAction } from "store/alert";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgDashboardIdField } from "utils";
import { addNewGuarantor, getStaffsWithParams } from "../store/clients";

const Option = Select.Option;

const RegisterGuarantor = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [cos, setCos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl1, setImageUrl1] = useState();
  const [imageUrl2, setImageUrl2] = useState();
  const { user } = useSelector((state) => state.users);


  useEffect(() => {
    setLoading(true);
    const orgType = getOrgStaffProfileOrgField(user?.position);
    const orgIdField = getOrgDashboardIdField(user?.position);
    const orgFieldId = user?.position === "CO" ? user?.id : user?.[orgType]?.id;

    let queryString = "";
    queryString = queryString.concat(`${orgIdField}=${orgFieldId}`);

    if (user?.position === "CO") {
      setCos([user]);
    } else {
      dispatch(getStaffsWithParams(queryString)).then((result) => {
        setCos(result);
      });
    }
    setLoading(false);
  }, []);

  const handleSubmit = async (values) => {
    if(!imageUrl1 && !imageUrl2) {
      dispatch(
        showAlertAction({
          message: "Please upload an image before submitting this form.",
          level: "danger",
          isVisible: true,
        })
      );
      return
    }

    setLoading(true);
    dispatch(
      addNewGuarantor({ ...values, image1: imageUrl1, image2: imageUrl2 })
    );
    form.resetFields();
    setLoading(false);
  };

  return (
    <>
      <Container fluid>
        <h3 className="page-title mb-4">Register Guarantor</h3>
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col className="" md="3">
              <Form.Item
                name="Title"
                label="title"
                rules={[
                  {
                    required: true,
                    message: "Please provide title",
                  },
                ]}
              >
                <Input
                  placeholder="Title"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="4">
              <Form.Item
                name="surname"
                label="Surname"
                rules={[
                  {
                    required: true,
                    message: "Please provide surname",
                  },
                ]}
              >
                <Input
                  placeholder="Surname"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="5">
              <Form.Item
                name="firstname"
                label="Firstname"
                rules={[
                  {
                    required: true,
                    message: "Please provide a first name",
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="" md="6">
              <Form.Item
                name="house_address"
                label="House Address"
                rules={[
                  {
                    required: true,
                    message: "Please provide house address",
                  },
                ]}
              >
                <Input
                  placeholder="House Adress"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="office_address"
                label="Office/Business Address"
                rules={[
                  {
                    required: true,
                    message: "Please provide office address",
                  },
                ]}
              >
                <Input
                  placeholder="Office/Business Address"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="" md="6">
              <Form.Item
                name="phone_number1"
                label="Phone Number 1"
                rules={[
                  {
                    required: true,
                    message: "Please provide phone number 1",
                  },
                ]}
              >
                <Input
                  placeholder="Phone Number 1"
                  type="number"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item label="Phone Number 2" name="phone_number2">
                <Input
                  placeholder="Phone Number 2"
                  type="number"
                  className="form-control"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="" md="6">
              <Form.Item
                name="marital_status"
                label="Marital Status"
                rules={[
                  {
                    required: true,
                    message: "Please provide marital status",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select marital Status"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  <Select.Option value="single" key="single">
                    Single
                  </Select.Option>
                  <Select.Option value="married" key="married">
                    Married
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="number_of_children"
                label="Number of children"
                rules={[
                  {
                    required: true,
                    message: "Please provide number of children",
                  },
                ]}
              >
                <Input
                  placeholder="Number of children"
                  type="number"
                  className="form-control"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="" md="6">
              <Form.Item
                name="nin"
                label="NIN"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please provide a valid NIN",
                  },
                  {
                    min: 11,
                    max: 11,
                    message: "Please provide a valid NIN",
                  },
                ]}
              >
                <Input
                  placeholder="Your NIN number"
                  type="number"
                  className="form-control"
                />
              </Form.Item>
            </Col>
            <Col className="" md="6">
              <Form.Item
                name="co_id"
                label="Credit Officer"
                rules={[
                  {
                    required: true,
                    message: "Please select a credit officer",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select credit officer"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {cos?.map((inst) => (
                    <Select.Option value={inst.id} key={inst.id}>
                      {inst.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <ImageUpload
              uploadDir="guarantors"
              onImageUpload={setImageUrl1}
              onDeleteImage={() => setImageUrl1(undefined)}
            />
            <ImageUpload
              uploadDir="guarantors"
              onImageUpload={setImageUrl2}
              onDeleteImage={() => setImageUrl2(undefined)}
            />
          </Row>

          <Row className="mt-3">
            <Col>
              <Button
                variant="primary"
                className="px-5"
                type="submit"
                disabled={loading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export { RegisterGuarantor };
