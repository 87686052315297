import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import { Button, Col, Container, Row } from "react-bootstrap";
import { INCOME_ORG_TYPE } from "views";
import { useDispatch, useSelector } from "react-redux";
import { useOrgAndRelatedOrgInput } from "hooks/useOrgAndRelatedOrgInput";
import { procurementCreateAction } from "store/org";

const ProcurementsForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const {
    onSelectOrgType,
    loadingOrg,
    relatedOrgOption,
    selectFieldName,
    orgType,
  } = useOrgAndRelatedOrgInput();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true)
    await dispatch(procurementCreateAction({
      ...values
    }))
    setLoading(false)
    form.resetFields()
  };

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Add procuremnts</h3>

      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Select Org Level"
              rules={[
                {
                  required: true,
                  message: "Please select a CATEGORY",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select credit officer"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onSelect={onSelectOrgType}
              >
                {INCOME_ORG_TYPE.map(
                  ({ id, label, permissionLevel }) =>
                    user?.permissionLevel >= permissionLevel && (
                      <Select.Option value={id} key={id}>
                        {label}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>

          {!!relatedOrgOption?.length && (
            <Col md="6">
              <Form.Item
                name={selectFieldName}
                rules={[
                  {
                    required: true,
                    message: "Please an an option",
                  },
                ]}
                label={`${orgType}`}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relatedOrgOption.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="asset_name"
              label="Asset Name"
              rules={[
                {
                  required: true,
                  message: "Please provide asset name",
                },
              ]}
            >
              <Input placeholder="Name" type="text" className="form-control" />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Please provide amount",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[
                {
                  required: true,
                  message: "Please provide quantity of asset/material",
                },
              ]}
            >
              <Input
                placeholder="Quantity"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="procurement_type"
              rules={[
                {
                  required: true,
                  message: "Please an an option",
                },
              ]}
              label="Type of procurement"
            >
              <Select
                showSearch
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                placeholder="Asset / Material"
                allowClear
              >
                <Select.Option value="asset" key={1}>
                  Asset
                </Select.Option>
                <Select.Option value="material" key={2}>
                  Material
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loadingOrg || loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { ProcurementsForm };
