import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getAllGuarantor } from "store/clients";
import { getStaffsWithParams } from "store/clients";
import { getClientsWithParams } from "store/clients";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgDashboardIdField } from "utils";
import { positionToOrgMapping } from "utils";
import { setClientDateFilterAction } from "store/clients";

const useDashboardClientList = (generateOrgId, allTime=false) => {
  const {
    users: { user },
    client: { filterDate, clients, loading }
  } = useSelector((state) => ({
    users: state.users,
    client: state.clients,
  }));
  const [filterName, setFilterName] = useState("");
  const dispatch = useDispatch();
  const { type, orgId } = useParams();
  const [listRefreshTrigger, setListRefreshTrigger] = useState(false);
  const [cos, setCos] = useState();
  // useful for loan request form
  const [guarantorList, setGurantorList] = useState([]);
  const orgType = type || positionToOrgMapping[user.position];

  const handleListRefreshTrigger = () =>
    setListRefreshTrigger((current) => !current);

  const handleGetGuarantors = async () => {
    // For loan request form
    const _orgType = getOrgStaffProfileOrgField(user?.position);
    const orgIdField = getOrgDashboardIdField(user?.position);
    const orgFieldId =
      user?.position === "CO" ? user?.id : user?.[_orgType]?.id;

    let queryString = "";
    queryString = queryString.concat(`${orgIdField}=${orgFieldId}`);

    const res = await dispatch(getAllGuarantor(queryString));
    if (res) {
      setGurantorList(res);
    }
  };

  useEffect(async () => {
    let queryString = "";
    if (generateOrgId) {
      // Benefitial for cases were we need to get the org id field from the user data
      const _orgType = getOrgStaffProfileOrgField(user?.position);
      const orgIdField = getOrgDashboardIdField(user?.position);
      const orgFieldId =
        user?.position === "CO" ? user?.id : user?.[_orgType]?.id;

      queryString = queryString.concat(`${orgIdField}=${orgFieldId}`);
    } else {
      if (orgType) {
        const field_id = getOrgDashboardIdField(orgType);
        queryString = queryString.concat(`${field_id}=${orgId || user?.id}`);
      }
    }

    const client_querystring = allTime ? queryString : queryString?.concat(
      `&search_name=${filterName}&start_date=${filterDate?.[0].format("YYYY/MM/DD" || "")}&end_date=${
        filterDate?.[1].format("YYYY/MM/DD") || ""
      }`
    )

    dispatch(
      getClientsWithParams(
        orgType,
        orgId,
        client_querystring
      )
    );

    if (user?.position === "CO") {
      setCos([user]);
    } else {
      dispatch(getStaffsWithParams(queryString)).then((result) => {
        setCos(result);
      });
    }
  }, [listRefreshTrigger, filterDate, filterName]);

  const handleFilterChange = (range) => dispatch(setClientDateFilterAction(range));

  return {
    listData: clients?.[orgType]?.[orgId],
    type: orgType,
    id: orgId,
    handleListRefreshTrigger,
    handleGetGuarantors,
    guarantorList,
    cos,
    loading,
    filterDate,
    setFilterDate: handleFilterChange,
    filterName,
    setFilterName,
  };
};

export { useDashboardClientList };
