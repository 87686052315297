import { Input, Modal, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";

const InputModal = ({ value, onSubmit, children }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <Popconfirm
      className="input-modal"
      title={
        <Input
          value={inputValue}
          type="number"
          onChange={(e) => setInputValue(e.target.value)}
        />
      }
      onConfirm={() => onSubmit(inputValue)}
      okText="Submit"
      icon={null}
    >
      {children}
    </Popconfirm>
  );
};

export { InputModal };
