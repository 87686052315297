import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getAllCoAction } from "store/lists";
import { getAllBranchesAction } from "store/lists";
import {
  getAllZonesAction,
  getAllStateAction,
  getAllAreaAction,
} from "store/lists";
import { useQueryParam, StringParam } from "use-query-params";

const useDashboardList = () => {
  const {
    users: { user },
    lists,
  } = useSelector((state) => ({
    users: state.users,
    org: state.org,
    lists: state.lists,
  }));
  const { listType, id } = useParams();
  const [field] = useQueryParam("field", StringParam);
  const [fieldId] = useQueryParam("fieldId", StringParam);
  const [listData, setListData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log({ field, fieldId });
    switch (listType) {
      case "states":
        dispatch(getAllStateAction({ permissionLevel: user.permissionLevel }));
        break;
      case "zones":
        dispatch(
          getAllZonesAction({
            orgIdField: field,
            orgId: fieldId,
            permissionLevel: user.permissionLevel,
          })
        );
        break;
      case "areas":
        dispatch(
          getAllAreaAction({
            orgIdField: field,
            orgId: fieldId,
            permissionLevel: user.permissionLevel,
          })
        );
        break;
      case "branches":
        dispatch(
          getAllBranchesAction({
            orgIdField: field,
            orgId: fieldId,
            permissionLevel: user.permissionLevel,
          })
        );
        break;
      case "cos":
        dispatch(
          getAllCoAction({
            orgIdField: field,
            orgId: fieldId,
            permissionLevel: user.permissionLevel,
          })
        );
        break;
    }
  }, []);

  useEffect(() => {
    setListData(lists[listType]);
  }, [lists]);

  return {
    listData: listData,
    listType,
    id,
    loading: lists?.loading,
  };
};

export { useDashboardList };
