import { ModifiedAntTable } from "components/ModifiedAntTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAllAdditionalSavingsAction } from "store/clients";
import { getSummationOfFields } from "utils";

export const ClientAdditionalSavings = ({ clientId }) => {
  const [additionalSavings, setAdditionalSavings] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [summedData, setSummedData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const queryString = `client_id=${clientId}`;

    dispatch(getAllAdditionalSavingsAction(queryString)).then((res) => {
      setAdditionalSavings(res);
      setLoading(false);

      setSummedData(
        getSummationOfFields({
          data: res,
          fieldsToSum: ["amount"],
        })
      );
    });
  }, []);

  const additionalSavingsListColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summation",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString()}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        record?.client.fullname
      ),
    },
  ];

  return (
    <>
      <h5 className="mb-4">Additional Savings</h5>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={additionalSavingsListColumn}
        dataSource={additionalSavings}
        bordered
        showSummary={true}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </>
  );
};
