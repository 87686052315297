import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Select, Form, Input } from "antd";
import { useLocation, useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { createOrgSectorAction } from "store/org";
import { showAlertAction } from "store/alert";
import { permissionLevels } from "utils";
import { getOrgSectorAction, getAllSectorData } from "store/org";
import { getRelatedOrgFieldMeta } from "utils";
import { getStaffsWithParams } from "store/clients";
import { getOrgDashboardIdField } from "utils";
import { positionToOrgMapping } from "utils";

export const weekly_repayment_durations = ["8", "12", "16", "24", "36"];
export const daily_repayment_durations = ["20", "24", "30", "40"];

export const ORG_TYPE = [
  // Permission level represent the min amount of permission needed to create this org level
  { label: "Branch", id: "branches", permissionLevel: permissionLevels.AM },
  { label: "Area", id: "areas", permissionLevel: permissionLevels.ZM },
  { label: "Zone", id: "zones", permissionLevel: permissionLevels.SM },
  { label: "State", id: "states", permissionLevel: permissionLevels.DR },
];

export const INCOME_ORG_TYPE = [
  // Permission level represent the min amount of permission needed to create this org level
  { label: "Branch", id: "branches", permissionLevel: permissionLevels.BM },
  { label: "Area", id: "areas", permissionLevel: permissionLevels.AM },
  { label: "Zone", id: "zones", permissionLevel: permissionLevels.ZM },
  { label: "State", id: "states", permissionLevel: permissionLevels.SM },
];

const CreateOrgLevel = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { type, id } = useParams();
  const history = useHistory();
  const [orgType, setOrgType] = useState(type);
  const [orgData, setOrgData] = useState();
  const [relatedOrgLevelOption, setRelatedOrgLevelOption] = useState([]);
  const [users, setUsers] = useState([]);
  const [relatedOrgMeta, setRelatedOrgMeta] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedRepaymentPlan, setSelectedRepaymentPlan] = useState([]);
  const [selectedAllowedDays, setSelectedAllowedDays] = useState([]);
  const [selectedAllowedWeeks, setSelectedAllowedWeeks] = useState([]);
  const { user } = useSelector((state) => state.users);
  const isEditPage = pathname?.includes?.("edit");

  const getRelatedOrgSelectField = async () => {
    // E.G retrievs the states you can choose from if you are creating a zone.
    if (orgType === "states") {
      setRelatedOrgLevelOption([]);
    }
    setLoading(true);
    const res = await dispatch(getAllSectorData(relatedOrgMeta?.type));
    setRelatedOrgLevelOption(res);
    setLoading(false);
  };

  const handleGetEditingData = async () => {
    if (!isEditPage) return;
    setLoading(true);
    const org = await dispatch(getOrgSectorAction({ type, id }));
    if (org) {
      setOrgData({ ...org, manager_id: org?.manager?.id });
      form.resetFields();
    }
    setLoading(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const orgType = type || values?.type;
    const relatedIdField = relatedOrgMeta?.idField || "";
    dispatch(
      // Calls Different actions for each cases.
      createOrgSectorAction({
        data: {
          ...values,
          weekly_loan_durations: selectedAllowedWeeks.join(","),
          daily_loan_durations: selectedAllowedDays.join(","),
          replayment_plans: selectedRepaymentPlan.join(","),
          [relatedIdField]: values?.related_level_id,
        },
        type: orgType,
        id: orgData?.id,
      })
    )
      .then((result) => {
        dispatch(
          showAlertAction({
            message: result.message,
            level: result.status,
            isVisible: true,
          })
        );
        if (!isEditPage) {
          form.resetFields();
        }
        setSelectedAllowedDays([]);
        setSelectedAllowedWeeks([]);
      })
      .catch((err) => {
        dispatch(
          showAlertAction({
            message: err.message,
            level: err.status,
            isVisible: true,
          })
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    if (type === "cos") {
      // Route cos to the staff creation page, since the
      history.push(`/staffs/edit/${id}`);
      return;
    }

    const orgType = positionToOrgMapping[user.position];
    const field_id = getOrgDashboardIdField(orgType);
    let queryString = "";
    queryString = queryString.concat(`${field_id}=${user?.[orgType]?.id}`);

    handleGetEditingData();
    dispatch(getStaffsWithParams(queryString, "")).then((result) =>
      setUsers(result)
    );
  }, []);

  useEffect(() => {
    if (!orgType) return;

    if (orgType === "states") {
      setRelatedOrgMeta(undefined);
      setRelatedOrgLevelOption([]);
    }

    if (!isEditPage) {
      setRelatedOrgMeta(getRelatedOrgFieldMeta(orgType));
    }
  }, [orgType]);

  useEffect(() => {
    if (!orgType || orgType === "states" || isEditPage) return;
    getRelatedOrgSelectField();
  }, [relatedOrgMeta]);

  useEffect(() => {
    if (isEditPage) {
      setSelectedAllowedWeeks(
        orgData?.weekly_loan_durations
          ? orgData?.weekly_loan_durations?.split(",")
          : []
      );
      setSelectedAllowedDays(
        orgData?.daily_loan_durations
          ? orgData?.daily_loan_durations?.split(",")
          : []
      );
      setSelectedRepaymentPlan(
        orgData?.replayment_plans ? orgData?.replayment_plans?.split(",") : []
      );
    }
  }, [orgData]);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">
        {isEditPage ? "Edit" : "Create"} organization sector
      </h3>

      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          ...orgData,
          weekly_loan_durations: orgData?.weekly_loan_durations
            ? orgData?.weekly_loan_durations?.split(",")
            : [],
          daily_loan_durations: orgData?.daily_loan_durations
            ? orgData?.daily_loan_durations?.split(",")
            : [],
          repayment_plans: orgData?.replayment_plans
            ? orgData?.replayment_plans?.split(",")
            : [],
        }}
      >
        <Row>
          <Col md="6">
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select type",
                },
              ]}
              initialValue={type}
              label="Select org type"
            >
              <Select
                showSearch
                placeholder="Select Type"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
                disabled={isEditPage}
                onChange={(e) => setOrgType(e)}
              >
                {ORG_TYPE.map(
                  ({ id, label, permissionLevel }) =>
                    user?.permissionLevel >= permissionLevel && (
                      <Select.Option value={id} key={id}>
                        {label}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>

          {!!relatedOrgLevelOption?.length && (
            <Col md="6">
              <Form.Item
                name="related_level_id"
                rules={[
                  {
                    required: true,
                    message: "Please an an option",
                  },
                ]}
                label={`Select ${relatedOrgMeta?.singularName}`}
              >
                <Select
                  showSearch
                  placeholder={`Select ${relatedOrgMeta?.singularName}`}
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  allowClear
                  disabled={isEditPage}
                >
                  {relatedOrgLevelOption.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col className="" md="6">
            <Form.Item
              name="manager_id"
              rules={[
                {
                  required: false,
                  message: "Please select a manager",
                },
              ]}
              label="Select a manager (Optional)"
            >
              <Select
                showSearch
                placeholder="Select Manager"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {users.map(({ name, id }) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md="6">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please provide name",
                },
              ]}
              label="Organization Name"
              labelAlign="left"
            >
              <Input className="form-control" type="text" placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please provide email address",
                },
              ]}
              label="Email Address (required))"
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Email Address"
                disabled={isEditPage}
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              name="phone_number"
              rules={[
                {
                  required: false,
                  message: "Please provide phone number",
                },
              ]}
              label="Phone Number (Optional)"
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Phone Number"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              name="address"
              rules={[
                {
                  required: false,
                  message: "Please provide address",
                },
              ]}
              label="Address (Optional)"
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Address"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <Form.Item
              name="repayment_plans"
              rules={[
                {
                  required: false,
                },
              ]}
              label="Select Allowed Repayment Plans"
            >
              <Select
                showSearch
                placeholder="Select plans"
                optionFilterProp="children"
                className="custom-input multi-select-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
                onChange={(e) => setSelectedRepaymentPlan(e)}
                mode="multiple"
              >
                <Select.Option value="daily" key={1}>
                  Daily
                </Select.Option>
                <Select.Option value="weekly" key={2}>
                  Weekly
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {selectedRepaymentPlan.includes("daily") && (
            <Col md="4">
              <Form.Item
                name="daily_loan_durations"
                rules={[
                  {
                    required: false,
                  },
                ]}
                label="Select Allowed Daily Loan Durations (Days)"
              >
                <Select
                  showSearch
                  placeholder="Select duration"
                  optionFilterProp="children"
                  className="custom-input multi-select-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  allowClear
                  onChange={(e) => setSelectedAllowedDays(e)}
                  mode="multiple"
                >
                  {daily_repayment_durations.map((val, index) => (
                    <Select.Option value={val} key={index}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {selectedRepaymentPlan.includes("weekly") && (
            <Col md="4">
              <Form.Item
                name="weekly_loan_durations"
                rules={[
                  {
                    required: false,
                  },
                ]}
                label="Select Allowed Weekly Loan Durations (Weeks)"
              >
                <Select
                  showSearch
                  placeholder="Select duration"
                  optionFilterProp="children"
                  className="custom-input multi-select-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  allowClear
                  onChange={(e) => setSelectedAllowedWeeks(e)}
                  mode="multiple"
                >
                  {weekly_repayment_durations.map((val, index) => (
                    <Select.Option value={val} key={index}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { CreateOrgLevel };
