export const BASE_API_URL = process.env.REACT_APP_BASE_URL

const loans = [
  {
    client: "1",
    dailyPay: 5500,
    disbursmentFee: 10000,
    duration: 40,
    guarantor: "2",
    guarantorHouseAddress: "Lagos 2",
    gurantorOfficeAddress: "Lagos 2",
    houseAddress: "Abuja FCT 2",
    interest: 20000,
    interestRate: 10,
    loanAmount: "200000",
    maritalStatus: "single",
    netPay: 220000,
    officeAddress: "Abuja FCT 2",
    phoneNumber1: "1234567890",
    phoneNumber2: "1234567890",
    savingsAmount: "10000",
    verifiedName: "Momodu Dele",
  },
  {
    client: "2",
    dailyPay: 550,
    disbursmentFee: 500,
    duration: 20,
    guarantor: "1",
    guarantorHouseAddress: "Jos Plateau State",
    gurantorOfficeAddress: "Jos Plateau State",
    houseAddress: "Jos Plateau State",
    interest: 1000,
    interestRate: 10,
    loanAmount: "10000",
    maritalStatus: "single",
    netPay: 11000,
    officeAddress: "Jos Plateau State",
    phoneNumber1: "1234567809",
    phoneNumber2: "1234567809",
    savingsAmount: "2000",
    verifiedName: "Ehindero Raphael Ayo",
  },
  {
    client: "3",
    dailyPay: 12000,
    disbursmentFee: 10000,
    duration: 20,
    guarantor: "1",
    guarantorHouseAddress: "Purgtory 12",
    gurantorOfficeAddress: "Purgtory 12",
    houseAddress: "Hades",
    interest: 40000,
    interestRate: 20,
    loanAmount: "200000",
    maritalStatus: "single",
    netPay: 240000,
    officeAddress: "Hades",
    phoneNumber1: "2134567890",
    phoneNumber2: "12345678990",
    savingsAmount: "20000",
    verifiedName: "John Doe",
  },
];

export const users = [
  {
    id: 0,
    position: "CO",
    name: "Sample CO",
    phoneNumber: "234567765435",
    email: "co@gmail.com",
    address: "User Address",
    idNumber: "12345",
    permissionLevel: 1,
    branch: 1,
    area: 3,
    zone: 5,
    state: 7,
  },
  {
    id: 1,
    position: "BM",
    name: "Sample BM",
    phoneNumber: "234567765435",
    email: "bm@gmail.com",
    address: "User Address",
    idNumber: "123456",
    permissionLevel: 2,
    branch: 2,
    area: 4,
    zone: 5,
    state: 7,
  },
  {
    id: 2,
    position: "AM",
    name: "Sample AM",
    phoneNumber: "234567765435",
    email: "am@gmail.com",
    address: "User Address",
    idNumber: "12345",
    permissionLevel: 3,
    branch: 1,
    area: 3,
    zone: 5,
    state: 7,
  },
  {
    id: 3,
    position: "ZM",
    name: "Sample ZM",
    phoneNumber: "234567765435",
    email: "zm@gmail.com",
    address: "User Address",
    idNumber: "12345",
    permissionLevel: 4,
    branch: 2,
    area: 4,
    zone: 6,
    state: 7,
  },
  {
    id: 4,
    position: "SM",
    name: "Sample SM",
    phoneNumber: "234567765435",
    email: "sm@gmail.com",
    address: "User Address",
    idNumber: "12345",
    permissionLevel: 5,
    branch: 1,
    area: 3,
    zone: 5,
    state: 7,
  },
  {
    id: 5,
    position: "DR",
    name: "Sample DR",
    phoneNumber: "234567765435",
    email: "dr@gmail.com",
    address: "User Address",
    idNumber: "12345",
    permissionLevel: 6,
    branch: 2,
    area: 4,
    zone: 6,
    state: 7,
  },
];

export const user = users[5];

export const userDashboardData = {
  metadata: {
    ...user,
  },
  accumulations: {
    "Total Registration Fees": 2000,
    "Total Disbursement Fees": 2000,
    "Total Amount Disbursed": 1000,
    "Total Number of Clients": 10,
  },
};

export const cosDb = [
  {
    id: 0,
    position: "CO",
    name: "Sample CO 0",
    phoneNumber: "234567765435",
    email: "demo1@gmail.com",
    address: "User Address",
    idNumber: "12345",
    permissionLevel: 1,
    branch: 1,
    area: 4,
    zone: 6,
    state: 7,
  },
  {
    id: 1,
    position: "CO",
    name: "Sample CO 1",
    phoneNumber: "234567765435",
    email: "demo1@gmail.com",
    address: "User Address",
    idNumber: "12345",
    permissionLevel: 1,
    branch: 2,
    area: 3,
    zone: 5,
    state: 7,
  },
]

export const branchesDb = [
  {
    id: 1,
    name: "Branch 1",
    address: "Address 1",
    email: "branch@email.com",
    phoneNumber: "11234567323",
    area: 4,
    zone: 6,
    state: 7,
    manager: {
      ...users[1],
    },
  },
  {
    id: 2,
    name: "Branch 1",
    address: "Address 1",
    email: "branch@email.com",
    phoneNumber: "11234567323",
    area: 3,
    zone: 5,
    state: 7,
    manager: {
      ...users[1],
    },
  },
];

export const areasDb = [
  {
    id: 3,
    name: "Area 1",
    address: "Address 1",
    email: "area@email.com",
    phoneNumber: "11234567323",
    state: 7,
    zone: 6,
    manager: {
      ...users[2],
    },
  },
  {
    id: 4,
    name: "Area 2",
    address: "Address 1",
    email: "area@email.com",
    phoneNumber: "11234567323",
    state: 7,
    zone: 5,
    manager: {
      ...users[2],
    },
  }
];

export const zonesDb = [
  {
    id: 5,
    name: "Zone 1",
    address: "Address 1",
    email: "zone@email.com",
    phoneNumber: "11234567323",
    state: 7,
    manager: {
      ...users[3],
    },
  },
  {
    id: 6,
    name: "Zone 2",
    address: "Address 1",
    email: "zone@email.com",
    phoneNumber: "11234567323",
    state: 7,
    manager: {
      ...users[3],
    },
  },
];

export const statesDb = [
  {
    id: 7,
    name: "State 1",
    address: "Address 1",
    email: "state@email.com",
    phoneNumber: "11234567323",
    manager: {
      ...users[4],
    },
  },
];

export const allOrgs = [
  ...branchesDb,
  ...areasDb,
  ...zonesDb,
  ...statesDb,
]

export const registeredClientsData = [
  {
    id: "1",
    firstName: "Dele",
    hasPayedRefFee: false,
    houseAddress: "House 18 Ayetoro street",
    lastName: "Momodu",
    registrationDate: "2022-04-30",
    fullName: "Dele Mohmodu",
    maritalStatus: "single",
    numberOfChildren: "21",
    officeAddress: "House 18 Ayetoro street",
    phoneNumber1: "23412435645645",
    phoneNumber2: "23412435645645",
    nin: "12345678901",
    regFee: "200",
    title: "Mr",
    co: cosDb[0],
  },
  {
    id: "2",
    firstName: "Raphael",
    hasPayedRefFee: false,
    houseAddress: "House 18 Ayetoro street",
    lastName: "Ehindero",
    fullName: "Raphael Ehindero",
    nin: "12345678902",
    registrationDate: "2022-04-30",
    maritalStatus: "married",
    numberOfChildren: "21",
    officeAddress: "House 18 Ayetoro street",
    phoneNumber1: "23412435645645",
    phoneNumber2: "23412435645645",
    regFee: "200",
    title: "Mr",
    co: cosDb[1],
  },
  {
    id: "3",
    firstName: "John",
    hasPayedRefFee: false,
    houseAddress: "House 18 Ayetoro street",
    lastName: "Doe",
    fullName: "John Doe",
    nin: "12345678903",
    registrationDate: "2022-03-30",
    maritalStatus: "single",
    numberOfChildren: "21",
    officeAddress: "House 18 Ayetoro street",
    phoneNumber1: "23412435645645",
    phoneNumber2: "23412435645645",
    regFee: "200",
    title: "Mr",
    co: cosDb[1],
  },
];

export const makeid = (length) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters?.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
