import React, { useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";

import { Dropdown, Nav, Collapse } from "react-bootstrap";
import classNames from "classnames";

import logo from "assets/img/logo.svg";

const Sidebar = ({ routes, authUser }) => {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="sidebar-brand logo d-flex align-items-center justify-content-start">
          <Link className="d-flex align-items-center" to="/">
            <div className="logo-img ">
              <img src={logo} alt="..." />
            </div>
            Opulent
          </Link>
        </div>

        <Nav>
          {routes.map((prop, key) => {
            if (
              !prop.showOnSidebar ||
              prop.permissionLevel > authUser.permissionLevel
            ) {
              return;
            }
            if (prop.subLayouts) {
              const [isVisible, setIsVisible] = useState(false);

              return (
                <li className={activeRoute(prop.path)} key={key}>
                  <div
                    className={classNames(
                      isVisible ? "nav-link menu-expanded" : "nav-link",
                      "d-flex justify-content-between"
                    )}
                    onClick={() => setIsVisible((current) => !current)}
                    role="button"
                  >
                    <span>
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </span>

                    <i
                      className={classNames(
                        isVisible ? "fa fa-caret-down" : "fa fa-caret-up"
                      )}
                    ></i>
                  </div>

                  <Collapse in={isVisible} className="mx-3">
                    <div className="sub-menu">
                      {prop.subLayouts.map((subProps, key) => {
                        if (
                          subProps.showOnSidebar &&
                          subProps.permissionLevel <= authUser.permissionLevel
                        ) {
                          return (
                            <NavLink
                              to={subProps.path}
                              className="nav-link"
                              key={key}
                            >
                              <i className={subProps.icon} />
                              <p>{subProps.name}</p>
                            </NavLink>
                          );
                        }
                      })}
                    </div>
                  </Collapse>
                </li>
              );
            }

            return (
              <li className={activeRoute(prop.path)} key={key}>
                <NavLink
                  to={prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
