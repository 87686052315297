import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { handleLoginAction } from "store/users";
import { Login } from "views/Login";
import Admin from "./Admin";

const ProtectedRoutes = () => {
  const { user } = useSelector((state) => state.users);
  const { message, level, isVisible } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleLoginAction());
  }, []);
  return (
    <>
      <Alert variant={level} show={isVisible} style={{ zIndex: 10 }}>
        <span>{message}</span>
      </Alert>
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) =>
            !!user ? <Redirect to="/" /> : <Login {...props} />
          }
        />
        <Route
          path="/*"
          render={(props) =>
            !!user ? <Admin {...props} key={1} /> : <Redirect to="/login" />
          }
        />
      </Switch>
    </>
  );
};

export { ProtectedRoutes };
