import { parse } from "query-string";
import { getSingularOrgListName } from "utils";

const useListTitle = (listName) => {
  const { name, org, guarantor_name } = parse(window.location.search);

  if (guarantor_name) {
    return {
      fullListName: `List of ${listName} guaranteed by ${guarantor_name}`,
    }
  }

  if (name && org) {
    return {
      fullListName: `List of ${listName} in ${name}`,
    };
  }

  return {
    fullListName: `List of ${listName}`,
  };
};

export { useListTitle };

