import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Dropdown, Menu, Select, Space, Table } from "antd";
import { useDashboardList } from "../../hooks/useDashboardList";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { assignListClientsToCoAction } from "store/lists";
import { showAlertAction } from "store/alert";
import { useQueryParam, NumberParam } from "use-query-params";
import { ModifiedAntTable } from "components/ModifiedAntTable";
import { deleteOrgSectorAction } from "store/org";
import { useListTitle } from "hooks/useListTitle";

const DashboardList = () => {
  const { listData, listType, id, loading } = useDashboardList();
  const [selectedRowKeysState, setSelectedRowKeysState] = useState([]);
  const [selectAction, setSelectAction] = useState(false);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { users, user } = useSelector((state) => state.users);
  const isClientList = listType === "clients";
  const dispatch = useDispatch();

  const { fullListName: title } = useListTitle(listType)

  const handleReAssign = async (CoId) => {
    const ids = selectedRowKeysState?.join(",");
    const result = await dispatch(
      assignListClientsToCoAction({
        clientsToAssign: ids,
        newCoId: CoId,
        oldCoId: id,
      })
    );
    dispatch(
      showAlertAction({
        message: result.message,
        level: result.level,
        isVisible: true,
      })
    );
  };

  const menu = (
    <Menu
      items={users?.map(({ name, id }) => ({
        label: <div onClick={() => handleReAssign(id)}>{name}</div>,
        key: id,
      }))}
    />
  );

  const handleDeleteOrg = async (id) => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${listType}? Note: This would delete everything within this organization.`
      )
    ) {
      await dispatch(deleteOrgSectorAction({ type: listType, id}));
    }
  };

  const getOrgColumn = useCallback(() => {
    const columns = [
      {
        title: "S/N",
        dataIndex: "id",
        render: (text, record, index) =>
          ((Number(page) || 1) - 1) * 50 + (index + 1),
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/org/${listType}/${record.id}`}>
            {text} <i class="fa fa-link" aria-hidden="true"></i>
          </Link>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Edit",
        render: (text, record) => (
          <Link to={`/org/${listType}/${record.id}/edit`}>
            Edit <i className="fa fa-pencil" aria-hidden="true"></i>
          </Link>
        ),
      },
    ];

    if (listType !== "cos" && listType !== "clients") {
      columns.splice(3, 0, {
        title: "Manager",
        dataIndex: ["manager", "name"],
      });
    }

    if (["DR", "SM", "ZM"].includes(user?.position)) {
      columns.push({
        title: "",
        dataIndex: "id",
        render: (text, record) => (
          <span role="button" onClick={() => handleDeleteOrg(record?.id)}>
            <i className="fas fa-trash"></i>
          </span>
        ),
        summary: "-",
      });
    }

    return columns;
  }, []);

  const clientColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Link to={`/clients/${record.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i>{" "}
          {`${record?.firstname} ${record?.surname}`}
        </Link>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number1",
    },
    {
      title: "Date",
      render: (text, record) => <>{record?.reg_date}</>,
    },
  ];

  return (
    <Container fluid>
      <h3 className="page-title mb-4">{title}</h3>

      {isClientList && (
        <Row>
          <Col md="4">
            <Select
              placeholder="Choose action"
              className="action-select mb-3"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setSelectAction(value)}
            >
              <Select.Option value={"newCo"} key={"newCo"}>
                Assign to new CO
              </Select.Option>
            </Select>
          </Col>

          {selectAction === "newCo" && (
            <Col md="4" className="align-items-center">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="antd-dropdown"
              >
                <Space>
                  Select New Co <i className="fa fa-caret-down"></i>
                </Space>
              </Dropdown>
            </Col>
          )}
        </Row>
      )}
      <ModifiedAntTable
        rowSelection={
          isClientList
            ? {
                type: "checkbox",
                selectedRowKeys: selectedRowKeysState,
                onChange: (selectedRowKeys) =>
                  setSelectedRowKeysState(selectedRowKeys),
              }
            : undefined
        }
        rowKey="id"
        scroll={{ x: "max-content" }}
        columns={isClientList ? clientColumns : getOrgColumn()}
        dataSource={listData}
        bordered
        loading={loading}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </Container>
  );
};

export { DashboardList };
