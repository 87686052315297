import React, { useCallback } from "react";
import { Card, Container, Row, Col, NavLink } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router";
import { useDashboard } from "hooks";
import { RenderList } from "components/RenderList";
import { Link } from "react-router-dom";
import { PaymentDistributionTable } from "../../components/PaymentDistributionTable";
import moment from "moment";
import { positionsMapping, getDashboardUrlWithParam } from "utils";
import { FieldPaymentModal } from "./FieldPaymentModal";

/**
 * type = "CO" | "BM" | "ZM" | "AM" | "SM" | "DR"
 */
const Dashboard = ({ permissionLevel }) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    metadata,
    accumulations,
    isOrgDashboard,
    userOrgField,
    orgType,
    user,
    viewListData,
    cardsData,
    useUserDashboard,
    tableData,
    onDateRangeChange,
    handleApproveRemittance,
    position,
    loadingTableData,
    todayCollection,
    onViewDailypay,
    loadingDashboard,
  } = useDashboard({
    permissionLevel,
    id: id,
  });

  const { pathname } = useLocation();

  const defaultUrlParams = useCallback(
    () => ({
      isOrgDashboard,
      orgType,
      id: metadata?.id,
      orgName: metadata?.name,
      user,
      userOrgField,
    }),
    [isOrgDashboard, orgType, metadata, user, userOrgField]
  );

  const getDashboardLink = ({ path, prefixPath }) =>
    getDashboardUrlWithParam({
      ...defaultUrlParams(),
      path,
      prefixPath,
    });

  const cards = [
    {
      title: "Total Registration Fee",
      subtitle: `₦ ${
        cardsData?.total_reg_fee
          ? Number(cardsData?.total_reg_fee).toLocaleString()
          : "0"
      }`,
      footerText: moment().format("DD/MM/YYYY"),
      iconClassName: "nc-icon nc-chart text-warning",
      onClick: () => {
        if (orgType) {
          return history.push(
            `/clients/${orgType}/${metadata?.id}/?status=REGISTERED&org=${orgType}&name=${metadata?.name}`
          );
        }
        history.push(
          `/clients/?status=REGISTERED&org=${orgType}&name=${metadata?.name}`
        );
      },
    },
    {
      title: "Total Disbursment Fee",
      subtitle: `₦ ${
        cardsData?.total_disbursement_fee
          ? Number(cardsData?.total_disbursement_fee).toLocaleString()
          : "0"
      }`,
      footerText: moment().format("DD/MM/YYYY"),
      iconClassName: "nc-icon nc-chart text-warning",
      onClick: () => {
        if (orgType) {
          return history.push(
            `/loans/${orgType}/${metadata?.id}/?org=${orgType}&name=${metadata?.name}`
          );
        }
        history.push(`/loans/?org=${orgType}&name=${metadata?.name}`);
      },
    },
    {
      title: "Completed Loans",
      subtitle: cardsData?.completed_loans,
      extra: (
        <span>
          Closed loans:{" "}
          <strong className="text-white">{cardsData?.waived_loans}</strong>
          <br />
          Union Fee:{" "}
          <strong className="text-white">
            ₦ {Number(cardsData?.total_union_fee || 0).toLocaleString()}
          </strong>
        </span>
      ),
      iconClassName: "nc-icon nc-chart text-warning",
      footerText: moment().format("DD/MM/YYYY"),
      onClick: () => {
        if (orgType) {
          return history.push(
            `/loans/${orgType}/${metadata?.id}/?status=COMPLETED&org=${orgType}&name=${metadata?.name}`
          );
        }
        history.push(
          `/loans/?status=COMPLETED&org=${orgType}&name=${metadata?.name}`
        );
      },
    },
    {
      title: "Number of Clients",
      subtitle: (
        <>
          <small>
            <strong>total </strong>
          </small>
          {cardsData?.number_of_clients} <br />{" "}
          <small>
            <strong>active </strong>
          </small>
          {cardsData?.number_of_active_clients}
        </>
      ),
      iconClassName: "nc-icon nc-chart text-warning",
      onClick: () => {
        if (orgType) {
          return history.push(
            `/clients/${orgType}/${metadata?.id}?org=${orgType}&name=${metadata?.name}`
          );
        }
        history.push(`/clients/?org=${orgType}&name=${metadata?.name}`);
      },
    },
  ];

  const RenderDahboardCards = ({
    title,
    subtitle,
    footerText,
    iconClassName,
    extra,
    onClick,
  }) => {
    return (
      <Col lg="3" sm="6">
        <Card className="card-stats" onClick={onClick}>
          <Card.Body>
            <Row>
              <Col xs="5">
                <div className="icon-big text-center icon-warning">
                  <i className={iconClassName}></i>
                </div>
              </Col>
              <Col xs="7">
                <div className="numbers">
                  <p className="card-category">{title}</p>
                  <Card.Title as="h4">{subtitle}</Card.Title>
                  <p className="card-category mb-0">{extra}</p>
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <hr />
            <div className="stats">{footerText}</div>
          </Card.Footer>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Container fluid>
        <Row className="col-12 mb-4 greetings">
          <Col className="p-0" md="6">
            Welcome {isOrgDashboard && "to"} {metadata?.name}
          </Col>

          <Col className="d-flex justify-content-end text-muted p-0 gap-2">
            <span className="badge badge-pill color-white">
              {useUserDashboard
                ? positionsMapping[metadata?.position]
                : `${orgType}`.toLocaleUpperCase()}
            </span>
            <FieldPaymentModal orgType={orgType} orgId={metadata?.id} />
          </Col>
        </Row>
        <Row>
          {cards.map((props, index) => (
            <RenderDahboardCards key={index} {...props} />
          ))}
        </Row>

        <Row>
          <Col md="6">
            <RenderList
              exclude={[
                "id",
                "ManagerId",
                "permissionLevel",
                "area_id",
                "branch_id",
                "zone_id",
                "state_id",
                "auth_text",
                "last_notifications_view_time",
              ]}
              data={metadata}
              extraContent={
                <div className="d-flex flex-wrap gap-2">
                  {metadata?.position !== "CO" && (
                    <>
                      <Link
                        to={getDashboardLink({ path: "org_expenses" })}
                        className="badge badge-outline"
                      >
                        Organisation Expenses
                      </Link>
                    </>
                  )}
                  {metadata?.position !== "CO" && (
                    <>
                      <Link
                        to={getDashboardLink({ path: "expenses" })}
                        className="badge badge-outline"
                      >
                        Expenses
                      </Link>
                    </>
                  )}
                  {metadata?.position !== "CO" && (
                    <>
                      <Link
                        to={getDashboardLink({ path: "specialincome" })}
                        className="badge badge-outline"
                      >
                        Special Incomes
                      </Link>
                    </>
                  )}
                  <Link
                    to={getDashboardLink({ path: "seizedassets" })}
                    className="badge badge-outline"
                  >
                    Siezed Assets
                  </Link>

                  <Link
                    to={getDashboardLink({ path: "bank_withdrawals" })}
                    className="badge badge-outline"
                  >
                    Bank Withdrawals
                  </Link>


                  <Link
                    to={getDashboardLink({ path: "savings_withdrawals" })}
                    className="badge badge-outline"
                  >
                    Savings Withdrawals
                  </Link>
                  {metadata?.position !== "CO" && (
                    <>
                      <Link
                        to={getDashboardLink({ path: "bank_deposits" })}
                        className="badge badge-outline"
                      >
                        Bank Deposits
                      </Link>
                    </>
                  )}

                  <Link
                    to={getDashboardLink({ path: "remittance" })}
                    className="badge badge-outline"
                  >
                    Remitance to BM
                  </Link>

                  <Link
                    to={getDashboardLink({ path: "procurements" })}
                    className="badge badge-outline"
                  >
                    Procurements
                  </Link>

                  <Link
                    to={getDashboardLink({ path: "reports" })}
                    className="badge badge-outline"
                  >
                    Reports
                  </Link>


                  <Link
                    to={getDashboardLink({ path: "waiverbalance" })}
                    className="badge badge-outline"
                  >
                    Waiver balance
                  </Link>

                  <Link
                    to={getDashboardLink({ path: "additionalsavings" })}
                    className="badge badge-outline"
                  >
                    Additional savings
                  </Link>

                  <Link
                    to={getDashboardLink({ path: "field-collections" })}
                    className="badge badge-outline"
                  >
                    Field collections
                  </Link>
                </div>
              }
            />
          </Col>
          <Col md="6">
            <RenderList
              data={accumulations}
              loading={loadingDashboard}
              extraContent={
                <>
                  <div className="d-flex flex-wrap gap-2">
                    {viewListData?.map((data) => (
                      <Link to={data?.link} className="badge badge-outline">
                        {" "}
                        {data?.name}{" "}
                      </Link>
                    ))}

                    {metadata?.managerId && !useUserDashboard && (
                      <>
                        <Link
                          to={`/staffs/edit/${metadata?.managerId}`}
                          className="badge badge-outline"
                        >
                          View Manager's Detail
                        </Link>
                        <br />
                      </>
                    )}
                    {metadata?.position !== "CO" && (
                      <Link
                        to={
                          orgType
                            ? `/staffs/${orgType}/${metadata?.id}/?org=${orgType}&name=${metadata?.name}`
                            : `/staffs/?org=${orgType}&name=${metadata?.name}`
                        }
                        className="badge badge-outline"
                      >
                        View Staffs
                      </Link>
                    )}
                    <NavLink
                      onClick={() => onViewDailypay(metadata?.name)}
                      className="badge badge-outline"
                    >
                      View Daily Pay
                    </NavLink>

                    <Link
                      to={
                        orgType
                          ? `/guarantors/${orgType}/${metadata?.id}/?org=${orgType}&name=${metadata?.name}`
                          : `/guarantors/?org=${orgType}&name=${metadata?.name}`
                      }
                      className="badge badge-outline"
                    >
                      View Guarantors
                    </Link>
                  </div>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <h6>
              Today's Collections: ₦
              {todayCollection === "-"
                ? " Not available"
                : Number(todayCollection).toLocaleString()}
            </h6>
            <PaymentDistributionTable
              dataset={tableData}
              onDateRangeChange={onDateRangeChange}
              handleApproveRemittance={handleApproveRemittance}
              showApproval={pathname === "/" && !(position === "DR")}
              loadingTableData={loadingTableData}
              manageriD={metadata?.managerId}
              figures={cardsData}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { Dashboard };
