import { Descriptions, Drawer } from "antd";
import React from "react";
import { getTotal, getCashInHand } from "utils";

const PaymentDistributionDrawer = ({ activeRecord, setActiveRecord }) => {
  return (
    <Drawer
      title={activeRecord?.date}
      placement="bottom"
      onClose={() => setActiveRecord()}
      visible={!!activeRecord}
      size="large"
    >
      <Descriptions title="Totals">
        <Descriptions.Item label="Total collection">
          ₦ {getTotal(activeRecord)}
        </Descriptions.Item>
        <Descriptions.Item label="Cash in hand">
          ₦ {getCashInHand(activeRecord)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Realisable">
        <Descriptions.Item label="Installment">
          ₦ {Number(activeRecord?.realizable_installment || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Savings">
          ₦ {Number(activeRecord?.realizable_savings || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Realised">
        <Descriptions.Item label="Installment">
          ₦ {Number(activeRecord?.realised_installment || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Savings">
          ₦ {Number(activeRecord?.realised_savings || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Additional Savings">
          ₦ {Number(activeRecord?.total_additional_savings || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Approved without approval date">
        <Descriptions.Item label="Daily Payment">
          ₦ {Number(activeRecord?.approved_pay_without_approval_date || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Savings">
          ₦ {Number(activeRecord?.approved_savings_without_approval_date || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Waived">
        <Descriptions.Item label="Installment">
          ₦ {Number(activeRecord?.waived_installment || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Savings">
          ₦ {Number(activeRecord?.waived_savings || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Generals">
        <Descriptions.Item label="Expenses">
          ₦ {Number(activeRecord?.expenses || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Special Income">
          ₦ {Number(activeRecord?.special_income || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Asset auction amount">
          ₦ {Number(activeRecord?.sold_assets || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Asset retrieval amount">
          ₦ {Number(activeRecord?.retrieved_assets || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Waiver Balance">
          ₦ {Number(activeRecord?.total_waiver_balance || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Fees">
        <Descriptions.Item label="Registration Fee">
          ₦ {Number(activeRecord?.total_reg_fee || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Disbursement Fee">
          ₦ {Number(activeRecord?.total_disbursement_fee || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Union Fee">
          ₦ {Number(activeRecord?.total_union_fee || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Waiver Fee">
          ₦ {Number(activeRecord?.total_waiver_fee || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Other Payments">
        <Descriptions.Item label="Cash In Hand Recovered">
          ₦ {Number(activeRecord?.prev_cash_in_hand_paid || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Daily cash in hand recoverd">
          ₦ {Number(activeRecord?.cash_in_hand_recovered_in_future || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Arreas payment">
          ₦{" "}
          {Number(
            activeRecord?.approved_before_date_dailypay || 0
          ).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Advance">
          ₦ {Number(activeRecord?.advanced_payment || 0).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Withdrawals">
        <Descriptions.Item label="Cash Withdrawal">
          ₦ {Number(activeRecord?.total_cash_withdrawal || 0).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Dailypay Withdrawal">
          ₦{" "}
          {Number(
            activeRecord?.total_withdrawal_for_dailypay || 0
          ).toLocaleString()}
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};

export { PaymentDistributionDrawer };
