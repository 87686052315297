import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { parse } from "query-string";
import { DatePicker, Input, Select } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { assignListClientsToCoAction } from "store/lists";
import { useDashboardClientList } from "hooks/useDashboardClientList";
import { useQueryParam, NumberParam } from "use-query-params";
import { ModifiedAntTable } from "components/ModifiedAntTable";
import { deleteClientAction } from "store/clients";
import { useFilterDateDropdown } from "hooks/useFilterDateDropdown";
import { useListTitle } from "hooks/useListTitle";

const filters = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Registered",
    value: "REGISTERED",
  },
  {
    label: "All",
    value: "",
  },
  {
    label: "Has Completed all loans",
    value: "COMPLETED LOANS",
  },
];

const DashboardClientList = () => {
  const {
    id,
    listData,
    handleListRefreshTrigger,
    cos,
    loading,
    filterDate,
    setFilterDate,
    filterName,
    setFilterName,
  } = useDashboardClientList();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { status: routeStatus } = parse(window.location.search);

  const [selectedRowKeysState, setSelectedRowKeysState] = useState([]);
  const [selectAction, setSelectAction] = useState();
  const [filteredData, setFilteredData] = useState(listData);
  const [filterStatus, setFilterStatus] = useState(undefined);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { renderFilter } = useFilterDateDropdown({ setFilterDate });
  const { fullListName } = useListTitle("Clients");

  const handleFilter = () => {
    setFilteredData(() =>
      listData?.filter(
        (val) =>
          `${val?.surname} ${val?.firstname}`
            ?.toLowerCase()
            ?.includes(filterName?.toLowerCase()) &&
          (val.status === filterStatus || filterStatus === "" || !filterStatus)
      )
    );
  };

  const handleDeleteLoan = async (id) => {
    if (window.confirm("Are you sure you want to delete this client?.")) {
      await dispatch(deleteClientAction(id));
      handleListRefreshTrigger();
    }
  };

  // Handle Filter
  useEffect(() => {
    handleFilter();
  }, [filterStatus, listData]);

  useEffect(() => {
    setFilterStatus(routeStatus);
  }, [routeStatus]);

  const handleReAssign = async (CoId) => {
    const ids = selectedRowKeysState?.join(",");
    await dispatch(
      assignListClientsToCoAction({
        clientsToAssign: ids,
        newCoId: CoId,
        oldCoId: id,
      })
    );

    handleListRefreshTrigger();
  };

  const getClientColumns = useCallback(() => {
    const columns = [
      {
        title: "S/N",
        dataIndex: "id",
        render: (text, record, index) =>
          ((Number(page) || 1) - 1) * 50 + (index + 1),
        summary: ["-", "Summations"],
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/clients/${record.id}`}>
            <i className="fa fa-link" aria-hidden="true"></i>{" "}
            {`${record?.surname} ${record?.firstname}`}
          </Link>
        ),
      },
      {
        title: "Credit Officer",
        dataIndex: "co_fullname",
      },
      {
        title: "status",
        render: (text, record) => (
          <div className="status-badge badge badge-sm">{record?.status}</div>
        ),
      },
      {
        title: "Reg Date",
        render: (text, record) => <>{record?.reg_date}</>,
      },
    ];

    if (["DR", "SM", "ZM"].includes(user?.position)) {
      columns.push({
        title: "",
        dataIndex: "id",
        render: (text, record) => (
          <span role="button" onClick={() => handleDeleteLoan(record?.id)}>
            <i className="fas fa-trash"></i>
          </span>
        ),
        summary: "-",
      });
    }

    return columns;
  }, [user]);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Input.Group compact className="mb-3 filter-panel">
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "50%" }}
          placeholder="Filter by status"
          value={filterStatus}
          onChange={(val) => setFilterStatus(val)}
        >
          {filters?.map(({ label, value }, index) => (
            <Select.Option key={index} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <Input
          style={{ width: "50%" }}
          placeholder="Filter by name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Input.Group>
      <Row compact className="mb-3 filter-panel">
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            allowClear
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val)}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      {!!selectedRowKeysState?.length && user?.position !== "CO" && (
        <Row>
          <Col md="4">
            <Select
              placeholder="Choose action"
              className="default-ant-select mb-3"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setSelectAction(value)}
            >
              <Select.Option value={"newCo"} key={"newCo"}>
                Assign to new CO
              </Select.Option>
            </Select>
          </Col>

          {selectAction === "newCo" && (
            <Col md="4" className="align-items-center">
              <Select
                placeholder="Choose action"
                className="default-ant-select mb-3"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => handleReAssign(value)}
                value={null}
                showSearch
              >
                {cos?.map((val, index) => (
                  <Select.Option value={val?.id} key={val?.id} id={val?.id}>
                    {val?.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
        </Row>
      )}

      {loading && (
        <div className="d-flex w-100 justify-content-center align-items-center my-2">
          <i class="fas fa-spinner fa-spin "></i>{" "}
          <span className="ml-2">Loading...</span>
        </div>
      )}

      <ModifiedAntTable
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedRowKeysState,
          onChange: (selectedRowKeys) =>
            setSelectedRowKeysState(selectedRowKeys),
        }}
        rowKey="id"
        scroll={{ x: "max-content" }}
        columns={getClientColumns()}
        dataSource={filteredData}
        bordered
        showSummary={false}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </Container>
  );
};

export { DashboardClientList };
