import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { parse } from "query-string";
import { DatePicker, Dropdown, Input, Menu, Select, Space, Table } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDashboardLoanList } from "hooks/useDashboardLoanList";
import { LOANS_STATUS, DAILY_PAY_STATUS } from "utils";
import classNames from "classnames";
import { getStatusClassName } from "utils";
import { confirmBatchPayment, confirmBatchSavings } from "store/loans";
import { showAlertAction } from "store/alert";
import { deleteLoan } from "store/loans";
import { useQueryParam, NumberParam } from "use-query-params";
import { ModifiedAntTable } from "components/ModifiedAntTable";
import { getSummationOfFields } from "utils";
import { useFilterDateDropdown } from "hooks/useFilterDateDropdown";
import { useListTitle } from "hooks/useListTitle";

const DashboardLoanList = ({ showFilter = true }) => {
  const {
    listData,
    unions,
    handleListRefreshTrigger,
    loading,
    setFilterDate,
    filterDate,
  } = useDashboardLoanList();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { status: routeStatus } = parse(window.location.search);
  const { fullListName } = useListTitle("loans");

  const [selectedRowKeysState, setSelectedRowKeysState] = useState([]);

  const [filteredData, setFilteredData] = useState(listData);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState(undefined);
  const [filterDailyStatus, setFilterDailyStatus] = useState(undefined);
  const [filterUnion, setFilterUnion] = useState(undefined);
  const [filterPaymentPlan, setFilterPaymentPlan] = useState(undefined);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { renderFilter } = useFilterDateDropdown({ setFilterDate });

  const [summedData, setSummedData] = useState({});

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredData,
        fieldsToSum: ["loan_amount", "daily_pay"],
      })
    );
  }, [filteredData, listData]);

  useEffect(() => {
    if (listData?.length) {
      handleFilter();
    }
  }, [listData]);

  const loanColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: ["-", "Summations"],
    },
    {
      title: "Client",
      dataIndex: "name",
      render: (text, record) => (
        <Link to={`/loans/${record.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i>{" "}
          {`${record?.client_fullname}`}
        </Link>
      ),
      summary: "-",
    },
    {
      title: "Request date",
      dataIndex: "creation_date",
      summary: "-",
    },
    {
      title: "Payment Plan",
      dataIndex: "repayment_plan",
      summary: "-",
    },
    {
      title: "Amount ₦",
      dataIndex: "loan_amount",
      render: (text, record) => <>{Number(text).toLocaleString()}</>,
      summary: summedData["loan_amount"],
    },
    {
      title: "Loan Daily Pay ₦",
      dataIndex: "daily_pay",
      render: (text, record) => <>{Number(text).toLocaleString()}</>,
      summary: summedData["daily_pay"],
    },
    {
      title: "Daily Payment Status",
      render: (text, record) => (
        <div
          className={classNames(
            "status-badge badge badge-sm",
            record?.today_payment_stat === "PAID"
              ? "badge-success"
              : "badge-warning"
          )}
        >
          {record?.today_payment_stat}
        </div>
      ),
      summary: "-",
    },
    {
      title: "Loan Status",
      render: (text, record) => (
        <div
          className={classNames(
            "status-badge badge badge-sm",
            getStatusClassName(record?.loan_status)
          )}
        >
          {record?.loan_status}
        </div>
      ),
      summary: "-",
    },
  ];

  const [tableColumns, setTableColumns] = useState(loanColumns);

  const handleDeleteLoan = async (id) => {
    if (window.confirm("Are you sure you want to delete this loan?.")) {
      await dispatch(deleteLoan(id));
      handleListRefreshTrigger();
    }
  };

  const handleFilter = useCallback(() => {
    setFilteredData(() => {
      return listData?.filter((val) => {
        const conditions =
          val?.client_fullname
            ?.toLowerCase()
            ?.includes(filterName?.toLowerCase()) &&
          (val?.loan_status === filterStatus ||
            filterStatus === "" ||
            !filterStatus) &&
          (val?.today_payment_stat === filterDailyStatus ||
            filterDailyStatus === "" ||
            !filterDailyStatus) &&
          (val?.client?.union?.id === filterUnion ||
            filterUnion === "" ||
            !filterUnion) &&
          (val?.repayment_plan === filterPaymentPlan ||
            filterPaymentPlan === "" ||
            !filterPaymentPlan);
        return conditions;
      });
    });
  }, [
    filterName,
    filterStatus,
    listData,
    filterDailyStatus,
    filterUnion,
    filterPaymentPlan,
  ]);

  const handleApproveLoans = async () => {
    if (user?.position !== "CO") {
      dispatch(
        showAlertAction({
          message: "This action can only be carried out by the credit officer",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    if (
      window.confirm(
        "Are you sure you want to confirm daily payment for all selected users?."
      )
    ) {
      dispatch(confirmBatchPayment({ ids: selectedRowKeysState })).then(() => {
        handleListRefreshTrigger();
      });
    }
  };

  const handleApproveSavings = async () => {
    if (user?.position !== "CO") {
      dispatch(
        showAlertAction({
          message: "This action can only be carried out by the credit officer",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    if (
      window.confirm(
        "Are you sure you want to confirm daily payment for all selected users?."
      )
    ) {
      dispatch(confirmBatchSavings({ ids: selectedRowKeysState })).then(() => {
        handleListRefreshTrigger();
      });
    }
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <div onClick={() => handleApproveLoans()}>
              Approve Daily Payment
            </div>
          ),
          key: 1,
        },
        {
          label: (
            <div onClick={() => handleApproveSavings()}>
              Approve Daily Savings
            </div>
          ),
          key: 1,
        },
      ]}
    />
  );

  useEffect(() => {
    if (["DR", "SM", "ZM", "AM"].includes(user?.position)) {
      setTableColumns((current) => [
        ...loanColumns,
        {
          title: "",
          dataIndex: "id",
          render: (text, record) => (
            <span role="button" onClick={() => handleDeleteLoan(record?.id)}>
              <i className="fas fa-trash"></i>
            </span>
          ),
          summary: "-",
        },
      ]);
    } else {
      setTableColumns(() => [...loanColumns]);
    }
  }, [summedData]);

  // Handle Filter
  useEffect(() => {
    handleFilter();
  }, [
    filterName,
    filterStatus,
    filterDailyStatus,
    filterUnion,
    filterPaymentPlan,
  ]);

  useEffect(() => {
    setFilterStatus(routeStatus);
  }, [routeStatus]);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">{fullListName}</h3>

      {showFilter && (
        <>
          <Input.Group compact className="mb-3 filter-panel">
            <Select
              allowClear
              className="default-ant-select"
              style={{ width: "30%" }}
              placeholder="Filter by loan status"
              value={filterStatus}
              onChange={(val) => setFilterStatus(val)}
            >
              {LOANS_STATUS.map((val) => (
                <Select.Option value={val} key={val}>
                  {val}
                </Select.Option>
              ))}
            </Select>
            <Select
              allowClear
              className="default-ant-select"
              style={{ width: "40%" }}
              placeholder="Filter by daily payment status"
              value={filterDailyStatus}
              onChange={(val) => setFilterDailyStatus(val)}
            >
              {DAILY_PAY_STATUS.map((val) => (
                <Select.Option value={val} key={val}>
                  {val}
                </Select.Option>
              ))}
            </Select>
            <Input
              style={{ width: "30%" }}
              placeholder="Filter by name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            />
          </Input.Group>

          <Row>
            <Col md="4" className="d-flex">
              {renderFilter()}
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                onChange={setFilterDate}
                className="mb-3"
                value={filterDate}
                allowClear={false}
              />
            </Col>

            <Col md="4">
              <Select
                allowClear
                className="default-ant-select mb-2 mb-sm-0"
                placeholder="Filter by Union"
                value={filterUnion}
                onChange={(val) => setFilterUnion(val)}
              >
                {unions?.map((val) => (
                  <Select.Option value={val.id} key={val.id}>
                    {val?.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col md="4">
              <Select
                allowClear
                className="default-ant-select mb-2 mb-sm-0"
                placeholder="Filter repayment plan"
                value={filterPaymentPlan}
                onChange={(val) => setFilterPaymentPlan(val)}
              >
                <Select.Option key="daily" value="daily">
                  Daily
                </Select.Option>
                <Select.Option key="weekly" value="weekly">
                  Weekly
                </Select.Option>
              </Select>
            </Col>
          </Row>
        </>
      )}

      {!!selectedRowKeysState?.length && (
        <Row>
          <Col md="4">
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              className="antd-dropdown"
            >
              <Space>
                Chose Action <i className="fa fa-caret-down"></i>
              </Space>
            </Dropdown>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          {loading && (
            <div className="d-flex w-100 justify-content-center align-items-center my-2">
              <i class="fas fa-spinner fa-spin "></i>{" "}
              <span className="ml-2">Loading...</span>
            </div>
          )}
          <ModifiedAntTable
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRowKeysState,
              onChange: (selectedRowKeys) =>
                setSelectedRowKeysState(selectedRowKeys),
            }}
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={tableColumns}
            dataSource={filteredData}
            bordered
            showSummary={true}
            pagination={{
              defaultCurrent: page,
              current: page,
              defaultPageSize: 50,
              hideOnSinglePage: true,
              onChange: (current) => setPage(current),
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export { DashboardLoanList };
