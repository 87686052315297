import { Input, Modal, Row, Select } from "antd";
import { ClientSelectField } from "components/ClientSelectField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlertAction } from "store/alert";
import { makeFieldCollection } from "store/loans";
import { getOrgDashboardIdField } from "utils";

export const FieldPaymentModal = ({ orgType, orgId }) => {
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [isOpen, setIsOpen] = useState(false);
  const [client, setClient] = useState();
  const [description, setDescription] = useState("");
  const { user } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const handleMakePayment = async () => {
    if (!amount || !client) {
      dispatch(showAlertAction("Please fill in the input fields correctly"));
      return;
    }

    await dispatch(
      makeFieldCollection({
        amount,
        payment_method: paymentMethod,
        client_id: client,
        description,
        collector_id: user?.id,
      })
    );

    toggleModal();
    setClient();
    setAmount("");
    setDescription("");
  };

  const toggleModal = () => {
    setIsOpen((open) => !open);
  };

  return (
    <>
      <div className="badge badge-outline badge-pill pointer" onClick={toggleModal} role="button">
        New Field Collection
      </div>

      <Modal
        visible={isOpen}
        onCancel={() => toggleModal()}
        title="Add a New Field Collection"
        okText="Submit"
        onOk={handleMakePayment}
        okButtonProps={{ className: "ant-modal-action-btn" }}
      >
        <ClientSelectField onSelect={setClient} defaultQueryParam={`${getOrgDashboardIdField(orgType)}=${orgId}`} />

        <div className="d-flex gap-2">
          <Input
            placeholder="Amount"
            className="form-control mb-2"
            value={amount}
            type="number"
            onChange={(e) => setAmount(e.target.value)}
          />

          <Select
            className="default-ant-select default-ant-select-40"
            placeholder="Select Payment Method"
            value={paymentMethod}
            onChange={setPaymentMethod}
          >
            <Select.Option value={"cash"}>Cash</Select.Option>
            <Select.Option value={"transfer"}>Transfer</Select.Option>
          </Select>
        </div>

        <Input.TextArea
          placeholder="Notes"
          className="form-control mb-2"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Modal>
    </>
  );
};
