import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { getStaffsWithParams } from "store/clients";
import { getOrgDashboardIdField } from "utils";
import { positionToOrgMapping } from "utils";

const useDashboardStaffList = () => {
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const dispatch = useDispatch();
  const { type, orgId } = useParams();
  const [listRefreshTrigger, setListRefreshTrigger] = useState(false);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false)
  // useful for loan request form
  const orgType = type || positionToOrgMapping[user.position];

  const handleListRefreshTrigger = () =>
    setListRefreshTrigger((current) => !current);

  useEffect(async () => {
    let queryString = "";
    setLoading(true)
    if (orgType) {
      const field_id = getOrgDashboardIdField(orgType);
      queryString = queryString.concat(`${field_id}=${orgId}`);
    }

    if (user?.position === "CO") {
      setCos([user]);
    } else {
      const res = await dispatch(getStaffsWithParams(queryString, ""));
      if (res) {
        setListData(res);
      }
    }
    setLoading(false)
  }, [listRefreshTrigger]);

  return {
    listData: listData,
    type: type,
    id: orgId,
    handleListRefreshTrigger,
    loading,
  };
};

export { useDashboardStaffList };
