import { Form, Input, Select } from "antd";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addExpenseAction, getAllSectorData } from "store/org";
import { INCOME_ORG_TYPE } from "views";
import {
  getOrgStaffProfileOrgField,
  getOrgDashboardIdField,
  staffCreationPositionMapping,
} from "../utils";

export const expenseCategories = [
  "Field allowance",
  "Transport",
  "Stationaries",
  "Utility",
  "General training allowance",
  "Monitoring team expenses",
  "New orientee",
  "Printing press",
  "Others",
];

const ExpenseForm = () => {
  const [loading, setLoading] = useState(false);
  const [relatedOrgOption, setRelatedOrgOption] = useState([]);
  const [orgType, setOrgType] = useState("");
  const [selectFieldName, setSelectFieldName] = useState("");

  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(addExpenseAction({ ...values, added_by_id: user.id }));
    form.resetFields();
    setLoading(false);
  };

  const handleFetchOrgsData = async (type) => {
    setOrgType(type);
    setLoading(true);
    const userOrgId = user[getOrgStaffProfileOrgField(user.position)]?.id;
    const userIdField = getOrgDashboardIdField(user.position);
    setSelectFieldName(getOrgDashboardIdField(type));

    if (type === staffCreationPositionMapping[user.position]) {
      setRelatedOrgOption([user[getOrgStaffProfileOrgField(user.position)]]);
      setLoading(false);
      return;
    }

    const queryString = `${userIdField}=${userOrgId}`;
    const res = await dispatch(getAllSectorData(type, queryString));
    setRelatedOrgOption(res);
    setLoading(false);
  };

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Add Expense</h3>
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Select Org Level"
              rules={[
                {
                  required: true,
                  message: "Please select a CATEGORY",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select credit officer"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onSelect={(val) => handleFetchOrgsData(val)}
              >
                {INCOME_ORG_TYPE.map(
                  ({ id, label, permissionLevel }) =>
                    user?.permissionLevel >= permissionLevel && (
                      <Select.Option value={id} key={id}>
                        {label}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>

          {!!relatedOrgOption?.length && (
            <Col md="6">
              <Form.Item
                name={selectFieldName}
                rules={[
                  {
                    required: true,
                    message: "Please an an option",
                  },
                ]}
                label={`${orgType}`}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relatedOrgOption.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col md="6">
            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please an an option",
                },
              ]}
              label="Select category"
            >
              <Select
                showSearch
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {expenseCategories.map((val, index) => (
                  <Select.Option value={val} key={index}>
                    {val}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item name="description" label="Description">
              <Input
                placeholder="Description"
                type="text"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Please provide amount",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { ExpenseForm };
