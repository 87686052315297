import { Form, Input, Select } from "antd";
import { user } from "db";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrgDashboardIdField } from "utils";
import { positionToOrgMapping } from "utils";
import { addNewUnion, getAllCos, getStaffsWithParams } from "../store/clients";

const RegisterUnion = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [cos, setCos] = useState([]);
  const [loading, setLoading] = useState(false)
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    setLoading(true)
    if (user?.position === 'CO') {
        setCos([user])
        setLoading(false)
        return
    }
    const orgType = positionToOrgMapping[user.position]
    const field_id = getOrgDashboardIdField(orgType)
    let queryString = ''
    queryString = queryString.concat(`${field_id}=${user?.[orgType]?.id}`)

    if (user?.position === "CO") {
      setCos([user]);
    } else {
      dispatch(getStaffsWithParams(queryString)).then((result) => {
        setCos(result);
      });
    }
    setLoading(false)
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true)
    dispatch(addNewUnion(values));
    form.resetFields();
    setLoading(false)
  };

  return (
    <>
      <Container fluid>
        <h3 className="page-title mb-4">Register Union</h3>
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col className="" md="6">
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please provide a name",
                  },
                ]}
              >
                <Input
                  placeholder="Name"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>

            <Col className="" md="6">
              <Form.Item
                label="Select CO"
                name="co_id"
                rules={[
                  {
                    required: true,
                    message: "Please select a credit officer",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select credit officer"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {cos?.map((inst) => (
                    <Select.Option value={inst.id} key={inst.id}>
                      {inst.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button variant="primary" className="px-5" type="submit" disabled={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export { RegisterUnion };
