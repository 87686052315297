import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { clientSearchAction } from "store/clients";
import debounce from "lodash.debounce";

export const ClientSelectField = ({
  onSelect,
  defaultQueryParam = "",
  ...others
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [clientsOptions, setClientOptions] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);

  const dispatch = useDispatch();

  const fetchClients = useCallback(
    debounce(async (query) => {
      if (!query) {
        return;
      }

      setLoadingClients(true);
      let queryString = defaultQueryParam ? `${defaultQueryParam}&search_name=${query}` : `&search_name=${query}`;

      dispatch(clientSearchAction(queryString)).then((res) => {
        setClientOptions(res?.results);
        setLoadingClients(false);
      });
    }, 300),
    []
  );

  useEffect(() => {
    fetchClients(searchTerm);
    // Cancel debounce on cleanup
    return () => fetchClients.cancel();
  }, [searchTerm, fetchClients]);

  return (
    <Select
      showSearch={true}
      searchValue={searchTerm}
      onSearch={setSearchTerm}
      placeholder="Search and select Client"
      optionFilterProp="children"
      className="custom-input mb-2"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear
      onSelect={(val) => onSelect?.(val)}
      loading={loadingClients}
      {...others}
    >
      {clientsOptions?.map(({ id, firstname, surname }) => (
        <Select.Option value={id} key={id}>
          {`${surname} ${firstname}`}
        </Select.Option>
      ))}
    </Select>
  );
};
