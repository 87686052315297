import { DatePicker, Input, Select } from "antd";
import { useFilterDateDropdown } from "hooks/useFilterDateDropdown";
import { useListTitle } from "hooks/useListTitle";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { NumberParam, useQueryParam } from "use-query-params";
import { getOrgDashboardIdField } from "utils";
import { getOrgStaffProfileOrgField } from "utils";
import { getSummationOfFields } from "utils";
import { ModifiedAntTable } from "./ModifiedAntTable";
import { remittanceToBmAction } from "store/org";
import { remittanceDeleteAction } from "store/org";

const RemittanceToBmList = ({}) => {
  const [loading, setLoading] = useState(false);
  const [summedData, setSummedData] = useState({});
  const [remittance, setRemittance] = useState([]);
  const [filteredRemittance, setFilteredRemittance] = useState([]);
  const [filterCoName, setFilterCoName] = useState();
  const [filterBmName, setFilterBmName] = useState();
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [filterDate, setFilterDate] = useState();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const [page, setPage] = useQueryParam("page", NumberParam);
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const { renderFilter } = useFilterDateDropdown({ setFilterDate });
  const { fullListName } = useListTitle("remittance to BM");

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredRemittance,
        fieldsToSum: ["amount"],
      })
    );
  }, [filteredRemittance]);

  useEffect(() => {
    setFilteredRemittance(() => {
      return remittance?.filter((val) => {
        const conditions =
          val?.co_name?.toLowerCase()?.includes(filterCoName?.toLowerCase() || '') &&
          val?.bm_name?.toLowerCase()?.includes(filterBmName?.toLowerCase() || '')

        return conditions;
      });
    });
  }, [remittance, filterBmName, filterCoName])

  useEffect(() => {
    const userProfileOrg = getOrgStaffProfileOrgField(user.position);

    const orgIdField = getOrgDashboardIdField(type || user.position);
    let org_id = id || user[userProfileOrg]?.id;

    if (user.position === "CO" && !id) {
      org_id = user.id;
    }
    let queryString = `${orgIdField}=${org_id}`;

    if (filterDate) {
      queryString = queryString.concat(
        `&start_date=${filterDate[0].format(
          "YYYY/MM/DD"
        )}&end_date=${filterDate[1].format("YYYY/MM/DD")}`
      );
    }

    setLoading(true);
    dispatch(remittanceToBmAction("GET", undefined, queryString))
      .then((result) => {
        setRemittance(result);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [filterDate, refreshTrigger]);

  const handleDelete = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this deposit from record?."
      )
    ) {
      await dispatch(remittanceDeleteAction(id));
      setRefreshTrigger((current) => !current);
    }
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summation",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString()}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "CO",
      dataIndex: "co_name",
    },
    {
      title: "BM",
      dataIndex: "bm_name",
      summary: "-",
    },
    {
      title: "Description",
      render: (text, record) => <div>{record?.description}</div>,
      summary: "-",
    },
  ];

  if (["DR", "SM", "ZM"].includes(user?.position)) {
    tableColumn.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <span role="button" onClick={() => handleDelete(record?.id)}>
          <i className="fas fa-trash"></i>
        </span>
      ),
      summary: "-",
    });
  }

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>
      <Input.Group compact className="mb-3 filter-panel">
        <Input
          style={{ width: "50%" }}
          placeholder="Filter by CO name"
          value={filterCoName}
          onChange={(e) => setFilterCoName(e.target.value)}
        />
        <Input
          style={{ width: "50%" }}
          placeholder="Filter by BM name"
          value={filterBmName}
          onChange={(e) => setFilterBmName(e.target.value)}
        />
      </Input.Group>

      <Row>
      <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val)}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredRemittance}
        showSummary={true}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { RemittanceToBmList };
