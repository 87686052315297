import React, { useEffect, useState } from "react";
import { Select, Form, useForm, Input } from "antd";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import LoginImg from "assets/img/login.png";
import { useDispatch } from "react-redux";
import { handleLoginAction } from "store/users";

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isProd = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION';
  const dispatch = useDispatch();

  const handleFinish = async (values) => {
    setLoading(true);
    await dispatch(handleLoginAction(values));
    setLoading(false);
  };

  return (
    <Container fluid className="login-page-wrap">
      <Row>
        <Col md="6" className="login-page-form-wrap">
          <Card className="p-4 border-none">
            <Card.Title className="mb-3">
              <h3 className="page-title mb-2">Welcome back</h3>
              Please provide your details!.
            </Card.Title>
            <Form form={form} onFinish={handleFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please provide email",
                  },
                ]}
              >
                <Input
                  className="form-control"
                  type="email"
                  placeholder="Enter email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please provide password",
                  },
                ]}
              >
                <Input
                  className="form-control"
                  type="password"
                  placeholder="Enter password"
                />
              </Form.Item>

              <Row className="mt-3">
                <Col>
                  <Button
                    variant="primary"
                    className="px-5 w-100"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

            {!isProd && (
              <div className="p-2 mt-3 warning-item d-flex align-items-center">
                <i className="fas fa-info-circle warning_event mr-2"></i>

                <p className="mb-0">Test environment</p>
              </div>
            )}
          </Card>
        </Col>
        <Col md="6" className="d-none d-md-flex login-page-img-wrap">
          <img src={LoginImg} alt="moon" />
        </Col>
      </Row>
    </Container>
  );
};

export { Login };
