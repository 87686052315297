import { List, Popconfirm, Popover, Select } from "antd";
import classNames from "classnames";
import { ModifiedAntTable } from "components/ModifiedAntTable";
import { SalaryPaymentModal } from "components/Sidebar/StaffProfileModals";
import { StaffPromotionModal } from "components/Sidebar/StaffProfileModals";
import { TransferStaff } from "components/Sidebar/StaffProfileModals";
import { NewQueryModal } from "components/Sidebar/StaffProfileModals";
import { RenderAddAward } from "components/Sidebar/StaffProfileModals";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { staffQueriesAction } from "store/users";
import { staffAwardsAction } from "store/users";
import { staffEmploymentStatusAction } from "store/users";
import { staffPromotionAction } from "store/users";
import { staffTransfersAction } from "store/users";
import { staffSalaryAction } from "store/users";
import { getUserAction } from "store/users";
import { getOrgStaffProfileOrgField } from "utils";
import { positionToOrgMapping2 } from "utils";
import { getSummationOfFields } from "utils";

const StaffProfile = () => {
  const { id } = useParams();
  const {
    users: { user },
  } = useSelector((state) => ({
    org: state.org,
    users: state.users,
  }));

  const [userData, setUserData] = useState();
  const [showStatusChange, setShowStatusChange] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showPromotion, setShowPromotion] = useState(false);
  const [showAddAward, setShowAddAward] = useState(false);
  const [showQuery, setShowQuery] = useState(false);
  const [showMakePayment, setShowMakePayment] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [shouldAllowAction, setShouldAllowAction] = useState(false);
  const [summedData, setSummedData] = useState({});
  const dispatch = useDispatch();

  const orgType = positionToOrgMapping2[userData?.position];
  const orgIdField = getOrgStaffProfileOrgField(userData?.position);
  const orgFieldId =
    userData?.position === "CO" ? userData?.id : userData?.[orgIdField]?.id;

  const getAllowActions = () => {
    if (user.position === "DR") return true;

    // An area manager at minimum editing for lower staffs
    return (
      user.permissionLevel > userData?.permissionLevel &&
      user.permissionLevel >= 3
    );
  };

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: userData?.user_salary_payment,
        fieldsToSum: [
          "gross_pay",
          "tax_amount",
          "cash_security",
          "other_deductions",
          "net_pay",
        ],
      })
    );
  }, [userData?.user_salary_payment]);

  useEffect(() => {
    setShouldAllowAction(() => getAllowActions());
  }, [userData]);

  useEffect(async () => {
    dispatch(getUserAction(id)).then((res) => {
      if (res) {
        setUserData({ ...res });
      }
    });
  }, [triggerRefresh]);

  const handleStatusChange = (status, pk, isDelete) => {
    dispatch(
      !isDelete
        ? staffEmploymentStatusAction({
            status,
            user: userData?.id,
            previous_status: userData?.status || "active",
          })
        : staffEmploymentStatusAction(undefined, pk, "DELETE")
    ).then(() => {
      setTriggerRefresh((current) => !current);
      setShowStatusChange(false);
    });
  };

  const handleTransfer = (orgData = {}, pk, isDelete) => {
    dispatch(
      !isDelete
        ? staffTransfersAction({ ...orgData, user: userData?.id })
        : staffTransfersAction(undefined, pk, "DELETE")
    ).then(() => {
      setTriggerRefresh((current) => !current);
      setShowTransfer(false);
    });
  };

  const handlePromotion = (data) => {
    dispatch(
      staffPromotionAction({
        ...data,
        user: userData?.id,
        previous_position: userData?.position,
      })
    ).then(() => {
      setTriggerRefresh((current) => !current);
    });
  };

  const handleAddAward = (data, pk, isDelete) => {
    dispatch(
      !isDelete
        ? staffAwardsAction({ ...data, user: userData?.id })
        : staffAwardsAction(undefined, pk, "DELETE")
    ).then(() => {
      setTriggerRefresh((current) => !current);
    });
  };

  const handleAddQuery = (data, pk, isDelete) => {
    dispatch(
      !isDelete
        ? staffQueriesAction({ ...data, user: userData?.id })
        : staffQueriesAction(undefined, pk, "DELETE")
    ).then(() => {
      setTriggerRefresh((current) => !current);
    });
  };

  const handleMakePayment = async (data, pk, isDelete) => {
    dispatch(
      !isDelete
        ? staffSalaryAction({ ...data, user: userData?.id })
        : staffSalaryAction(undefined, pk, "DELETE")
    ).then(() => {
      setTriggerRefresh((current) => !current);
    });
  };

  const salaryTableColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) => index + 1,
      summary: "-",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (_, data) => moment(data?.date).format("LL"),
      summary: "-",
    },
    {
      title: "Gross (₦)",
      dataIndex: "gross",
      render: (_, data) => Number(data?.gross_pay || 0).toLocaleString(),
      summary: summedData?.["gross_pay"],
    },
    {
      title: "Tax (₦)",
      dataIndex: "tax",
      render: (_, data) => Number(data?.tax_amount || 0).toLocaleString(),
      summary: summedData?.["tax_amount"],
    },
    {
      title: "Cash Security (₦)",
      dataIndex: "cash_security",
      render: (_, data) => Number(data?.cash_security || 0).toLocaleString(),
      summary: summedData?.["cash_security"],
    },
    {
      title: "Other Deductions (₦)",
      dataIndex: "other_deductions",
      render: (_, data) => Number(data?.other_deductions || 0).toLocaleString(),
      summary: summedData?.["other_deductions"],
    },
    {
      title: "Net Pay (₦)",
      dataIndex: "net_pay",
      render: (_, data) => Number(data?.net_pay || 0).toLocaleString(),
      summary: summedData?.["net_pay"],
    },
  ];

  if (shouldAllowAction) {
    salaryTableColumns.push({
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => handleMakePayment(undefined, record?.id, true)}
          placement="left"
          icon={null}
          okText="Yes"
          cancelText="No"
        >
          <i className="fas fa-trash"></i>
        </Popconfirm>
      ),
      summary: "-",
    });
  }

  const RenderSelectStatus = ({}) => (
    <div>
      <Select
        showSearch={false}
        placeholder="New Status"
        optionFilterProp="children"
        className="custom-input mb-2"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSelect={(value) => handleStatusChange(value)}
      >
        <Select.Option
          value="active"
          key="active"
          disabled={userData?.status === "active"}
        >
          Active
        </Select.Option>
        <Select.Option
          value="suspended"
          key="suspended"
          disabled={userData?.status === "suspended"}
        >
          Suspended
        </Select.Option>
        <Select.Option
          value="terminated"
          key="terminated"
          disabled={userData?.status === "terminated"}
        >
          Terminated
        </Select.Option>
      </Select>
      <small>
        <strong>Active</strong>: User can log into system and perform oparations
      </small>{" "}
      <br />
      <small>
        <strong>Suspended</strong>: User can not log into system or perform
        oparations
      </small>{" "}
      <br />
      <small>
        <strong>Terminated</strong>: User can not log into system or perform
        oparations
      </small>
    </div>
  );

  return (
    <div>
      <Col>
        <Row className="justify-content-between align-items-center">
          <h6 className="mb-3">Staff Profile</h6>

          <strong>
            <Link to={`/org/${orgType}/${orgFieldId}`}>View Dashboard</Link>
          </strong>
        </Row>
      </Col>

      <div>
        <div className="description-item d-flex align-items-center">
          <label className="description-label">Name</label>
          <div className="description-value">{userData?.name}</div>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Position</label>
          <span className="badge badge-pill color-white mr-2 align-items-center ft-12 position-pill">
            {userData?.position}
          </span>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Address</label>
          <div className="description-value">{userData?.address}</div>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Phone Number</label>
          <div className="description-value">{userData?.phone_number}</div>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Email</label>
          <div className="description-value">{userData?.email}</div>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Employment Date</label>
          <div className="description-value">{userData?.employment_date}</div>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Last Login</label>
          <div className="description-value">
            {userData?.last_login
              ? moment(userData?.last_login).format("LLL")
              : "-"}
          </div>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Status</label>
          <div className="description-value">{userData?.status}</div>
        </div>

        <div className="description-item d-flex align-items-center">
          <label className="description-label">Org</label>
          <div className="description-value">
            {userData?.[getOrgStaffProfileOrgField(userData?.position)]?.name}
            {/* {userData?.branch ? `${userData?.branch?.name} branch` : "None"} */}
          </div>
        </div>

        {userData?.position === "CO" && (
          <div className="description-item d-flex align-items-center">
            <label className="description-label">Clients</label>
            <Link
              to={`/clients/cos/${userData?.id}?org=cos&name=${userData?.name}`}
            >
              <strong>Click here</strong>{" "}
              <i className="fas fa-angle-double-right"></i>
            </Link>
          </div>
        )}
      </div>

      <hr className="mt-5 mb-5" />

      <Col className="mb-2">
        <Row className="justify-content-between">
          <h6>Payout</h6>

          {shouldAllowAction && (
            <div role="button" onClick={() => setShowMakePayment(true)}>
              <i className="fa fa-plus"></i> Make Payment
            </div>
          )}
        </Row>
      </Col>

      <ModifiedAntTable
        scroll={{ x: "max-content" }}
        columns={salaryTableColumns}
        dataSource={userData?.user_salary_payment}
        bordered
        className="dashboard_table"
        showSummary={true}
        pagination={{
          defaultPageSize: 3,
          hideOnSinglePage: true,
        }}
      />

      <hr className="mt-5 mb-5" />

      <Col>
        <Row className="justify-content-between">
          <h6>Employment Status</h6>

          {shouldAllowAction && (
            <Popover
              content={<RenderSelectStatus />}
              title="Update Status"
              trigger="click"
              open={showStatusChange}
              onOpenChange={() => setShowStatusChange((current) => !current)}
              className="custom-popover"
              placement="leftTop"
            >
              <div role="button">
                <i className="fas fa-pen"></i> Update
              </div>
            </Popover>
          )}
        </Row>
      </Col>

      <div className="description-item d-flex align-items-center">
        <label className="description-label">Current Status</label>

        <div className="d-flex align-items-center">
          <span className="badge badge-pill color-white mr-2 align-items-center ft-12 position-pill">
            {userData?.status ?? "Active"}
          </span>
        </div>
      </div>

      <h5>History</h5>

      <List
        itemLayout="vertical"
        size="small"
        dataSource={userData?.user_employment_status?.reverse()}
        pagination={{ pageSize: 3, hideOnSinglePage: true }}
        split={false}
        bordered
        renderItem={(item) => (
          <List.Item
            key={item?.id}
            extra={
              shouldAllowAction && (
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() =>
                    handleStatusChange(undefined, item?.id, true)
                  }
                  placement="left"
                  icon={null}
                  okText="Yes"
                  cancelText="No"
                >
                  <i className="fas fa-trash"></i>
                </Popconfirm>
              )
            }
          >
            <li
              className={classNames("list-group-item", {
                "list-group-item-success": item?.to === "Active",
                "list-group-item-danger": item?.to === "Terminated",
                "list-group-item-warning": item?.to === "Suspended",
              })}
            >
              <strong className="mr-3">
                {moment(item?.date).format("LL")}
              </strong>{" "}
              {item?.previous_status} <strong>to</strong> {item?.status}
            </li>
          </List.Item>
        )}
      />

      <hr className="mt-5 mb-5" />

      <Col className="mb-2">
        <Row className="justify-content-between">
          <h6>Awards and Honors</h6>

          {shouldAllowAction && (
            <div role="button" onClick={() => setShowAddAward(true)}>
              <i className="fa fa-plus"></i> Add New
            </div>
          )}
        </Row>
      </Col>

      <List
        itemLayout="horizontal"
        size="small"
        dataSource={userData?.user_awards?.reverse()}
        pagination={{ pageSize: 3, hideOnSinglePage: true }}
        bordered
        renderItem={(item) => (
          <List.Item
            key={item?.id}
            extra={
              shouldAllowAction && (
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => handleAddAward(undefined, item?.id, true)}
                  placement="left"
                  icon={null}
                  okText="Yes"
                  cancelText="No"
                >
                  <i className="fas fa-trash"></i>
                </Popconfirm>
              )
            }
          >
            <List.Item.Meta title={item.title} description={item.description} />
            <div>{item.date}</div>
          </List.Item>
        )}
      />

      <hr className="mt-5 mb-5" />

      <Col className="mb-2">
        <Row className="justify-content-between">
          <h6>Promotion</h6>

          {shouldAllowAction && (
            <div role="button" onClick={() => setShowPromotion(true)}>
              <i className="fa fa-plus"></i> New Promtion
            </div>
          )}
        </Row>
      </Col>

      <List
        itemLayout="horizontal"
        size="small"
        dataSource={[...(userData?.user_promotions || [])].reverse()}
        pagination={{ pageSize: 3, hideOnSinglePage: true }}
        bordered
        renderItem={(item) => (
          <List.Item key={item?.id}>
            <List.Item.Meta
              title={
                <span>
                  <code>{item.previous_position}</code> to{" "}
                  <code>{item.new_position}</code>
                </span>
              }
              description={item.reason}
            />
            <div>
              <code>Effective:</code> {moment(item.effective_date).format("LL")}
            </div>
          </List.Item>
        )}
      />

      <hr className="mt-5 mb-5" />

      <Col className="mb-2">
        <Row className="justify-content-between">
          <h6>Transfers</h6>

          {shouldAllowAction && userData?.position !== "DR" && (
            <div
              role="button"
              onClick={() => setShowTransfer((current) => !current)}
            >
              <i className="fas fa-plus"></i> Add new
            </div>
          )}
        </Row>
      </Col>
      {userData?.position === "DR" && (
        <small>Director can not be transferred</small>
      )}

      <List
        itemLayout="horizontal"
        size="small"
        dataSource={userData?.user_transfers?.reverse()}
        pagination={{ pageSize: 3, hideOnSinglePage: true }}
        bordered
        renderItem={(item) => (
          <List.Item
            key={item?.id}
            extra={
              shouldAllowAction && (
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => handleTransfer(undefined, item?.id, true)}
                  placement="left"
                  icon={null}
                  okText="Yes"
                  cancelText="No"
                >
                  <i className="fas fa-trash"></i>
                </Popconfirm>
              )
            }
          >
            <List.Item.Meta title={item.org_name} />
            <div>{moment(item.date).format("LL")}</div>
          </List.Item>
        )}
      />

      <hr className="mt-5 mb-5" />

      <Col className="mb-2">
        <Row className="justify-content-between">
          <h6>Queries</h6>

          {shouldAllowAction && (
            <div role="button" onClick={() => setShowQuery(true)}>
              <i className="fa fa-plus"></i>
              Add new
            </div>
          )}
        </Row>
      </Col>

      <List
        itemLayout="horizontal"
        size="small"
        dataSource={userData?.user_queries?.reverse()}
        pagination={{ pageSize: 3, hideOnSinglePage: true }}
        bordered
        renderItem={(item) => (
          <List.Item
            key={item?.id}
            extra={
              <div>
                <span className="mr-2">{moment(item.date).format("LL")}</span>
                {shouldAllowAction && (
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => handleAddQuery(undefined, item?.id, true)}
                    placement="left"
                    icon={null}
                    okText="Yes"
                    cancelText="No"
                  >
                    <i className="fas fa-trash"></i>
                  </Popconfirm>
                )}
              </div>
            }
          >
            <List.Item.Meta
              title={item.subject}
              description={`${
                String(item.content).length > 100
                  ? String(item.content).substring(0, 100) + "..."
                  : String(item.content)
              }`}
            />
            <div role="button" className="mr-2">
              Read more
            </div>
          </List.Item>
        )}
      />

      <RenderAddAward
        isOpen={showAddAward}
        toggleOpen={() => setShowAddAward((current) => !current)}
        onSubmit={handleAddAward}
      />

      <NewQueryModal
        isOpen={showQuery}
        toggleOpen={() => setShowQuery((current) => !current)}
        onSubmit={handleAddQuery}
      />

      <SalaryPaymentModal
        isOpen={showMakePayment}
        toggleOpen={() => setShowMakePayment((current) => !current)}
        onSubmit={handleMakePayment}
      />

      <StaffPromotionModal
        isOpen={showPromotion}
        toggleOpen={() => setShowPromotion((current) => !current)}
        onSubmit={handlePromotion}
        currentPosition={userData?.position}
        currentUser={user}
      />

      {showTransfer && (
        <TransferStaff
          isOpen={showTransfer}
          userPosition={userData?.position}
          handleTransfer={handleTransfer}
          toggleOpen={() => setShowTransfer((current) => !current)}
        />
      )}
    </div>
  );
};

export { StaffProfile };
