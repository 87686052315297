import { Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { closeLoanAction } from "store/loans";

const { useState, useEffect } = require("react");
const { NumericObjectParam } = require("use-query-params");

export const ClosableLoanNotice = ({ loan, onCloseLoan }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isClosable, setIsClosable] = useState(false);

  const loanIsClosable = () => {
    if (!loan || !loan?.client || !loan?.daily_payment_data) {
      return
    };

    const {
      client: { savings_balance, client_waiver_balance },
      daily_payment_data,
    } = loan;
    const installmentsPaid = daily_payment_data?.reduce((acc, obj) => {
      if (obj.paid) {
        return acc + Number(obj.amount);
      } else {
        return acc;
      }
    }, 0);

    const payableAmount =
      installmentsPaid + Number(savings_balance) + Number(client_waiver_balance);

    return Number(loan?.net_pay) <= Number(payableAmount);
  };

  const handleLoanClose = () => {
    setLoading(true);
    dispatch(closeLoanAction(loan?.id)).then(() => {
      setLoading(false);
      onCloseLoan?.()
    });
  };

  useEffect(() => {
    setIsClosable(() => loanIsClosable());
  }, [loan]);

  if (!isClosable || !loan || loan?.loan_status === 'COMPLETED' || loan?.loan_status === 'WAIVED') {
    return null;
  }

  return (
    <div className="loan-alert-info">
      <span>The client has enough balance to close loan</span>{" "}
      <Popconfirm
        title="Are you sure you want to close this loan?."
        onConfirm={handleLoanClose}
        placement="top"
        icon={null}
        okText="Yes"
        cancelText="No"
        className="btn-link"
        disabled={loading}
      >
        click to close loan
      </Popconfirm>
    </div>
  );
};
