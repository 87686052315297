import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllunions } from "store/clients";
import { getOrgDashboardIdField } from "utils";
import { getOrgStaffProfileOrgField } from "utils";
import { useQueryParam, NumberParam } from "use-query-params";
import { ModifiedAntTable } from "components/ModifiedAntTable";

const ViewUnion = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [unions, setUnions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useQueryParam("page", NumberParam);

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
    },
    {
      title: "Name",
      dateIndex: "name",
      render: (text, record, index) => (
        <Link to={`/union-viewall/${record.id}`}>{record.name}</Link>
      ),
    },
    {
      title: "Credit Officer",
      dateIndex: "co_id",
      render: (text, record, index) => (
        <Link to={`/union-viewall/${record.id}`}>{record.co}</Link>
      ),
    },
    {
      title: "Branch",
      dateIndex: "branch",
      render: (text, record, index) => (
        <Link to={`/union-viewall/${record.id}`}>{record.branch}</Link>
      ),
    },
    {
      title: "Area",
      dateIndex: "area",
      render: (text, record, index) => (
        <Link to={`/union-viewall/${record.id}`}>{record.area}</Link>
      ),
    },
    {
      title: "Zone",
      dateIndex: "zone",
      render: (text, record, index) => (
        <Link to={`/union-viewall/${record.id}`}>{record.zone}</Link>
      ),
    },
    {
      title: "State",
      dateIndex: "state",
      render: (text, record, index) => (
        <Link to={`/union-viewall/${record.id}`}>{record.state}</Link>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    const orgType = getOrgStaffProfileOrgField(user?.position);
    const orgIdField = getOrgDashboardIdField(user?.position);
    const orgFieldId = user?.position === "CO" ? user?.id : user?.[orgType]?.id;

    let queryString = "";
    queryString = queryString.concat(`${orgIdField}=${orgFieldId}`);

    dispatch(getAllunions(queryString)).then((result) => {
      setUnions(result);
    });
    setLoading(false);
  }, []);

  return (
    <div>
      <h3 className="page-title mb-4">Unions</h3>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={unions}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { ViewUnion };
