import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getStaffsWithParams } from "store/clients";
import { remittanceToBmAction } from "store/org";
import { getOrgDashboardIdField } from "utils";
import { getOrgStaffProfileOrgField } from "utils";

const RemittanceToBmForm = ({}) => {
  const [loading, setLoading] = useState();
  const [cos, setCos] = useState();
  const [bms, setBms] = useState();
  const [form] = Form.useForm();

  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(
      remittanceToBmAction("POST", {
        ...values,
        branch_id: user?.branch?.id,
        state_id: user?.state?.id,
        area_id: user?.area?.id,
        zone_id: user?.zone?.id,
      })
    );
    form.resetFields();
    setLoading(false);
  };

  const handleFetchCOs = async () => {
    setLoading(true);
    const org_id_field =
      user?.position === "CO"
        ? "branch_id"
        : getOrgDashboardIdField(user?.position);
    const org = getOrgStaffProfileOrgField(user.position);
    const org_id = user[org]?.id;
    let queryString = `${org_id_field}=${org_id}`;

    if (user?.position === "CO") {
      setCos([user]);
    } else {
      dispatch(getStaffsWithParams(queryString)).then((result) => {
        setCos(result);
      });
    }

    if (user?.position === "BM") {
      setBms([user]);
    } else {
      dispatch(getStaffsWithParams(queryString, "BM")).then((result) => {
        setBms(result);
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchCOs();
  }, []);

  return (
    <div>
      <h3 className="page-title mb-4">Add remittance to BM</h3>

      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              name="bm_id"
              label="Select BM"
              rules={[
                {
                  required: true,
                  message: "Please select a BM",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select branch manager"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                loading={loading}
              >
                {bms?.map(({ id, name }) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Select CO"
              name="co_id"
              rules={[
                {
                  required: true,
                  message: "Please select a CO",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select credit officer"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                loading={loading}
              >
                {cos?.map(({ id, name }) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount remitted"
              rules={[
                {
                  required: true,
                  message: "Please provide amount deplosited",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please provide a description for this deposit",
                },
              ]}
            >
              <Input
                placeholder="Description"
                type="text"
                className="form-control"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export { RemittanceToBmForm };
