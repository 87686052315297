import { createSlice } from "@reduxjs/toolkit";
import { getOrgDashboardIdField } from "utils";
import { getErrorMsg, makeRequest, hashToken } from "utils";
import { BASE_API_URL } from "../db";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "users",
  initialState: {
    messages: [],
  },
  reducers: {
    setAllMessages: (state, action) => {
      state.messages = action.payload;
    },
    addNewMessage: (state, action) => {
      state.messages = [action.payload, ...state.messages]
    },
  },
});

export default slice.reducer;

const { addNewMessage, setAllMessages } = slice.actions;

export const handleGettAllMessages = (queryString) => async (dispatch) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/messages/?${queryString}`,
      "GET"
    );

    dispatch(setAllMessages(res));
  } catch (error) {
    dispatch(
      showAlertAction({
        message: "Unable to get messages your messages",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const sendMessagesAction = (data) => async (dispatch) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/messages/`,
      "POST",
      data
    );

    dispatch(addNewMessage(res));
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const readLastNotification = () => async (dispatch) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/messages/last_notification_time/`,
      "POST",
      {}
    );

    return true
  } catch (error) {
    return false
  }
};
