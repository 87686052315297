import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bankWithdrawalAction } from "store/org";
import { getAllSectorData } from "store/org";
import { getOrgDashboardIdField } from "utils";
import { getOrgStaffProfileOrgField } from "utils";

const BankWithdrawalForm = ({}) => {
  const [loading, setLoading] = useState();
  const [branches, setBranches] = useState();
  const [transactionType, setTransactionType] = useState();

  const [form] = Form.useForm();

  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const userOrgId = user[getOrgStaffProfileOrgField(user.position)]?.id;
  const userIdField = getOrgDashboardIdField(user.position);

  console.log({ userOrgId, userIdField });

  useEffect(() => {
    const queryString = `${userIdField}=${userOrgId}`;
    dispatch(getAllSectorData("branches", queryString)).then((res) => {
      setBranches(res);
    });
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(bankWithdrawalAction('POST', { ...values, added_by_id: user.id }));
    form.resetFields();
    setLoading(false);
  };

  return (
    <div>
      <h3 className="page-title mb-4">Add bank withdrawal</h3>

      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Select Branch"
              name="branch_id"
              rules={[
                {
                  required: true,
                  message: "Please select a Branch",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select branch"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {branches?.map(({ id, name }) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Withdrawal Amount"
              rules={[
                {
                  required: true,
                  message: "Please provide amount withdrew",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Transaction type"
              name="withdrawal_type"
              rules={[
                {
                  required: true,
                  message: "Please select a type",
                },
              ]}
            >
              <Select
                placeholder="Select type"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(val) => setTransactionType(val)}
                allowClear
              >
                <Select.Option value="transfer" key="Transfer">
                  Transfer
                </Select.Option>
                <Select.Option value="cheque" key="Cheque">
                  Cheque
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {transactionType === "cheque" && (
            <Col className="" md="6">
              <Form.Item
                name="cheque_number"
                label="Cheque Number"
                rules={[
                  {
                    required: true,
                    message: "Please cheque number",
                  },
                ]}
              >
                <Input
                  placeholder="Cheque number"
                  type="text"
                  className="form-control"
                />
              </Form.Item>
            </Col>
          )}

          {transactionType === "transfer" && (
            <>
              <Col className="" md="6">
                <Form.Item
                  name="recipient_bank"
                  label="Recipient bank"
                  rules={[
                    {
                      required: true,
                      message: "Please add recipient bank",
                    },
                  ]}
                >
                  <Input
                    placeholder="Recipient bank"
                    type="text"
                    className="form-control"
                  />
                </Form.Item>
              </Col>

              <Col className="" md="6">
                <Form.Item
                  name="recipient_account_name"
                  label="Recipient account name"
                  rules={[
                    {
                      required: true,
                      message: "Please add recipient account name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Account name"
                    type="text"
                    className="form-control"
                  />
                </Form.Item>
              </Col>

              <Col className="" md="6">
                <Form.Item
                  name="recipient_account_number"
                  label="Recipient account number"
                  rules={[
                    {
                      required: true,
                      message: "Please provide reciopient's account number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Recipient Account number"
                    type="number"
                    className="form-control"
                  />
                </Form.Item>
              </Col>
            </>
          )}

          <Col className="" md="6">
            <Form.Item name="description" label="Description">
              <Input
                placeholder="Description"
                type="text"
                className="form-control"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export { BankWithdrawalForm };
