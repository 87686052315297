import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Select, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { validateLoanRequest } from "store/loans";
import { newLoanRequest } from "store/loans";
import { useDashboardClientList } from "hooks/useDashboardClientList";
import { showAlertAction } from "store/alert";
import { shouldPayLoanWaiverFeeAction } from "store/loans";
import { getOrgStaffProfileOrgField } from "utils";
import { Link } from "react-router-dom";

const { Option } = Select;
const initialLoanPaymentData = {
  interest: undefined,
  daily_pay: undefined,
  net_pay: undefined,
  disbursment_fee: undefined,
};

const LoanRequestForm = () => {
  const [form] = Form.useForm();
  const [shouldPayWaiver, setShouldPayWaiver] = useState(true);
  const dispatch = useDispatch();
  const {
    listData: clientsOption,
    handleGetGuarantors,
    guarantorList: guarantorsOption,
    loading: loadingClients,
  } = useDashboardClientList(true, true);
  const [selectedWD, setSelectedWD] = useState();
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState();
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedGuarantor, setSelectedGuarantor] = useState(null);
  const [formState, setFormState] = useState({});
  const [loanPaymentData, setLoanPaymentData] = useState(
    initialLoanPaymentData
  );
  const [eligibilityData, setEligibilityData] = useState({
    isEligible: false,
    showEligibilityCard: false,
  });
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const { client, interest_rate, loan_duration, loan_amount } = formState;
  const { user } = useSelector((state) => state.users);

  // Even if a client is not eligible to take a loan, a director, state and zonal manager should be able to by pass.
  const shouldByPassEligibility =
    ["DR", "SM", "ZM"].includes(user.position) &&
    eligibilityData?.showEligibilityCard &&
    !eligibilityData?.isEligible;

  const { daily_loan_durations, weekly_loan_durations, replayment_plans } =
    user.position === "DR"
      ? {
          weekly_loan_durations: "8,12,16,24,36",
          daily_loan_durations: "20,24,30,40",
          replayment_plans: "weekly,daily",
        }
      : user[getOrgStaffProfileOrgField(user.position)];

  const weekly_repayment_durations = weekly_loan_durations
    ? weekly_loan_durations?.split(",")
    : [];
  const daily_repayment_durations = daily_loan_durations
    ? daily_loan_durations.split(",")
    : [];
  const allowed_repayment_plans = replayment_plans
    ? replayment_plans.split(",")
    : [];

  const handleAutoFill = (isClient) => {
    if (isClient && selectedClient) {
      form.setFieldsValue({
        verified_name: `${selectedClient?.surname} ${selectedClient?.firstname}`,
        marital_status: selectedClient?.marital_status,
        house_address: selectedClient?.house_address,
        phone_number1: selectedClient?.phone_number1,
        phone_number2: selectedClient?.phone_number2,
        office_address: selectedClient?.office_address,
      });
      return;
    }

    if (!isClient && selectedGuarantor) {
      form.setFieldsValue({
        guarantor_house_address: selectedGuarantor?.house_address,
        guarantor_office_address: selectedGuarantor?.office_address,
      });
    }
  };

  const handleClientSelect = async (val) => {
    setLoading(true);
    setSelectedClient(() => clientsOption?.find((inst) => inst?.id === val));
    const res = await dispatch(shouldPayLoanWaiverFeeAction(val));

    if (res) {
      setShouldPayWaiver(res?.should_pay_waiver);
    }
    setLoading(false);
  };

  const handleGuarantorSelect = async (val) => {
    setSelectedGuarantor(() =>
      guarantorsOption?.find((inst) => inst?.id === val)
    );
  };

  const checkEligibility = async (data) => {
    setValidating(true);
    const res = await dispatch(validateLoanRequest(data));

    setEligibilityData((current) => ({
      ...current,
      ...res,
      showEligibilityCard: true,
      isEligible: !Object.keys(res).some((key) => res[key]?.status === false),
    }));
    setValidating(false);
  };

  const handleSubmit = async (values) => {
    if (loading || validating) {
      return;
    }
    if (Number(values?.admin_fee) < 100) {
      dispatch(
        showAlertAction({
          message: "Admin fee cannot be less than ₦100",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }
    if (Number(values?.savings_amount) < 200) {
      dispatch(
        showAlertAction({
          message: "Savings amount cannot be less than ₦200",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }
    if (!eligibilityData?.isEligible && !shouldByPassEligibility) {
      // Submit
      return checkEligibility({
        guarantor: values.guarantor,
        client: values.client,
      });
    }
    setLoading(true);
    await dispatch(
      newLoanRequest({
        ...values,
        ...loanPaymentData,
        client_id: values?.client,
        guarantor_id: values?.guarantor,
      })
    );

    form.resetFields();
    setEligibilityData({
      isEligible: false,
      showEligibilityCard: false,
    });
    setLoanPaymentData(initialLoanPaymentData);
    setLoading(false);
  };

  const updateFormState = () => setFormState(form.getFieldsValue());

  const getPaymentData = () => {
    const interest = parseInt(
      Number(loan_amount) * (Number(interest_rate) / 100)
    );
    const net_pay = parseInt(Number(interest) + Number(loan_amount));
    const daily_pay = parseInt(Number(net_pay) / Number(loan_duration));
    const disbursment_fee = parseInt(Number(loan_amount) * 0.05);

    return {
      interest,
      net_pay,
      daily_pay,
      disbursment_fee,
    };
  };

  useEffect(() => {
    if (
      interest_rate &&
      loan_duration &&
      loan_amount &&
      Number(loan_amount) > 0
    ) {
      setLoanPaymentData((current) => ({
        ...current,
        ...getPaymentData(),
      }));
    }
  }, [formState]);

  useEffect(() => {
    handleGetGuarantors();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      interest_rate: !!selectedWD ? 20 : undefined,
    });
  }, [selectedWD]);

  useEffect(() => {
    handleAutoFill(true);
  }, [selectedClient]);

  useEffect(() => {
    handleAutoFill(false);
  }, [selectedGuarantor]);

  /**
   * Loan Collection conditions
   * 1. Nin has no outstanding loan
   * 2. Guarantor has no outstanding loan
   * 3. Client has guaranteed an unfinished loan
   */

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Request new loan</h3>

      <Form
        form={form}
        onFinish={(values) => handleSubmit(values)}
        onFieldsChange={updateFormState}
      >
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Client"
              name="client"
              rules={[
                {
                  required: true,
                  message:
                    "Please select a client, or create a new client first",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select Client"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onSelect={handleClientSelect}
                onClear={() => setShouldPayWaiver(true)}
                loading={loadingClients}
              >
                {clientsOption?.map(({ id, firstname, surname }) => (
                  <Option value={id} key={id}>
                    {`${surname} ${firstname}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Guarantor"
              name="guarantor"
              rules={[
                {
                  required: true,
                  message: "Please select a guarantor or create one",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select Guarantor"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                disabled={!client}
                onSelect={handleGuarantorSelect}
              >
                {guarantorsOption?.map(({ id, firstname, surname }) => (
                  <Option value={id} key={id}>
                    {`${surname} ${firstname}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Repayment Plan"
              name="repayment_plan"
              rules={[
                {
                  required: true,
                  message: "Please select loan duration",
                },
              ]}
            >
              <Select
                placeholder="Repayment Plan"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onChange={(val) => setSelectedPaymentPlan(val)}
              >
                {allowed_repayment_plans?.map((plan) => (
                  <Select.Option value={plan} key={plan}>
                    {plan}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              label="Loan Duration"
              name="loan_duration"
              rules={[
                {
                  required: true,
                  message: "Please select loan duration",
                },
              ]}
            >
              <Select
                placeholder="Loan Duration"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onChange={(val) => setSelectedWD(val)}
                disabled={!selectedPaymentPlan}
              >
                {selectedPaymentPlan === "daily"
                  ? daily_repayment_durations?.map((inst) => (
                      <Select.Option value={inst} key={inst}>
                        {`${inst} Working Days`}
                      </Select.Option>
                    ))
                  : weekly_repayment_durations?.map((inst) => (
                      <Select.Option value={inst} key={inst}>
                        {`${inst} Weeks`}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="" md="6">
            <Form.Item
              label="Interest Rate"
              name="interest_rate"
              rules={[
                {
                  required: true,
                  message: "Please select an interest rate",
                },
              ]}
            >
              <Select
                placeholder="Select Interest Rate"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled
                allowClear
              >
                <Select.Option value={10} key={10}>
                  10%
                </Select.Option>
                <Select.Option value={20} key={20}>
                  20%
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Item
              label="Loan Amount"
              name="loan_amount"
              rules={[
                {
                  required: true,
                  min: 0,
                  message: "Please provide loan amount",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Loan Amount"
                prefix="₦"
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              label="Savings Amount"
              name="savings_amount"
              rules={[
                {
                  required: true,
                  message: "Please provide savings amount",
                },
                {
                  validator: async (_, value) => {
                    if (value < 200) {
                      return Promise.reject(
                        "Savings amount must be at least 200"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Savings Amount (min ₦200)"
              />
            </Form.Item>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md="4">
            <Form.Item
              label="Verified name"
              name="verified_name"
              rules={[
                {
                  required: true,
                  message: "Please provide a verified Name from Id card",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Verified Name"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Marital status"
              name="marital_status"
              rules={[
                {
                  required: true,
                  message: "Please provide current marital status",
                },
              ]}
            >
              <Select
                placeholder="Current Marital Status"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
              >
                <Select.Option value="married" key="married">
                  Married
                </Select.Option>
                <Select.Option value="single" key="single">
                  Single
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="House address"
              name="house_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current homes address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Current House address"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <Form.Item
              label="Office address"
              name="office_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current office address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Current Office address"
              />
            </Form.Item>
          </Col>
          <Col md="4">
            <Form.Item
              label="Phone number 1"
              name="phone_number1"
              rules={[
                {
                  required: true,
                  message: "Please provide current phone number",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Current Phone Number 1"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item label="Phone Number 2" name="phone_number2">
              <Input
                className="form-control"
                type="number"
                placeholder="Current Phone number 2"
              />
            </Form.Item>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md="4">
            <Form.Item
              label="Union fee"
              name="union_fee"
              rules={[
                {
                  required: true,
                  message: "Please provide a union fee",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Union Fee"
                prefix="₦"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Admin fee"
              name="admin_fee"
              rules={[
                {
                  required: true,
                  message: "Please provide admin fee",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Admin Fee"
                prefix="₦"
              />
            </Form.Item>
          </Col>

          {shouldPayWaiver && (
            <Col md="4">
              <Form.Item
                label="Waiver fee"
                name="waiver_fee"
                rules={[
                  {
                    required: true,
                    message:
                      "Selected client, needs to pay waiver fee to proceed.",
                  },
                ]}
              >
                <Input
                  className="form-control"
                  type="number"
                  placeholder="Waiver Fee"
                  prefix="₦"
                />
              </Form.Item>
            </Col>
          )}

          <Col md="4">
            <Form.Item
              label="Guarantor house address"
              name="guarantor_house_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current guarantor house address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Guarantor house address"
              />
            </Form.Item>
          </Col>

          <Col md="4">
            <Form.Item
              label="Guarantor office address"
              name="guarantor_office_address"
              rules={[
                {
                  required: true,
                  message: "Please provide current guarantor office address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Guarantor office address"
              />
            </Form.Item>
          </Col>
        </Row>

        <hr />
        <small>Auto generated fields</small>
        <hr />

        <Row>
          <Col className="" md="4">
            <span>Loan Amount</span>
            <div className="form-control ant-input-disabled">
              ₦ {loan_amount ? Number(loan_amount)?.toLocaleString() : ""}
            </div>
          </Col>
          <Col className="" md="4">
            <span>Net Pay</span>
            <Form.Item style={{ display: "none" }}>
              <Input
                value={loanPaymentData.net_pay}
                className="form-control"
                type="number"
                disabled
              />
            </Form.Item>
            <div className="form-control ant-input-disabled">
              ₦ {loanPaymentData.net_pay?.toLocaleString()}
            </div>
          </Col>
          <Col className="" md="4">
            <span>Daily Pay</span>
            <Form.Item style={{ display: "none" }}>
              <Input
                value={loanPaymentData.daily_pay}
                className="form-control"
                type="number"
                disabled
              />
            </Form.Item>
            <div className="form-control ant-input-disabled">
              ₦ {loanPaymentData.daily_pay?.toLocaleString()}
            </div>
          </Col>
          <Col className="" md="4">
            <span>Disbursement Fee</span>
            <Form.Item style={{ display: "none" }}>
              <Input
                value={loanPaymentData.disbursment_fee}
                className="form-control"
                type="number"
                disabled
              />
            </Form.Item>
            <div className="form-control ant-input-disabled">
              ₦ {loanPaymentData.disbursment_fee?.toLocaleString()}
            </div>
          </Col>
          <Col className="" md="4">
            <span>Interest</span>
            <Form.Item style={{ display: "none" }}>
              <Input
                value={loanPaymentData.interest}
                className="form-control"
                type="number"
                disabled
              />
            </Form.Item>
            <div className="form-control ant-input-disabled">
              % {loanPaymentData.interest}
            </div>
          </Col>
        </Row>

        {eligibilityData?.showEligibilityCard && (
          <Card className="mt-3 p-2">
            {/* <i className="fas fa-circle-notch fa-spin"> For spinner*/}
            <strong className="text-center profile-label">
              Client Eligibility
            </strong>
            <ul className="eligibility-list p-0">
              <li>
                <strong>Client has no outstanding loan: </strong>{" "}
                {eligibilityData["client_has_no_outstanding"].status ? (
                  <i className="fas fa-check-circle icon-success"></i>
                ) : (
                  <Link
                    to={`/loans/${eligibilityData["client_has_no_outstanding"].loan_id}`}
                    className="icon-danger"
                  >
                    <i className="fas fa-exclamation-triangle icon-danger"></i>{" "}
                    View loan
                  </Link>
                )}
              </li>
              {/* <li>
                <strong>Client have not guranteed an outstanding loan: </strong>{" "}
                {eligibilityData["client_has_not_guaranteed_outstanding"] ? (
                  <i className="fas fa-check-circle icon-success"></i>
                ) : (
                  <i className="fas fa-exclamation-triangle icon-danger"></i>
                )}
              </li> */}
              <li>
                <strong>Guarantor has no outstanding loan: </strong>{" "}
                {eligibilityData["guarantor_has_no_outstanding"].status ? (
                  <i className="fas fa-check-circle icon-success"></i>
                ) : (
                  <Link
                    to={`/loans/${eligibilityData["guarantor_has_no_outstanding"].loan_id}`}
                    className="icon-danger"
                  >
                    <i className="fas fa-exclamation-triangle icon-danger"></i>{" "}
                    View loan
                  </Link>
                )}
              </li>
              <li>
                <strong>
                  Guarantor has not guaranteed an outstanding loan:{" "}
                </strong>{" "}
                {eligibilityData["guarantor_has_not_guaranteed_outstanding"]
                  .status ? (
                  <i className="fas fa-check-circle icon-success"></i>
                ) : (
                  <Link
                    to={`/loans/${eligibilityData["guarantor_has_not_guaranteed_outstanding"].loan_id}`}
                    className="icon-danger"
                  >
                    <i className="fas fa-exclamation-triangle icon-danger"></i>{" "}
                    View loan
                  </Link>
                )}
              </li>
            </ul>
            <small>
              {eligibilityData?.isEligible
                ? "Client is eligible to take loan, you can submit now"
                : "Client is not eligible to take loan."}
            </small>
          </Card>
        )}

        <Row className="mt-3">
          <Button
            size="sm"
            variant="primary"
            className="px-5"
            type="submit"
            disabled={validating || loading}
          >
            {eligibilityData?.isEligible ? (
              "Submit"
            ) : validating ? (
              <span className="text-muted">Validating...</span>
            ) : shouldByPassEligibility ? (
              "Bypass eligibility"
            ) : (
              "Check Eligibility"
            )}
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export { LoanRequestForm };
