import { Drawer, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getStaffsWithParams } from "store/clients";
import { sendMessagesAction } from "store/messages";
import { getAllSectorData } from "store/org";
import { pluraliseOrgLevel } from "utils";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgFieldMeta } from "utils";
import { messagingPositionMapping } from "utils";

const NewMessageForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [destinationType, setDestinationType] = useState("");
  const [userOrgFieldMeta, setUserOrgFieldMeta] = useState();
  const [relatedOrgLevelOption, setRelatedOrgLevelOption] = useState([]);
  const isMobile = window.innerWidth <= 768

  const getRelatedOrgSelectField = async () => {
    setLoading(true);
    const orgType = pluraliseOrgLevel(destinationType?.toLocaleLowerCase());
    let queryString = "";
    queryString = queryString.concat(
      `${userOrgFieldMeta?.idField}=${userOrgFieldMeta?.id}`
    );
    let res;

    if (destinationType === "STAFF") {
      res = await dispatch(getStaffsWithParams(queryString, ""));
      if (res) {
        res = res.filter((staffData) => staffData?.id !== user.id)
      }
    } else {
      res = await dispatch(getAllSectorData(orgType, queryString));
    }
    setRelatedOrgLevelOption(res);
    setLoading(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);


    await dispatch(
      sendMessagesAction({
        content: values.content,
        sender_id: user?.id,
        [destinationType === "STAFF" ? "user_id" : userOrgFieldMeta?.idField]:
          values?.related_level_id,
      })
    );
    setLoading(false);
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    if (destinationType === "STAFF") {
      const destination = getOrgStaffProfileOrgField(user?.position);
      setUserOrgFieldMeta(
        getOrgFieldMeta(user, destination?.toLocaleLowerCase())
      );
      return;
    }
    setUserOrgFieldMeta(
      getOrgFieldMeta(user, destinationType?.toLocaleLowerCase())
    );
  }, [destinationType]);

  useEffect(() => {
    if (!destinationType) {
      return;
    }
    getRelatedOrgSelectField();
  }, [userOrgFieldMeta]);

  return (
    <>
      <div className="button-wrapper">
        <Button
          className="new-message-button"
          onClick={() => setVisible((current) => !current)}
        >
          Send Message <i className="fas fa-solid fa-plus"></i>
        </Button>
      </div>

      <Drawer
        title="Send Message"
        placement="bottom"
        onClose={onClose}
        visible={visible}
        size={isMobile ? "default" : "large"}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col md="6">
              <Form.Item
                name="position"
                label="Select where your message should be broadcasted"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  placeholder="Select message type"
                  optionFilterProp="children"
                  className="custom-input"
                  onChange={(e) => setDestinationType(e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {messagingPositionMapping?.[user.position].map((val) => (
                    <Select.Option value={val} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {!!relatedOrgLevelOption?.length && (
              <Col md="6">
                <Form.Item
                  name="related_level_id"
                  rules={[
                    {
                      required: true,
                      message: "Please an an option",
                    },
                  ]}
                  label={`Select ${
                    destinationType === "STAFF"
                      ? "Staff"
                      : userOrgFieldMeta?.singularName
                  }`}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    className="custom-input"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {relatedOrgLevelOption.map(({ id, name }) => (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="6">
              <Form.Item
                name="content"
                label="Message"
                rules={[
                  {
                    required: true,
                    message: "Please enter message",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button
                variant="primary"
                className="px-5"
                disabled={loading}
                type="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export { NewMessageForm };
