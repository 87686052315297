import { Drawer, Input } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { readLastNotification } from "store/messages";
import { handleGettAllMessages } from "store/messages";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgDashboardIdField } from "utils";
import "./message.scss";
import { NewMessageForm } from "./NewMessageForm";

const ViewMessages = () => {
  const {
    users: { user },
    messages: { messages },
  } = useSelector((state) => ({
    messages: state.messages,
    users: state.users,
  }));
  const dispatch = useDispatch();
  const [inputSearchParam, setInputSearchParam] = useState("");
  const [activeMessage, setActiveMessage] = useState();
  const [filteredMessages, setFilteredMessages] = useState(messages);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    setFilteredMessages(() =>
      messages?.filter(
        (val) =>
          val?.content
            ?.toLocaleLowerCase()
            .includes(inputSearchParam?.toLocaleLowerCase()) ||
          val?.sender.name
            ?.toLocaleLowerCase()
            .includes(inputSearchParam?.toLocaleLowerCase())
      )
    );
  }, [inputSearchParam, messages]);

  useEffect(() => {
    dispatch(readLastNotification());
  }, []);

  useEffect(() => {
    let queryString = "";

    if (user.position === "CO") {
      const userString = queryString.concat(`user_id=${user?.id}`);
      dispatch(handleGettAllMessages(userString));
      return;
    }

    const orgType = getOrgDashboardIdField(user?.position);
    const orgFieldId = user[getOrgStaffProfileOrgField(user?.position)]?.id;
    const orgString = queryString.concat(`${orgType}=${orgFieldId}`);
    dispatch(handleGettAllMessages(orgString));
  }, []);

  const MessageItem = ({ message }) => {
    return (
      <>
        <div
          className={classNames(
            "d-flex",
            message?.sender?.id === user.id && "my-sent-message justify-content-end"
          )}
        >
          <div
            className={classNames("message-card card p-3")}
            role="button"
            onClick={() => setActiveMessage(message)}
          >
            <h6 className="message-reciever text-muted mb-2">
              To: {message?.reciever}
            </h6>
            <div className="d-flex flex-wrap justify-content-between">
              <h5 className="message-sender">
                <span className="message-reciever text-muted">Sent by:</span>{" "}
                {message?.sender?.id === user.id ? "Me" : message?.sender?.name}
              </h5>
              <span className="text-muted">
                {moment(message.sent_at).fromNow()}
              </span>
            </div>
            <div className="text-muted message-item-content">
              {message?.content}
            </div>
          </div>
        </div>
        <br />
      </>
    );
  };

  return (
    <div>
      <NewMessageForm />
      <Input
        placeholder="Search message"
        allowClear
        value={inputSearchParam}
        className="search-input"
        onChange={(e) => setInputSearchParam(e.target.value)}
      />

      <hr />

      {filteredMessages?.map((message) => (
        <Col>
          <MessageItem
            sender={message?.sender?.name}
            content={message?.content}
            time={moment(message.sent_at).fromNow()}
            message={message}
          />
        </Col>
      ))}

      {/* Message Detail Drawer */}
      <Drawer
        title={`by ${activeMessage?.sender.name}`}
        placement="bottom"
        onClose={() => setActiveMessage(null)}
        visible={!!activeMessage}
        size={isMobile ? "default" : "large"}
        className="message-drawer-content"
      >
        <Badge className="message-detail-date badge-sm mb-3">
          {moment(activeMessage?.sent_at).format("dd, MM YY")}
        </Badge>
        <div className="message-detail-content text-muted">
          {activeMessage?.content}
        </div>
      </Drawer>
    </div>
  );
};

export { ViewMessages };
