import { useState } from "react";
import imageCompression from "browser-image-compression";
import { useDispatch } from "react-redux";
import { uploadFile, deleteFile } from "react-s3";
import { showAlertAction } from "store/alert";

const useHandleImageUpload = (directory = "clients", image) => {
  const [uploadingImage, setUploadingImage] = useState(false);
  const [deletingImage, setDeletingImage] = useState(false);
  const [compressedImage, setCompressedImage] = useState();
  const [imageUrl, setImageUrl] = useState(image);
  const dispatch = useDispatch();

  const config = {
    bucketName: process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME,
    dirName: directory,
    region: process.env.REACT_APP_AWS_S3_REGION_NAME,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const handleCompress = async (imageObj) => {
    if (!imageObj) {
      return;
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const processedImg = await imageCompression(imageObj, options);
    setCompressedImage(processedImg);
    return processedImg;
  };

  const handleImageChange = async (info, dirName) => {
    try {
      if (uploadingImage) {
        return;
      }
      setUploadingImage(true);
      const compressed = await handleCompress(info?.file?.originFileObj);

      const url = await uploadFile(compressed, {
        ...config,
        dirName: dirName || directory,
      })
        .then((data) => data?.location)
        .catch((err) =>
          dispatch(
            showAlertAction({
              message: err?.message,
              level: "danger",
              isVisible: true,
            })
          )
        );
      setUploadingImage(false);

      setImageUrl(url);
      return url;
    } catch (error) {
      setUploadingImage(false);
      dispatch(
        showAlertAction({
          message: "Unable to upload image, please try again",
          level: "danger",
          isVisible: true,
        })
      );
      return undefined;
    }
  };

  const deleteImage = async (imgUri) => {
    try {
      setDeletingImage(true);
      const splitted = imgUri?.split("/");
      const fileName = splitted?.[splitted?.length - 1];
      const dirName = splitted?.[splitted?.length - 2];

      //TODO: Fix me
      // await deleteFile(fileName, {
      //   ...config,
      //   dirName,
      // });
      setImageUrl(undefined)
      setDeletingImage(false);
      return true;
    } catch (error) {
      setImageUrl(undefined)
      setDeletingImage(false);
      dispatch(
        showAlertAction({
          message: "Unable to delete image, please try again",
          level: "danger",
          isVisible: true,
        })
      );
      return false;
    }
  };

  return {
    compressedImage,
    handleCompress,
    handleImageChange,
    compressedImage,
    uploadingImage,
    imageUrl,
    deleteImage,
    deletingImage,
  };
};

export { useHandleImageUpload };
