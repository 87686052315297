import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Select, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { USER_POSITIONS, generatePassword } from "utils";
import { createNewUser } from "store/users";
import { showAlertAction } from "store/alert";
import { permissionLevels } from "utils";
import { getUserAction } from "store/users";
import { staffCreationPositionMapping } from "utils";
import { getAllSectorData } from "store/org";
import { updateUser } from "store/users";
import { changeMyPassword } from "store/users";
import PasswordStrengthBar from "react-password-strength-bar";

const StaffCreateForm = () => {
  const [form] = Form.useForm();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
    org: state.org,
  }));
  const { id } = useParams();
  const [selectedPosition, setSelectedPosition] = useState();
  const [userData, setUserData] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [relatedOrgLevelOption, setRelatedOrgLevelOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [password1Score, setPassword1Score] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordScore, setNewPasswordScore] = useState();
  const { pathname } = useLocation();
  const isEditPage = pathname?.includes?.("edit");
  const shouldAllowEdit =
    !isEditPage || (isEditPage && Number(id) !== Number(user.id));
  const dispatch = useDispatch();

  const shouldBeDisabled = (position, inputType) => {
    if (position === "DR") return false;
    if (position === "SM") {
      return !["ZM", "AM", "BM", "CO"].includes(inputType);
    }
    if (position === "ZM") {
      return !["AM", "BM", "CO"].includes(inputType);
    }
    if (position === "AM") {
      return !["BM", "CO"].includes(inputType);
    }
    if (position === "BM") {
      return !["CO"].includes(inputType);
    }
    return true;
  };

  const handleGetEditingData = async () => {
    if (!id) return;
    setLoading(true);
    const staff = await dispatch(getUserAction(id));
    if (staff) {
      setUserData({ ...staff });
      form.resetFields();
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetEditingData();
  }, []);

  const getRelatedOrgSelectField = async () => {
    if (!selectedPosition || selectedPosition === "DR") {
      return;
    }
    const res = await dispatch(
      getAllSectorData(staffCreationPositionMapping[selectedPosition])
    );
    setRelatedOrgLevelOption(res);
  };

  useEffect(() => {
    getRelatedOrgSelectField();
  }, [selectedPosition]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const passwordOfInterest = isEditPage ? password1Score : newPasswordScore;

    if (passwordOfInterest < 2) {
      dispatch(
        showAlertAction({
          message:
            "Please provide a strong and valid password. HINT: Include uppercase, special characters.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    if (isEditPage) {
      dispatch(updateUser(id, values));
      return;
    }

    await dispatch(
      createNewUser({
        ...values,
        auth_text: values?.password,
        permissionLevel: permissionLevels[values.position],
      })
    );
    form.resetFields();
    setLoading(false);
  };

  const handleChangePassword = async () => {
    setLoading(true);

    if (password1Score < 2) {
      dispatch(
        showAlertAction({
          message:
            "Please provide a strong and valid password. HINT: Include uppercase, special characters.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    if (!(password1 && password2 && password1 === password2)) {
      dispatch(
        showAlertAction({
          message: "Provide valid and matching new passwords",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    const res = await dispatch(
      changeMyPassword({
        new_password: password1,
        re_new_password: password2,
        current_password: userData?.auth_text,
      })
    )

    if (res) {
      await dispatch(
        updateUser(id, {
          ...user,
          auth_text: password1,
        })
      )
    }

    setLoading(false);
  };

  return (
    <div>
      <h3 className="page-title mb-4">
        {isEditPage ? "Edit" : "Create New"} Staff Account
      </h3>

      <Form
        form={form}
        onFinish={(values) => handleSubmit(values)}
        initialValues={userData}
      >
        <Row>
          <Col className="" md="6">
            <Form.Item
              name="position"
              label="Position"
              rules={[
                {
                  required: true,
                  message: "Please select staff position",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select staff position"
                optionFilterProp="children"
                className="custom-input"
                onChange={(e) => setSelectedPosition(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={!shouldAllowEdit}
                allowClear
              >
                {USER_POSITIONS.map(({ id, label }) => (
                  <Select.Option
                    value={id}
                    key={id}
                    disabled={shouldBeDisabled(user?.position, id)}
                  >
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="name"
              label="Staff"
              rules={[
                {
                  required: true,
                  message: "Please provide staff name",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Staff Name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please provide staff email",
                },
              ]}
            >
              <Input
                disabled={!shouldAllowEdit}
                className="form-control"
                type="email"
                placeholder="Email address"
              />
            </Form.Item>
          </Col>

          {!isEditPage && (
            <Col md="6">
              <Form.Item
                label="Password"
                name="password"
                help={
                  <span>
                    Use a strong password, suggestion:{" "}
                    <code>{generatePassword()}</code>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please provide a valid password",
                  },
                ]}
              >
                <Input.Password
                  className="form-control"
                  type="password"
                  placeholder="Pasword"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>
              <PasswordStrengthBar
                minLength={6}
                onChangeScore={(score) => setNewPasswordScore(score)}
                password={newPassword}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col md="6">
            <Form.Item
              label="Phone Number"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please provide staff's phone number",
                },
              ]}
            >
              <Input
                className="form-control"
                type="number"
                placeholder="Phone Number"
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please provide staff's address",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Address"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {(selectedPosition === "BM" || selectedPosition === "CO") && (
            <Col md="6">
              <Form.Item name="branch" label="Branch">
                <Select
                  showSearch
                  placeholder="Select Branch"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relatedOrgLevelOption?.map(({ name, id }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {selectedPosition === "AM" && (
            <Col md="6">
              <Form.Item name="area" label="Area">
                <Select
                  showSearch
                  placeholder="Select Area"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relatedOrgLevelOption?.map(({ name, id }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {selectedPosition === "ZM" && (
            <Col md="6">
              <Form.Item name="zone" label="Zone">
                <Select
                  showSearch
                  placeholder="Select Zone"
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relatedOrgLevelOption?.map(({ name, id }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {selectedPosition === "SM" && (
            <Col md="6">
              <Form.Item name="state" label="State">
                <Select
                  showSearch
                  placeholder="Select State"
                  // disabled={shouldBeDisabled(userPosition, 'state')}
                  optionFilterProp="children"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {relatedOrgLevelOption?.map(({ name, id }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        {/* Only visible when viewing your own edit page or you are >= ZM */}
        {isEditPage &&
          (Number(user?.id) === Number(id) ||
            (!Number(user?.id) !== Number(id) &&
              ["ZM", "SM", "DR"].includes(user?.position))) && (
            <Row>
              <Col md="6">
                <div>
                  Password:{" "}
                  {showPassword ? (
                    <>
                      <strong>{userData?.auth_text}</strong>
                      <i
                        className="fa fa-eye-slash ml-2"
                        aria-hidden="true"
                        role="button"
                        onClick={() => setShowPassword(false)}
                      ></i>
                    </>
                  ) : (
                    <>
                      <strong>xxx xxx xxx</strong>
                      <i
                        className="fa fa-eye ml-2"
                        aria-hidden="true"
                        role="button"
                        onClick={() => setShowPassword(true)}
                      ></i>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          )}

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>

      {!shouldAllowEdit && (
        <>
          <hr />

          <h6>Change Password</h6>
          <Row>
            <Col md="6">
              <label>Password</label>
              <Input.Password
                className="form-control"
                type="password"
                placeholder="New Password"
                value={password1}
                enterKeyHint
                onChange={(e) => setPassword1(e.target.value)}
              />
              <PasswordStrengthBar
                minLength={6}
                onChangeScore={(score) => setPassword1Score(score)}
                password={password1}
              />
            </Col>

            <Col md="6">
              <label>Confirm password</label>
              <Input.Password
                className="form-control"
                type="password"
                placeholder="New Password"
                value={password2}
                enterKeyHint
                onChange={(e) => setPassword2(e.target.value)}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button
                variant="primary"
                className="px-5 btn-sm"
                type="submit"
                disabled={loading}
                onClick={handleChangePassword}
              >
                Change password
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export { StaffCreateForm };
