import { createSlice } from "@reduxjs/toolkit";
import { getOrgDashboardIdField } from "utils";
import { makeRequest } from "utils";
import { BASE_API_URL } from "../db";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "org",
  initialState: {
    cos: [],
    branches: [],
    zones: [],
    areas: [],
    states: [],
    orgDashboardData: {},
    metaData: {},
    orgClients: [],
    loadingOrgDashboard: false,
  },
  reducers: {
    createBranchSuccess: (state, action) => {
      state.branches.push(action.payload);
    },
    createZoneSuccess: (state, action) => {
      state.zones.push(action.payload);
    },
    createAreaSuccess: (state, action) => {
      state.areas.push(action.payload);
    },
    createStateSuccess: (state, action) => {
      state.states.push(action.payload);
    },
    setLoadingDashobard: (state, action) => {
      state.loadingOrgDashboard = action.payload;
    },
    setOrgDashboardData: (state, action) => {
      state.orgDashboardData = action.payload.orgDashboardData;
      state.orgClients = action.payload.orgClients;
    },
    setMetaData: (state, action) => {
      state.metaData = action.payload;
    },
  },
});

export default slice.reducer;

const {
  createBranchSuccess,
  createAreaSuccess,
  createStateSuccess,
  createZoneSuccess,
  setLoadingDashobard,
  setOrgDashboardData,
  setMetaData,
} = slice.actions;

export const createOrgSectorAction =
  ({ type, data, id = "" }) =>
  async (dispatch) => {
    try {
      const requestType = !!id ? "PUT" : "POST";
      const httpOrgData = await makeRequest(
        `${BASE_API_URL}/org/${type}/${id}`,
        requestType,
        data
      );
      data.type === "branches" && dispatch(createBranchSuccess(httpOrgData));
      data.type === "areas" && dispatch(createAreaSuccess(httpOrgData));
      data.type === "zones" && dispatch(createZoneSuccess(httpOrgData));
      data.type === "states" && dispatch(createStateSuccess(httpOrgData));

      return {
        message: "Successfully created",
        status: "success",
      };
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getOrgSectorAction =
  ({ type, id }) =>
  async (dispatch) => {
    try {
      const httpOrgData = await makeRequest(
        `${BASE_API_URL}/org/${type}/${id}`,
        "GET"
      );
      // Get an org based on ID from endpoint
      return httpOrgData;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteOrgSectorAction =
  ({ type, id }) =>
  async (dispatch) => {
    try {
      await makeRequest(`${BASE_API_URL}/org/${type}/${id}`, "DELETE");

      dispatch(
        showAlertAction({
          message: "Org has been deleted successfully",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const editOrgSectionAction =
  ({ type, id }, data) =>
  async (dispatch) => {
    try {
      return {
        message: "Successfully Updated",
        status: "success",
      };
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllSectorData = (type, queryString) => async (dispatch) => {
  // E.G retrieve all states
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/${type}/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getOrgTableData = (data) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/org_table/`,
      "POST",
      data
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const setOrgDashboardAction =
  ({ orgId, type }) =>
  async (dispatch) => {
    try {
      dispatch(setLoadingDashobard(true));

      let httpOrgData =
        type === "cos"
          ? await makeRequest(`${BASE_API_URL}/accounts/users/${orgId}/`, "GET")
          : await makeRequest(`${BASE_API_URL}/org/${type}/${orgId}`, "GET");

      const getManagerDetails = () => {
        if (type === "cos" || !httpOrgData?.manager) return {};
        return {
          manager: httpOrgData?.manager?.name,
          managerId: httpOrgData?.manager?.id,
        };
      };

      dispatch(setMetaData({ ...httpOrgData, ...getManagerDetails() }));

      const orgIdField = getOrgDashboardIdField(type);
      // const httpOrgClients = await makeRequest(`${BASE_API_URL}/accounts/clients/?${orgIdField}=${orgId}`, "GET")

      // Dashboard Figures
      const figuresRes = await makeRequest(
        `${BASE_API_URL}/org/figures/?org_id_field=${orgIdField}&org_id=${orgId}`,
        "GET"
      );

      const getDashboardData = () => {
        return {
          accumulations: {
            "Total Registration Fees": `₦ ${
              Number(figuresRes?.total_reg_fee) || 0
            }`,
            "Total Disbursement Fees": `₦ ${
              Number(figuresRes?.total_disbursement_fee) || 0
            }`,
            "Total Completed Loans": figuresRes?.completed_loans || 0,
            "Total Number of Clients": figuresRes?.number_of_clients || 0,
            "Previous accumulated daily pay cash in hand": `₦${(
              Number(figuresRes?.accumulated_pay_cash_in_hand) || 0
            ).toLocaleString()}`,
            "Previous accumulated savings cash in hand": `₦${(
              Number(figuresRes?.accumulated_savings_cash_in_hand) || 0
            ).toLocaleString()}`,
          },
          cardsData: figuresRes,
        };
      };

      const httpOrg = getDashboardData();
      await dispatch(
        setOrgDashboardData({
          orgDashboardData: httpOrg,
        })
      );
      dispatch(setLoadingDashobard(false));
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const approveRemitance = (data) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/verify_remittance/`,
      "POST",
      data
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addExpenseAction = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/expenses/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Expense has been registered successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addOrgExpenseAction = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/org_expenses/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Expense has been registered successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addSpecialIncome = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/special_income/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Special income has been registered successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllExpensesAction = (queryString) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/expenses/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllOrgExpensesAction = (queryString) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/org_expenses/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteExpenseAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/expenses/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Expense deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteOrgExpenseAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/org_expenses/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Expense deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllSpecialIncomeAction = (queryString) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/special_income/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteSpecialIncomeAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/special_income/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Special income deleted successfully.",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const deleteSiezedAssetsAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/siezedassets/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Seized asset deleted successfully.",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getSiezedAssets = (queryString) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/siezedassets/?${queryString || ""}`,
      "GET"
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getSiezedAsset = (id, type, data) => async (dispatch) => {
  try {
    const httpOrgData = await makeRequest(
      `${BASE_API_URL}/org/siezedassets/${id}/`,
      type,
      data
    );

    return httpOrgData;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addSiezedAssets = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/siezedassets/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Asset has been added successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const sellRetrieveAssetAction =
  (type = "sell", data) =>
  async (dispatch) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/org/siezedassets/${
          type === "sell" ? "sell" : "retrieval"
        }/`,
        "POST",
        data
      );

      dispatch(
        showAlertAction({
          message: `Asset ${type} successfully 🎉`,
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const bankDepositsAction =
  (type = "POST", data, queryString) =>
  async (dispatch) => {
    try {
      const httpsRes = await makeRequest(
        `${BASE_API_URL}/org/bankdeposits/?${queryString}`,
        type,
        data
      );

      if (type === "POST") {
        dispatch(
          showAlertAction({
            message: `Deposit recorded successfully 🎉`,
            level: "success",
            isVisible: true,
          })
        );
        return;
      }
      return httpsRes;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const bankDepositDeleteAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/bankdeposits/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Deposit has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const remittanceToBmAction =
  (type = "POST", data, queryString) =>
  async (dispatch) => {
    try {
      const httpsRes = await makeRequest(
        `${BASE_API_URL}/org/remittance_to_bm/?${queryString}`,
        type,
        data
      );

      if (type === "POST") {
        dispatch(
          showAlertAction({
            message: `Rmittance recorded successfully 🎉`,
            level: "success",
            isVisible: true,
          })
        );
        return;
      }
      return httpsRes;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const remittanceDeleteAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/remittance_to_bm/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Remittance has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};
export const bankWithdrawalDeleteAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/bankwithdrawals/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Withdrawal has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const bankWithdrawalAction =
  (type = "POST", data, queryString) =>
  async (dispatch) => {
    try {
      const httpsRes = await makeRequest(
        `${BASE_API_URL}/org/bankwithdrawals/?${queryString}`,
        type,
        data
      );

      if (type === "POST") {
        dispatch(
          showAlertAction({
            message: "Withdrawal recorded successfully 🎉",
            level: "success",
            isVisible: true,
          })
        );
        return;
      }
      return httpsRes;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const procurementCreateAction = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/procurements/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Procurement recorded successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getProcurementsAction = (queryString) => async (dispatch) => {
  try {
    const httpRes = await makeRequest(
      `${BASE_API_URL}/org/procurements/?${queryString}`,
      "GET"
    );

    return httpRes;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const procurementDeleteAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/procurements/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Procurement deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const reportCreateAction = (data) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/reports/`, "POST", data);

    dispatch(
      showAlertAction({
        message: "Report added successfully 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getReportsAction = (queryString) => async (dispatch) => {
  try {
    const httpRes = await makeRequest(
      `${BASE_API_URL}/org/reports/?${queryString}`,
      "GET"
    );

    return httpRes;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const reportDeleteAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/org/reports/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Procurement deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message || "An error occured, please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};
