import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaffsWithParams } from "store/clients";
import { getAllSectorData } from "store/org";
import { staffCreationPositionMapping } from "utils";
import { getOrgDashboardIdField } from "utils";
import { getOrgStaffProfileOrgField } from "utils";

const useOrgAndRelatedOrgInput = () => {
  const [loading, setLoading] = useState(false);
  const [relatedOrgOption, setRelatedOrgOption] = useState([]);
  const [orgType, setOrgType] = useState("");
  const [selectFieldName, setSelectFieldName] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);

  const handleFetchOrgsData = async (type) => {
    setOrgType(type);
    setLoading(true);
    setRelatedOrgOption([])
    const userOrgId = user[getOrgStaffProfileOrgField(user.position)]?.id;
    const userIdField = getOrgDashboardIdField(user.position);
    setSelectFieldName(getOrgDashboardIdField(type));

    if (type === staffCreationPositionMapping[user.position]) {
      setRelatedOrgOption([user[getOrgStaffProfileOrgField(user.position)]]);
      setLoading(false);
      return;
    }

    const queryString = `${userIdField}=${userOrgId}`;
    const res = await dispatch(getAllSectorData(type, queryString));
    setRelatedOrgOption(res);
    setLoading(false);
  };

const handleFetchUserData = async () => {
    setSelectFieldName('user_id')
    setOrgType('User')
    setLoading(true);
    setRelatedOrgOption([])
    const userOrgId = user[getOrgStaffProfileOrgField(user.position)]?.id;
    const userIdField = getOrgDashboardIdField(user.position);
    
    const queryString = `${userIdField || ""}=${userOrgId || ""}`;
    const res = await dispatch(getStaffsWithParams(queryString, ""));
    setRelatedOrgOption(res);
    setLoading(false);
  }

  return {
    onSelectOrgType: handleFetchOrgsData,
    handleFetchUserData,
    loadingOrg: loading,
    relatedOrgOption,
    selectFieldName,
    orgType,
  };
};

export { useOrgAndRelatedOrgInput };
