import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import clients from "./clients";
import alerts from "./alert";
import users from "./users";
import lists from "./lists";
import org from "./org";
import messages from "./messages"
import loans from "./loans"

const reducer = combineReducers({
  clients,
  alerts,
  lists,
  users,
  org,
  messages,
  loans
  // here we will be adding reducers
});

const store = configureStore({
  reducer,
});

export { store };
