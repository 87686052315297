import { DatePicker, Select, Input } from "antd";
import { useFilterDateDropdown } from "hooks/useFilterDateDropdown";
import { useListTitle } from "hooks/useListTitle";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { NumberParam, useQueryParam } from "use-query-params";
import { getOrgDashboardIdField } from "utils";
import { getOrgStaffProfileOrgField } from "utils";
import { getSummationOfFields } from "utils";
import { ModifiedAntTable } from "./ModifiedAntTable";
import { getAllWithdrawals } from "store/loans";
import moment from "moment";
import {debounce} from 'lodash';

const withdrawalReasons = {
  payDaily: "Pay loan repayment",
  cashWithdrawal: "Cash withdrawal",
};

const SavingsWithdrawalList = ({}) => {
  const [loading, setLoading] = useState(false);
  const [summedData, setSummedData] = useState({});
  const [withdrawals, setWithdrawals] = useState([]);
  const [filteredWithdrawals, setFilteredWithdrawals] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [debouncedFilterName, setDebouncedFilterName] = useState('');
  const [filterReasons, setFilterReasons] = useState(undefined);
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const [filterDate, setFilterDate] = useState([
    moment().subtract(1, "days"),
    moment(),
  ]);
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const { renderFilter } = useFilterDateDropdown({ setFilterDate });
  const { fullListName } = useListTitle("withdrawals")

  useEffect(() => {
    const filtered = withdrawals?.filter((withdrawal) => {
      if (filterReasons && withdrawal.reason !== filterReasons) {
        return false;
      }
      return true;
    });
    setFilteredWithdrawals(filtered);
  }, [withdrawals, filterReasons]);

  useEffect(() => {
    setSummedData(
      getSummationOfFields({
        data: filteredWithdrawals,
        fieldsToSum: ["amount"],
      })
    );
  }, [filteredWithdrawals]);

  useEffect(() => {
    const userProfileOrg = getOrgStaffProfileOrgField(user.position);

    const orgIdField = getOrgDashboardIdField(type || user.position);
    let org_id = id || user[userProfileOrg]?.id;
    
    if (user.position === 'CO' && !id) {
      org_id = user.id
    }
    let queryString = `${orgIdField}=${org_id}&client_name=${filterName}`;

    if (filterDate) {
      queryString = queryString.concat(`&start_date=${filterDate[0].format(
        "YYYY/MM/DD"
      )}&end_date=${filterDate[1].format(
        "YYYY/MM/DD"
      )}`);
    }

    setLoading(true);
    dispatch(getAllWithdrawals(queryString))
      .then((result) => {
        setWithdrawals(() => result || []);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [filterDate, debouncedFilterName, refreshTrigger]);

  const debounceFilter = useCallback(
    debounce((value) => setDebouncedFilterName(value), 300),
    []
  );

  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
    debounceFilter(e.target.value);
  };

  const tableColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(page) || 1) - 1) * 50 + (index + 1),
      summary: "Summations",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "By",
      render: (text, record) => <div>{record?.author?.name}</div>,
      summary: "-",
    },
    {
      title: "Client",
      render: (text, record, index) => <div>{record?.client?.fullname}</div>,
      summary: "-",
    },
    {
      title: "Account",
      dataIndex: "account",
      render: (text, record, index) => <div>{record?.account}</div>,
      summary: "-",
    },
    {
      title: "Withdrawal Amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString() || "-"}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Purpose",
      render: (text, record, index) => (
        <div>{withdrawalReasons[record?.reason] || ""}</div>
      ),
      summary: "-",
    },
  ];

  return (
    <div>
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Input.Group compact className="mb-3 filter-panel">
        <Input
          style={{ width: "50%" }}
          placeholder="Filter by client's firstname or surname"
          value={filterName}
          onChange={handleFilterNameChange}
        />
        <Select
          allowClear
          className="default-ant-select"
          style={{ width: "50%" }}
          placeholder="Filter by purpose"
          value={filterReasons}
          onChange={setFilterReasons}
        >
          {Object.keys(withdrawalReasons)?.map((val, index) => (
            <Select.Option key={val} value={val}>
              {withdrawalReasons[val]}
            </Select.Option>
          ))}
        </Select>
      </Input.Group>


      <Row compact className="mb-3 filter-panel">
        <Col className="d-flex">
          {renderFilter()}
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            onChange={(val) => setFilterDate(val)}
            className="mb-3"
            value={filterDate}
          />
        </Col>
      </Row>

      <ModifiedAntTable
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        dataSource={filteredWithdrawals}
        showSummary={true}
        bordered
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
        }}
      />
    </div>
  );
};

export { SavingsWithdrawalList };
