import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { getClientById } from "store/clients";
import { useParams } from "react-router";
import {
  Descriptions,
  Image,
  Select,
  Table,
  Tabs,
  Tooltip,
  Upload,
} from "antd";
import { getLoansWithParams } from "store/loans";
import { WithdrawalForm } from "components/WithdrawalForm";
import { getAllWithdrawals } from "store/loans";
import { updateClient } from "store/clients";
import { getOrgStaffProfileOrgField } from "utils";
import { getOrgDashboardIdField } from "utils";
import { getAllunions, refreshSavingsAction } from "store/clients";
import { ModifiedAntTable } from "components/ModifiedAntTable";

import RefreshImg from "assets/img/arrows-rotate-solid.svg";
import EditImg from "assets/img/edit.png";
import { InputModal } from "./InputModal";
import { ImageUpload } from "components/ImageUpload";
import { cleanedImage } from "utils";
import { getAllClosedLoanRecord } from "store/loans";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { getStatusClassName } from "utils";
import { getSummationOfFields } from "utils";
import { refreshUionBalanceAction } from "store/clients";
import { getAllFieldCollecionAction } from "store/loans";
import { ClientAdditionalSavings } from "./client/ClientAdditionalSavings";

const { TabPane } = Tabs;

const withdrawalReasons = {
  payDaily: "Pay loan repayment",
  // payAllDaily: "Pay all daily repayment",
  cashWithdrawal: "Cash withdrawal",
};

const ClientProfile = () => {
  const [clientData, setClientData] = useState();
  const [clientLoans, setClientLoans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [fieldCollectinoData, setFieldCollectinoData] = useState();
  const [closedLoanRecord, setClosedLoanRecord] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [unionOptions, setUnionOptions] = useState([]);
  const [unionInput, setUnionInput] = useState();
  const [assigningUnion, setAssigningUnion] = useState(false);
  const [withdrawPage, setWithdrawPage] = useState(1);
  const [fieldCollectionPage, setFieldCollectionPage] = useState(1);
  const [loanPage, setLoanPage] = useState(1);
  const [kycDetail, setKycDetail] = useState();
  const [summedData, setSummedData] = useState({});
  const [summedClosedLoans, setSummedClosedLoans] = useState({});
  const [summedFieldCollection, setSummedFieldCollection] = useState({});
  const [summedClientLoans, setSummedClientLoans] = useState({});

  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));
  const isProd = window.location.hostname === "opulentpeerless.com";

  const handleTriggerRefresh = () => setTriggerRefresh((current) => !current);

  const handleSetkYC = async () => {
    dispatch(updateClient(id, { kyc: String(kycDetail) })).then((res) => {
      setClientData(res);
    });
  };

  const handleAssignUnion = () => {
    if (!unionInput) {
      return;
    }

    setAssigningUnion(true);
    dispatch(updateClient(id, { union_id: Number(unionInput) })).then(() => {
      setAssigningUnion(false);
      handleTriggerRefresh();
    });
  };

  const handleUpdateClient = (data = {}) => {
    if (!data) return;
    dispatch(updateClient(id, { ...data })).then(() => {
      setAssigningUnion(false);
      handleTriggerRefresh();
    });
  };

  const handleRefreshSavingsOrUnion = async (type) => {
    setLoading(true);
    if (type === "union") {
      if (
        window.confirm(
          "Are you sure you want to empty the union balance?. Note: This can not be reversed"
        )
      ) {
        dispatch(refreshUionBalanceAction(id)).then(() => {
          setLoading(false);
          handleTriggerRefresh();
        });
      }

      return;
    }

    if (
      window.confirm(
        "Are you sure you want to empty the savings balance?. Note: This can not be reversed"
      )
    ) {
      dispatch(refreshSavingsAction(id)).then(() => {
        setLoading(false);
        handleTriggerRefresh();
      });
    }
  };

  const handleChangeSavingsOrUnionBalance = async (value, type) => {
    setLoading(true);
    if (type === "union") {
      dispatch(refreshUionBalanceAction(id, value)).then(() => {
        setLoading(false);
        setTriggerRefresh((current) => !current);
      });

      return;
    }

    dispatch(refreshSavingsAction(id, value)).then(() => {
      setLoading(false);
      setTriggerRefresh((current) => !current);
    });
  };

  useEffect(() => {
    if (!clientData?.union) {
      const orgType = getOrgStaffProfileOrgField(user?.position);
      const orgIdField = getOrgDashboardIdField(user?.position);
      const orgFieldId =
        user?.position === "CO" ? user?.id : user?.[orgType]?.id;

      let queryString = "";
      queryString = queryString.concat(`${orgIdField}=${orgFieldId}`);

      dispatch(getAllunions(queryString)).then((result) => {
        setUnionOptions(result);
      });
    }
  }, [clientData]);

  useEffect(() => {
    if (window.innerWidth > 700) {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    setKycDetail(clientData?.kyc);
  }, [clientData]);

  useEffect(async () => {
    setLoading(true);
    const res = await dispatch(getClientById(id));
    setClientData(res);

    dispatch(getLoansWithParams(`client_id=${id}`)).then((result) => {
      setClientLoans(result);
      setLoading(false);

      setSummedClientLoans(
        getSummationOfFields({
          data: result,
          fieldsToSum: ["loan_amount", "daily_pay"],
        })
      );
    });

    dispatch(getAllWithdrawals(`client_id=${id}`)).then((result) => {
      setWithdrawalData(result);

      setSummedData(
        getSummationOfFields({
          data: result,
          fieldsToSum: ["amount"],
        })
      );
    });

    dispatch(getAllClosedLoanRecord(id)).then((result) => {
      setClosedLoanRecord(result);

      setSummedClosedLoans(
        getSummationOfFields({
          data: result,
          fieldsToSum: ["amount", "waiver_fee_amount", "savings_balance_amount", "number_of_daily_pay"],
        })
      );
    });
  }, [triggerRefresh]);

  useEffect(() => {
    const queryString = `client_id=${id}&page=${fieldCollectionPage}`;

    dispatch(getAllFieldCollecionAction(queryString)).then((res) => {
      setFieldCollectinoData(res);

      setSummedFieldCollection(
        getSummationOfFields({
          data: res?.results,
          fieldsToSum: ["amount"],
        })
      );
    });
  }, [fieldCollectionPage]);

  const withdrawalColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(withdrawPage) || 1) - 1) * 50 + (index + 1),
      summary: "Summations",
    },
    {
      title: "By",
      render: (text, record) => <div>{record?.author?.name}</div>,
      summary: "-",
    },
    {
      title: "Client",
      render: (text, record, index) => <div>{record?.client?.fullname}</div>,
      summary: "-",
    },
    {
      title: "Account",
      dataIndex: "account",
      render: (text, record, index) => <div>{record?.account}</div>,
      summary: "-",
    },
    {
      title: "Withdrawal Amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString() || "-"}</div>
      ),
      summary: summedData["amount"],
    },
    {
      title: "Purpose",
      render: (text, record, index) => (
        <div>{withdrawalReasons[record?.reason] || ""}</div>
      ),
      summary: "-",
    },
  ];

  const fieldCollectionColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(fieldCollectionPage) || 1) - 1) * 50 + (index + 1),
      summary: "Summations",
    },
    {
      title: "Date",
      dataIndex: "date",
      summary: "-",
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString()}</div>
      ),
      summary: summedFieldCollection['amount'],
    },
    {
      title: "Collector",
      dataIndex: "name",
      render: (text, record) => <div>{record?.collector.name}</div>,
      summary: "-",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_method",
      summary: "-",
    },
    {
      title: "Note",
      dataIndex: "descripion",
      render: (text, record) => (
        <Tooltip title={record.description} trigger="click">
          <span className="pointer">View</span>
        </Tooltip>
      ),
      summary: "-",
    },
  ];

  const closeRecordColumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) => index + 1,
      summary: "Summations",
    },
    {
      title: "date",
      dataIndex: "date",
      summary: "-",
    },
    {
      title: "Loan",
      render: (text, record) => (
        <Link to={`/loans/${record.loan.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i> Click here
        </Link>
      ),
      summary: "-",
    },
    {
      title: "Number of closed installment",
      dataIndex: "number_of_daily_pay",
      summary: summedClosedLoans["number_of_daily_pay"],
    },
    {
      title: "Savings balance",
      dataIndex: "savings_balance_amount",
      render: (text, record) => (
        <div>{Number(record?.savings_balance_amount).toLocaleString()}</div>
      ),
      summary: summedClosedLoans['savings_balance_amount'],
    },
    {
      title: "Waiver fee",
      render: (text, record) => (
        <div>{Number(record?.waiver_fee_amount).toLocaleString()}</div>
      ),
      summary: summedClosedLoans['waiver_fee_amount'],
    },
    {
      title: "Closed_by",
      render: (text, record, index) => <div>{record?.closed_by?.name}</div>,
      summary: "-",
    },
  ];

  const loanColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) =>
        ((Number(loanPage) || 1) - 1) * 50 + (index + 1),
      summary: "Summary",
    },
    {
      title: "Request date",
      dataIndex: "creation_date",
      summary: "-",
    },
    {
      title: "Amount ₦",
      dataIndex: "loan_amount",
      render: (text, record) => <>{Number(text).toLocaleString()}</>,
      summary: summedClientLoans['loan_amount'],
    },
    {
      title: "Loan Daily Pay ₦",
      dataIndex: "daily_pay",
      render: (text, record) => <>{Number(text).toLocaleString()}</>,
      summary: summedClientLoans['daily_pay'],
    },
    {
      title: "Daily Payment Status",
      render: (text, record) => (
        <div
          className={classNames(
            "status-badge badge badge-sm",
            record?.today_payment_stat === "PAID"
              ? "badge-success"
              : "badge-warning"
          )}
        >
          {record?.today_payment_stat}
        </div>
      ),
      summary: "-",
    },
    {
      title: "Loan Status",
      render: (text, record) => (
        <div
          className={classNames(
            "status-badge badge badge-sm",
            getStatusClassName(record?.loan_status)
          )}
        >
          {record?.loan_status}
        </div>
      ),
      summary: "-",
    },
    {
      title: "Loan",
      render: (text, record) => (
        <Link to={`/loans/${record.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i> Click here
        </Link>
      ),
      summary: "-",
    },
  ];

  return (
    <Container fluid>
      <h6 className="mb-3">
        {clientData?.title} {clientData?.surname} {clientData?.firstname}
      </h6>
      <h6 className="mb-3 d-flex align-items-center">
        Savings Balance: ₦{Number(clientData?.savings_balance).toLocaleString()}
        {["DR", "SM", "ZM"].includes(user?.position) && (
          <div>
            <img
              src={RefreshImg}
              alt=""
              height={15}
              width={15}
              className="ml-2"
              role="button"
              onClick={() => handleRefreshSavingsOrUnion("savings")}
            />

            <InputModal
              value={clientData?.savings_balance}
              onSubmit={(val) =>
                handleChangeSavingsOrUnionBalance(val, "savings")
              }
            >
              <img
                src={EditImg}
                alt="edit"
                height={15}
                width={15}
                className="ml-2"
                role="buton"
              />
            </InputModal>
          </div>
        )}
      </h6>
      <h6 className="mb-3 d-flex align-items-center">
        Union Balance: ₦{Number(clientData?.union_balance).toLocaleString()}
        {["DR", "SM", "ZM"].includes(user?.position) && (
          <div>
            <img
              src={RefreshImg}
              alt=""
              height={15}
              width={15}
              className="ml-2"
              role="button"
              onClick={() => handleRefreshSavingsOrUnion("union")}
            />

            <InputModal
              value={clientData?.savings_balance}
              onSubmit={(val) =>
                handleChangeSavingsOrUnionBalance(val, "union")
              }
            >
              <img
                src={EditImg}
                alt="edit"
                height={15}
                width={15}
                className="ml-2"
                role="buton"
              />
            </InputModal>
          </div>
        )}
      </h6>
      <h6 className="mb-3">
        Waiver Balance: ₦
        {Number(clientData?.client_waiver_balance).toLocaleString()}
      </h6>
      <Tabs
        tabPosition={isMobile ? "top" : "left"}
        defaultActiveKey="1"
        className="loan-detail-tabs"
      >
        <TabPane tab="Client Detail" key="1">
          <Row>
            <ImageUpload
              image={cleanedImage(clientData?.image1)}
              uploadDir="clients"
              onImageUpload={(image) => handleUpdateClient({ image1: image })}
              onDeleteImage={() => handleUpdateClient({ image1: null })}
            />

            <ImageUpload
              image={cleanedImage(clientData?.image2)}
              uploadDir="clients"
              onImageUpload={(image) => handleUpdateClient({ image2: image })}
              onDeleteImage={() => handleUpdateClient({ image1: null })}
            />
          </Row>
          <Descriptions
            title={`${clientData?.title} ${clientData?.surname} ${clientData?.firstname}`}
            layout="vertical"
            className="mt-3"
          >
            {clientData?.union && (
              <Descriptions.Item label="Union">
                {clientData?.union?.name}
              </Descriptions.Item>
            )}

            <Descriptions.Item label="Credit Officer">
              {clientData?.co_fullname}
            </Descriptions.Item>

            <Descriptions.Item label="House Address">
              {clientData?.house_address}
            </Descriptions.Item>

            <Descriptions.Item label="Office Address">
              {clientData?.office_address}
            </Descriptions.Item>

            <Descriptions.Item label="Phone Number 1">
              {clientData?.phone_number1}
            </Descriptions.Item>

            <Descriptions.Item label="Phone Number 2">
              {clientData?.phone_number2}
            </Descriptions.Item>

            <Descriptions.Item label="Marital Status">
              {clientData?.marital_status}
            </Descriptions.Item>

            <Descriptions.Item label="Number of children">
              {clientData?.number_of_children}
            </Descriptions.Item>

            <Descriptions.Item label="Registration fee">
              {clientData?.reg_fee}
            </Descriptions.Item>

            <Descriptions.Item label="NIN">{clientData?.nin}</Descriptions.Item>

            <Descriptions.Item label="Branch Name">
              {clientData?.branch_name}
            </Descriptions.Item>

            <Descriptions.Item label="Area Name">
              {clientData?.area_name}
            </Descriptions.Item>

            <Descriptions.Item label="Zone Name">
              {clientData?.zone_name}
            </Descriptions.Item>

            <Descriptions.Item label="State Name">
              {clientData?.state_name}
            </Descriptions.Item>
          </Descriptions>

          <hr />

          <h6>Union</h6>

          {clientData?.union ? (
            <div></div>
          ) : (
            <div className="d-flex flex-column">
              <div className="mb-3">Client does not belong to any Union</div>
              <Select
                allowClear
                className="default-ant-select"
                placeholder="Select Union"
                value={unionInput}
                onChange={(val) => setUnionInput(val)}
              >
                {unionOptions?.map((val) => (
                  <Select.Option value={val?.id} key={val?.id}>
                    {val?.name}
                  </Select.Option>
                ))}
              </Select>

              <Button
                disabled={loading || assigningUnion}
                onClick={handleAssignUnion}
                variant="primary"
                className="mt-3 btn-sm"
                type="submit"
              >
                Submit
              </Button>
            </div>
          )}
        </TabPane>

        <TabPane tab="Loans" key="2">
          <h5 className="mb-4">List of Loans</h5>

          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={loanColumns}
            dataSource={clientLoans}
            bordered
            loading={loading}
            showSummary={true}
            pagination={{
              defaultCurrent: loanPage,
              current: loanPage,
              defaultPageSize: 50,
              hideOnSinglePage: true,
              onChange: (current) => setLoanPage(current),
            }}
          />
        </TabPane>
        <TabPane tab="Withdrawal Form" key="3">
          <WithdrawalForm
            clientData={clientData}
            userId={user?.id}
            handleTriggerRefresh={handleTriggerRefresh}
          />
        </TabPane>
        <TabPane tab="Withdrawal List" key="4">
          <h5 className="mb-4">List of Withdrawals</h5>

          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={withdrawalColumn}
            dataSource={withdrawalData}
            bordered
            loading={loading}
            showSummary={true}
            pagination={{
              defaultCurrent: withdrawPage,
              current: withdrawPage,
              defaultPageSize: 50,
              hideOnSinglePage: true,
              onChange: (current) => setWithdrawPage(current),
            }}
          />
        </TabPane>

        <TabPane tab="Field Collections" key="field-collection">
          <h5 className="mb-4">List of Field Collections</h5>

          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={fieldCollectionColumn}
            dataSource={fieldCollectinoData?.results}
            bordered
            loading={loading}
            showSummary={true}
            pagination={{
              defaultCurrent: fieldCollectionPage,
              current: fieldCollectionPage,
              defaultPageSize: 50,
              hideOnSinglePage: true,
              onChange: (current) => setFieldCollectionPage(current),
              total: fieldCollectinoData?.count,
            }}
          />
        </TabPane>

        <TabPane tab="Additional Savings" key="additional-savings">
          <ClientAdditionalSavings clientId={id} />
        </TabPane>

        <TabPane tab="Loan Close Record" key="close">
          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={closeRecordColumn}
            dataSource={closedLoanRecord}
            bordered
            loading={loading}
            showSummary={true}
          />
        </TabPane>

        <TabPane tab="KYC Detiail" key="kyc">
          <ReactQuill
            theme="snow"
            defaultValue={kycDetail}
            value={kycDetail}
            onChange={setKycDetail}
            className="rich-text"
            style={{ height: "250px", marginBottom: "30px" }}
          />

          <button
            disabled={kycDetail === clientData?.kyc}
            className="btn btn-sm btn-primary mt-5"
            onClick={handleSetkYC}
          >
            Update KYC
          </button>
        </TabPane>
      </Tabs>
    </Container>
  );
};

export { ClientProfile };
