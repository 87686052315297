import { Table } from "antd";
import React, { memo } from "react";
import _ from "lodash";

const ModifiedAntTable = memo(
  ({ showSummary = false, columns, dataSource, ...otherProps }) => {
    const summary = _.flatten(
      columns.map((inst) => {
        // for array
        if (typeof inst?.summary === "object") {
          return inst?.summary?.map((val) => (
            <Table.Summary.Cell>{val}</Table.Summary.Cell>
          ));
        }
        return <Table.Summary.Cell>{inst?.summary}</Table.Summary.Cell>;
      })
    );

    return (
      <Table
        {...otherProps}
        columns={columns}
        dataSource={dataSource}
        summary={
          showSummary && !!dataSource?.length
            ? () => <Table.Summary.Row>{summary}</Table.Summary.Row>
            : undefined
        }
      />
    );
  }
);

export { ModifiedAntTable };
