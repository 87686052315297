import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Input, Select } from "antd";
import { Button, Col, Row } from "react-bootstrap";
import { createHoliday } from "store/loans";
import moment from "moment";
import { getOrgDashboardIdField } from "utils";
import { getOrgStaffProfileOrgField } from "utils";
import { getAllSectorData } from "store/org";
import { INCOME_ORG_TYPE } from "./staffs";
import { staffCreationPositionMapping } from "utils";
import { permissionLevels } from "utils";
import { getStaffsWithParams } from "store/clients";

const ORG_SELECT_OPTIONS = [
  ...INCOME_ORG_TYPE,
  { label: "Staff", id: "cos", permissionLevel: permissionLevels.BM },
];

const CreateHolidays = () => {
  const [relatedOrgOption, setRelatedOrgOption] = useState([]);
  const [orgType, setOrgType] = useState("");
  const [selectFieldName, setSelectFieldName] = useState("");

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    users: { user },
  } = useSelector((state) => ({
    users: state.users,
  }));

  const handleFinish = (values) => {
    if (
      window.confirm(
        "Are you sure you want to create this new holiday, and move all payments forward?"
      )
    ) {
      setLoading(true);
      dispatch(
        createHoliday({
          ...values,
          state_id: user?.position === "SM" ? user?.state?.id : "",
          orgIdField: values,
          creator_id: user?.id,
          date: moment(values?.date).format("YYYY-MM-DD"),
        })
      ).then(() => {
        setLoading(false)
        form.resetFields()
      });
    }
  };

  const handleFetchOrgsData = async (type) => {
    setOrgType(type);
    setLoading(true);
    const userOrgId = user[getOrgStaffProfileOrgField(user.position)]?.id;
    const userIdField = getOrgDashboardIdField(user.position);
    setSelectFieldName(getOrgDashboardIdField(type));
    const queryString = `${userIdField}=${userOrgId}`;

    if (type === "cos") {
      const res = await dispatch(getStaffsWithParams(queryString, ""));
      setRelatedOrgOption(res);
      setLoading(false);
      return;
    }

    if (type === staffCreationPositionMapping[user.position]) {
      setRelatedOrgOption([user[getOrgStaffProfileOrgField(user.position)]]);
      setLoading(false);
      return;
    }

    const res = await dispatch(getAllSectorData(type, queryString));
    setRelatedOrgOption(res);
    setLoading(false);
  };

  return (
    <div>
      <h3 className="page-title mb-4">Add Holiday</h3>

      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Select Org Level"
              rules={[
                {
                  required: true,
                  message: "Please select a CATEGORY",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select credit officer"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onSelect={(val) => handleFetchOrgsData(val)}
              >
                {ORG_SELECT_OPTIONS.map(
                  ({ id, label, permissionLevel }) =>
                    user?.permissionLevel >= permissionLevel && (
                      <Select.Option value={id} key={id}>
                        {label}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>

          {!!relatedOrgOption?.length && (
            <Col md="6">
              <Form.Item
                name={selectFieldName}
                rules={[
                  {
                    required: true,
                    message: "Please an an option",
                  },
                ]}
                label={`${orgType === "cos" ? "Staff" : orgType}`}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select org option"
                  className="custom-input"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  allowClear
                  loading={loading}
                >
                  {relatedOrgOption.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col md="6">
            <Form.Item
              name="name"
              label="Type of Holiday"
              rules={[
                {
                  required: true,
                  message: "Please provide name",
                },
              ]}
            >
              <Input
                className="form-control"
                type="text"
                placeholder="Type of holiday"
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="date"
              rules={[{ required: true, message: "Please provide date" }]}
              label="Date"
            >
              <DatePicker
                allowClear
                style={{ width: "100%" }}
                className="mb-3"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="description" label="Additional detail (Optional)">
          <Input
            className="form-control"
            type="text"
            placeholder="Description"
          />
        </Form.Item>

        <div>
          Note: All payments scheduled for an holiday date would be moved
          forward, and cannot be reversed by deleting the holiday.
        </div>

        <Row>
          <Col className="mt-3">
            <Button
              variant="primary"
              className="px-5"
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export { CreateHolidays };
