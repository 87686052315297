import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrgDashboardIdField, getOrgStaffProfileOrgField } from "utils";
import { getSlimClientsWithParams } from "../store/clients";
import { addSiezedAssets } from "store/org";
import { showAlertAction } from "store/alert";
import { ImageUpload } from "./ImageUpload";

const SeizedAssetsForm = () => {
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.users);
  const [imageUrl, setImageUrl] = useState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    if (!imageUrl) {
      dispatch(
        showAlertAction({
          message:
            "Please upload an image of the asset before submitting this form.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }
    setLoading(true);
    await dispatch(addSiezedAssets({ ...values, image: imageUrl }));
    setLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    setLoading(true);
    const userOrgId =
      user.position === "CO"
        ? user.id
        : user[getOrgStaffProfileOrgField(user.position)]?.id;
    const userIdField = getOrgDashboardIdField(user.position);

    const queryString = `${userIdField}=${userOrgId}`;
    dispatch(getSlimClientsWithParams(queryString)).then((res) => {
      setClients(res);
      setLoading(false);
    });
  }, []);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Add Siezed Asset</h3>

      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              name="name"
              label="Name of Asset"
              rules={[
                {
                  required: true,
                  message: "Please provide name",
                },
              ]}
            >
              <Input
                placeholder="Enter Name"
                type="text"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item name="serial_number" label="Serial number">
              <Input
                placeholder="Enter Serial number"
                type="text"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col md="6">
            <Form.Item
              name="owner_id"
              rules={[
                {
                  required: true,
                  message: "Please select client",
                },
              ]}
              label="Asset Owner"
            >
              <Select
                showSearch
                optionFilterProp="children"
                className="custom-input"
                placeholder="Select asset owner"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                allowClear
              >
                {clients.map(({ id, surname, firstname }) => (
                  <Select.Option value={id} key={id}>
                    {`${surname} ${firstname}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="quantity"
              label="Quantity of assets"
              rules={[
                {
                  required: true,
                  message: "Please provide quantitiy of assets.",
                },
              ]}
            >
              <Input
                placeholder="Enter Quantity"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount owed by owner"
              rules={[
                {
                  required: true,
                  message: "Please provide amount",
                },
              ]}
            >
              <Input
                placeholder="Enter amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please provide date",
                },
              ]}
            >
              <Input placeholder="Date" type="date" className="form-control" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <ImageUpload
              uploadDir="seized_assets"
              onImageUpload={setImageUrl}
              onDeleteImage={() => setImageUrl(undefined)}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { SeizedAssetsForm };
