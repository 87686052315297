import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { setOrgDashboardAction } from "store/org";
import { showAlertAction } from "../store/alert";
import { setUserDashboard } from "../store/users";
import { positionToOrgMapping, getOrgDashboardIdField } from "utils";
import { positionToOrgMapping2 } from "utils";
import { getOrgTableData } from "store/org";
import moment from "moment";
import { approveRemitance } from "store/org";
import { getTotal } from "utils";

const useDashboard = ({ id }) => {
  const {
    users: {
      user,
      userDashboardData,
      metaData: userMetaData,
      loadingDashboard,
    },
    org: { orgDashboardData, loadingOrgDashboard, metaData: orgMetaData },
  } = useSelector((state) => ({
    users: state.users,
    org: state.org,
  }));
  const { pathname } = useLocation();
  const { type, id: orgId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const permissionLevel = user.permissionLevel;
  const [viewListData, setViewListData] = useState();
  const [isOrgDashboard, setIsOrgDashboard] = useState(
    pathname.includes("org")
  );
  const userOrgField = positionToOrgMapping[user.position];
  const useUserDashboard = !isOrgDashboard && user.position === "DR";
  const [tableData, setTableData] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableMeta, _] = useState({
    startDate: moment().subtract(4, "days"),
    endDate: moment(),
  });
  const cardData = useUserDashboard
    ? userDashboardData?.cardsData
    : orgDashboardData?.cardsData;
  const metadata = useUserDashboard ? userMetaData : orgMetaData;

  useEffect(() => {
    setIsOrgDashboard(pathname.includes("org"));
  }, [pathname]);

  const historyPushWithParam = (path, name) => {
    if (isOrgDashboard) {
      history.push(
        `${pathname}/dashboard-list/${path}?org=${
          type || userOrgField
        }&name=${name}`
      );
      return;
    }

    history.push(
      `/dashboard-list/${path}?org=${type || userOrgField}&name=${name}`
    );
  };

  const getOrgLink = (path, name, field, fieldId) => {
    const link = isOrgDashboard
      ? `${pathname}/dashboard-list/${path}?org=${type || userOrgField}&name=${
          name || ""
        }&field=${field || ""}&fieldId=${fieldId || ""}`
      : `/dashboard-list/${path}?org=${type || userOrgField}&name=${
          name || ""
        }&field=${field || ""}&fieldId=${fieldId || ""}`;

    return link;
  };

  const getUserDashboardViewList = useCallback(
    (position, type) => {
      // const cosListData = (field, fieldId) => ({
      //   name: "Credit officers",
      //   link: getOrgLink("cos", metadata?.name, field, fieldId),
      // });
      const branchesListData = (field, fieldId) => ({
        name: "Branches",
        link: getOrgLink("branches", metadata?.name, field, fieldId),
      });
      const areasListData = (field, fieldId) => ({
        name: "Areas",
        link: getOrgLink("areas", metadata?.name, field, fieldId),
      });
      const zonesListData = (field, fieldId) => ({
        name: "Zones",
        link: getOrgLink("zones", metadata?.name, field, fieldId),
      });
      const statesListData = (field, fieldId) => ({
        name: "States",
        link: getOrgLink("states", metadata?.name, field, fieldId),
      });

      // if ((position === "BM" && !isOrgDashboard) || type?.includes("branch")) {
      //   return [cosListData("branch", id)];
      // }
      if ((position === "AM" && !isOrgDashboard) || type?.includes("area")) {
        return [
          branchesListData("area_id", id), 
          // cosListData("area", id)
        ];
      }
      if ((position === "ZM" && !isOrgDashboard) || type?.includes("zone")) {
        return [
          areasListData("zone_id", id),
          branchesListData("zone_id", id),
          // cosListData("zone", id),
        ];
      }
      if ((position === "SM" && !isOrgDashboard) || type?.includes("state")) {
        return [
          zonesListData("state_id", id),
          areasListData("state_id", id),
          branchesListData("state_id", id),
          // cosListData("state", id),
        ];
      }
      if (position === "DR" && !isOrgDashboard) {
        return [
          statesListData(),
          zonesListData(),
          areasListData(),
          branchesListData(),
          // cosListData(),
        ];
      }
    },
    [userMetaData, orgMetaData, id]
  );

  useEffect(() => {
    const org_type = isOrgDashboard
      ? type
      : positionToOrgMapping2[user.position];

    const org_id = isOrgDashboard
      ? orgId
      : org_type === "cos"
      ? user.id
      : user[userOrgField]?.id;

    if (useUserDashboard) {
      dispatch(setUserDashboard({ userId: id || user.id, permissionLevel }));
      return;
    }

    dispatch(setOrgDashboardAction({ orgId: org_id, type: org_type })).catch(
      (error) => {
        dispatch(showAlertAction(error));
      }
    );
  }, [pathname]);

  useEffect(() => {
    setViewListData(() => getUserDashboardViewList(user.position, type));
  }, [userMetaData, orgMetaData, id]);

  const handleGetTableData = async (dateRange) => {
    setLoadingTableData(true);
    const org_type = isOrgDashboard
      ? type
      : positionToOrgMapping2[user.position];

    const org_id = isOrgDashboard
      ? orgId
      : org_type === "cos"
      ? user.id
      : user[userOrgField]?.id;

    // ORG TABLE
    dispatch(
      getOrgTableData({
        org_field_id: getOrgDashboardIdField(org_type),
        org_id: org_id || user.id,
        start_date:
          dateRange?.[0]?.format("YYYY/MM/DD") ||
          tableMeta.startDate.format("YYYY/MM/DD"),
        end_date:
          dateRange?.[1]?.format("YYYY/MM/DD") ||
          tableMeta.endDate.format("YYYY/MM/DD"),
      })
    ).then((result) => {
      setTableData(result);
      setLoadingTableData(false);
    });
  };

  const handleApproveRemittance = async (data, dateRage, total) => {
    if (!total || total === 0) {
      dispatch(
        showAlertAction({
          message:
            "You cannot remit ₦0, please try again when there is more money to remit.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }

    if (data?.is_remitted) {
      return;
    }

    if (window.confirm("Are you sure you want to confirm remitance?.")) {
      setLoadingTableData(true);
      loadingTableData;
      const amount =
        Number(data?.realised_installment) +
        Number(data?.realised_savings) +
        Number(data?.prev_cash_in_hand_paid);

      const cashInHand =
        parseInt(data?.realizable_installment) +
        parseInt(data?.realizable_savings) -
        (parseInt(data?.realised_installment) +
          parseInt(data?.realised_savings));

      const org_type = isOrgDashboard
        ? type
        : positionToOrgMapping2[user.position];

      const org_id = isOrgDashboard
        ? orgId
        : org_type === "cos"
        ? user.id
        : user[userOrgField]?.id;

      dispatch(
        approveRemitance({
          date: data?.date,
          cash_in_hand: cashInHand,
          amount,
          org_field_id: getOrgDashboardIdField(org_type),
          org_id: org_id || user.id,
        })
      ).then(() => {
        handleGetTableData(dateRage);
      });
    }
  };

  const getTodayTotalCollection = () => {
    const dataInst = tableData?.find((inst) =>
      moment(inst.date).isSame(new Date(), "day")
    );

    if (dataInst) {
      return getTotal(dataInst);
    }
    return "-";
  };

  const onViewDailypay = (name) => historyPushWithParam("dailypay", name);

  useEffect(() => {
    handleGetTableData();
  }, [pathname]);

  return {
    isOrgDashboard,
    userOrgField,
    metadata,
    accumulations: useUserDashboard
      ? userDashboardData?.accumulations
      : orgDashboardData?.accumulations,
    orgType: type || userOrgField,
    viewListData,
    cardsData: cardData,
    useUserDashboard,
    onDateRangeChange: handleGetTableData,
    tableData,
    handleApproveRemittance,
    position: user.position,
    loadingTableData,
    user,
    todayCollection: getTodayTotalCollection(),
    onViewDailypay: onViewDailypay,
    loadingDashboard: loadingOrgDashboard || loadingDashboard,
    user,
  };
};

export { useDashboard };
