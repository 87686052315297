import { Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getUnion } from "store/clients";
import { getUnionsMembers } from "store/clients";

const UnionDetailPage = () => {
  const [union, setUnion] = useState({});
  const [members, setMembers] = useState([]);
  const { id } = useParams();

  const dispatch = useDispatch();

  const getUnionData = async () => {
    dispatch(getUnionsMembers(id)).then((result) => {
      if (result) {
        setMembers(result);
      }
    });

    dispatch(getUnion(id)).then((result) => {
      setUnion(result);
    });
  };

  useEffect(async () => {
    getUnionData();
  }, []);

  return (
    <Container fluid>
      <h6 className="mb-3">
        Union: <strong>{union?.name || "-"}</strong>
      </h6>

      <h6 className="mb-3">
        Union Balance:{" "}
        <strong>₦ {Number(union?.balance || "0").toLocaleString()}</strong>
      </h6>

      <Descriptions layout="horizontal">
        <Descriptions.Item label="Branch">
          {union?.branch || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Area">{union?.area || "-"}</Descriptions.Item>
        <Descriptions.Item label="Zone">{union?.zone || "-"}</Descriptions.Item>
        <Descriptions.Item label="State">
          {union?.state || "-"}
        </Descriptions.Item>
      </Descriptions>

      <hr />

      <div>
        <h6>Members ({members?.length})</h6>

        <ul class="list-group">
          {members?.map((member) => (
            <li class="list-group-item d-flex justify-content-between align-items-center">
              {member?.fullname}
              <span class="badge badge-primary badge-pill">₦ {Number(member?.union_balance).toLocaleString()}</span>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export { UnionDetailPage };
