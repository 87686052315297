import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  level: "info",
  isVisible: false,
};

const slice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.message = action.payload.message
      state.level = action.payload.level
      state.isVisible = action.payload.isVisible
    },
  },
});

export default slice.reducer;

const { showAlert } = slice.actions;

export const showAlertAction = (alertData) => async (dispatch) => {
  try {
    dispatch(showAlert(alertData));

    setTimeout(() => {
      dispatch(showAlert(initialState));
    }, 5000);
  } catch (e) {
    console.log({ e });
  }
};
