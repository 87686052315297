import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addAdditionalSavingsAction } from "store/clients";
import { getSlimClientsWithParams } from "store/clients";
import { getOrgStaffProfileOrgField, getOrgDashboardIdField } from "../utils";

const AdditionalSavingsForm = () => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);

  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const userOrgId =
      user.position === "CO"
        ? user.id
        : user[getOrgStaffProfileOrgField(user.position)]?.id;
    const userIdField = getOrgDashboardIdField(user.position);
    const queryString = `${userIdField}=${userOrgId}`;
    dispatch(getSlimClientsWithParams(queryString)).then((res) => {
      setClients(res);
      setLoading(false);
    });
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    await dispatch(addAdditionalSavingsAction(values));
    form.resetFields();
    setLoading(false);
  };

  return (
    <Container fluid>
      <h3 className="page-title mb-4">Additional Savgings</h3>
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col className="" md="6">
            <Form.Item
              label="Select client"
              name="client_id"
              rules={[
                {
                  required: true,
                  message: "Please select a client",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select client"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                loading={loading}
              >
                {clients.map(({ id, surname, firstname }) => (
                  <Select.Option value={id} key={id}>
                    {`${surname} ${firstname}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="" md="6">
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Please provide amount",
                },
              ]}
            >
              <Input
                placeholder="Amount"
                type="number"
                className="form-control"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button
              variant="primary"
              className="px-5"
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export { AdditionalSavingsForm };
