import moment from "moment";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getAllunions } from "store/clients";
import { setDateFilterAction } from "store/loans";
import { getOrgDashboardIdField, positionToOrgMapping } from "utils";
import { getPersistedLoansWithParams } from "store/loans";
import { parse } from "query-string";

const useDashboardLoanList = () => {
  const {
    users: { user },
    loans: { filterDate, loans },
  } = useSelector((state) => ({
    users: state.users,
    loans: state.loans,
  }));
  const dispatch = useDispatch();
  const { type, orgId } = useParams();
  const [listRefreshTrigger, setListRefreshTrigger] = useState(false);
  const [unions, setUnions] = useState([]);
  const [loading, setLoading] = useState(false);
  const orgType = type || positionToOrgMapping[user.position];
  const { guarantor_id } = parse(window.location.search);

  const handleListRefreshTrigger = () =>
    setListRefreshTrigger((current) => !current);

  useEffect(async () => {
    let queryString = `start_date=${filterDate?.[0].format(
      "YYYY/MM/DD" || ""
    )}&end_date=${filterDate?.[1].format("YYYY/MM/DD") || ""}`;

    setLoading(true);
    if (orgType) {
      const field_id = getOrgDashboardIdField(orgType);
      queryString = queryString.concat(`&${field_id}=${orgId}`);
    }
    dispatch(
      getPersistedLoansWithParams(
        guarantor_id ? 'guarantor_id': orgType,
        guarantor_id ? guarantor_id : orgId,
        queryString.concat(`&guarantor_id=${guarantor_id || ''}`)
      )
    ).then(() => {
      setLoading(false);

      dispatch(getAllunions(queryString)).then((result) => setUnions(result));
    });
  }, [listRefreshTrigger, filterDate]);

  const handleFilterChange = (range) => dispatch(setDateFilterAction(range));

  return {
    listData: guarantor_id ? loans?.['guarantor_id']?.[guarantor_id] : loans?.[orgType]?.[orgId],
    type: orgType,
    id: orgId,
    handleListRefreshTrigger,
    unions,
    loading,
    setFilterDate: handleFilterChange,
    filterDate,
  };
};

export { useDashboardLoanList };
